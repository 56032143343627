import * as React from "react";

const Dislike = ({ color }) => (
	<>
		<path
			d="M2.43399 13.9746C3.01908 14.6771 3.88554 15.0839 4.79974 15.0855H9.49009L8.91409 16.5564C8.42775 17.8637 8.61413 19.3264 9.41276 20.4699C10.2114 21.6135 11.5205 22.2921 12.9153 22.2856C13.3194 22.2848 13.6856 22.0474 13.8513 21.6787L16.7828 15.0855H19.1999C20.9042 15.0855 22.2857 13.704 22.2857 11.9998V4.79965C22.2857 3.09543 20.9042 1.71389 19.1999 1.71389H6.10605C4.61645 1.71439 3.33984 2.77895 3.07172 4.24421L1.76541 11.4443C1.60184 12.3452 1.84678 13.2722 2.43399 13.9746ZM17.1428 3.77106H19.1999C19.768 3.77106 20.2285 4.23158 20.2285 4.79965V11.9998C20.2285 12.5678 19.768 13.0283 19.1999 13.0283H17.1428V3.77106ZM5.07746 4.61451L3.77116 11.8146C3.72037 12.117 3.80711 12.4262 4.00773 12.658C4.20353 12.8931 4.49381 13.0288 4.79974 13.0283H9.49009C10.1637 13.0295 10.7941 13.3604 11.1777 13.9142C11.5612 14.468 11.6493 15.1745 11.4136 15.8055L10.8684 17.2764C10.6516 17.8428 10.6816 18.4741 10.9513 19.0173C11.221 19.5605 11.7056 19.9661 12.2878 20.1359L15.0856 13.8409V3.77106H6.10604C5.60306 3.76286 5.16794 4.11966 5.07746 4.61451Z"
			id="Shape"
			fillOpacity="0.85"
			fill={color ? color : "#000000"}
			fillRule="nonzero"
		></path>
	</>
);

export default Dislike;
