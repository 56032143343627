import { SET_FEEDSSET_STATE, SET_FEEDS_DATA, SET_FEEDS_SCROLL_UPDATE, SET_LAST_UPDATED_POST_DATE } from "../Constants";
import { SET_FEEDS_UPDATE } from "../Constants";
import { SET_FEEDSOWNER_DATA } from "../Constants";
import { SET_POSTLIST_DATA } from "../Constants";
import { SET_FETCHPROCESS_DATA } from "../Constants";
import { SET_REMAINING_POSTS } from "../Constants";
import { SET_PROCESSED_ENTITY_IDS } from "../Constants";
import { SET_REMAINING_ENTITIES } from "../Constants";

export const setFeeds = data => ({
	type: SET_FEEDS_DATA,
	payload: data,
});
export const setPostList = data => ({
	type: SET_POSTLIST_DATA,
	payload: data,
});
export const setFetchProcess = data => ({
	type: SET_FETCHPROCESS_DATA,
	payload: data,
});
export const setFeedOwners = data => ({
	type: SET_FEEDSOWNER_DATA,
	payload: data,
});
export const setFeedsUpdate = data => ({
	type: SET_FEEDS_UPDATE,
	payload: data,
});

export const setFeedsScrollUpdate = data => ({
	type: SET_FEEDS_SCROLL_UPDATE,
	payload: data,
});

export const setLastUpdatedPostDate = data => ({
	type: SET_LAST_UPDATED_POST_DATE,
	payload: data,
});

export const setFeedsState = () => ({
	type: SET_FEEDSSET_STATE,
} );

export const setRemainingPosts = data => ( {
	type: SET_REMAINING_POSTS,
	payload: data,
} );

export const setProcessedEntityIds = data => ( {
	type: SET_PROCESSED_ENTITY_IDS,
	payload: data,
} );

export const setRemainingEntities = data => ( {
	type: SET_REMAINING_ENTITIES,
	payload: data,
} );