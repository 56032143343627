import { ILang } from "../AVCore/ILang";
import Data from "../App/en.json";
import { NativeModules, Platform } from "react-native";
import { Server } from "../Platform/Server";
import { Person } from "../AVCore/Person";

export class Loc {
	private static _currentLang: ILang;
	private static _version: string = "1.57";
	private static _browserLang = "en";
	public static async getBrowserLang(): Promise<string> {
		await fetch(Server._host + "/api/Base/UserLang/", {
			method: "GET",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			},
		})
			.then(resp => resp.json())
			.then(response => {
				this._browserLang = response.lang;
			});
		return this._browserLang;
	}

	public static get currentLang(): any {
		if (Loc._currentLang) return Loc._currentLang;

		//return Data;
	}
	public static async SetLang(key?: string) {
		//if (!this._currentLang)
		//this._currentLang = Data;

		if (!key) {
			if (Platform.OS === "ios") {
				key =
					NativeModules.SettingsManager.settings.AppleLocale ||
					NativeModules.SettingsManager.settings.AppleLanguages[0];
			} else if (Platform.OS === "android") key = NativeModules.I18nManager.localeIdentifier;
			else /*key = navigator.language || "en";*/
			   key = 'en';
			key = key?.toLowerCase().replace("_", "-");
		}
		try {
			if (key !== Loc._currentLang?.loc || Loc._currentLang?.version !== Loc._version) {
				//let user = await Person.getSelf();
				//key = user?.preferences?.lang ? user?.preferences?.lang : key;
				await fetch(Server._host + "/api/Base/Lang/" + key + "/" + this._version, {
					method: "GET",
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json",
					},
				})
					.then(resp => resp.json())
					.then(response => {
						Loc._currentLang = response;
					});
			}
		} catch {
			console.log("failed to get lang");
		}
	}
}
