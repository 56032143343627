import * as signalR from '@microsoft/signalr';
import { AVAuth } from '../Platform/AVAuth';

export class SignalRService
{
    // The SignalR hub URL from azure 
    private static hubUrl = '/SignalRHub';

    // Holds the SignalR connection instance
    private static connection : signalR.HubConnection | null = null;

    // Creates the SignalR connection with the user's authentication token
    public static async createConnection ()
    {
        // If the connection already exists, exit the function
        if ( SignalRService.connection )
        {
            return;
        }

        try
        {
            // Factory function to get the authentication token
            const accessTokenFactory = async () =>
            {
                const token = await AVAuth.getToken();
                if ( !token )
                {
                    throw new Error( 'Authentication token is not available' );
                }
                
                return token;
            };

            // Build the SignalR connection
            SignalRService.connection = new signalR.HubConnectionBuilder()
                .withUrl( SignalRService.hubUrl, {
                    accessTokenFactory: accessTokenFactory, // Ensure token is included in each request
                    //transport: signalR.HttpTransportType.WebSockets
                } )
                .withAutomaticReconnect() // Optionally enable automatic reconnection
                .configureLogging( signalR.LogLevel.Information ) // Enable information-level logging
                .build();

            // Set KeepAliveInterval and ServerTimeout
            SignalRService.connection.keepAliveIntervalInMilliseconds = 10000; //10 Seconds
            SignalRService.connection.serverTimeoutInMilliseconds = 300000; //5 Minutes

            console.log( 'Signal R connection Data: ', SignalRService.connection.baseUrl );
        } catch ( error )
        {
            console.error( 'Error creating SignalR connection:', error );
            throw error;
        }
    }

    // Starts the SignalR connection
    public static async startSignalRConnection ()
    {
        try
        {
            if ( SignalRService.connection )
            {
                await SignalRService.connection.start();
                console.log( 'SignalR Connected' );
            } else
            {
                console.error( 'SignalR connection is null.' );
            }
        } catch ( error )
        {
            console.error( 'SignalR Connection Error: ', error );
            throw error;
        }
    }

    // Stops the SignalR connection
    public static async stopSignalRConnection ()
    {
        try
        {
            // Only stop the connection if it's currently connected
            if ( SignalRService.connection?.state === signalR.HubConnectionState.Connected )
            {
                await SignalRService.connection.stop();
                console.log( 'SignalR Connection Stopped' );
            }
        } catch ( error )
        {
            console.error( 'Error stopping SignalR connection:', error );
            throw error;
        }
    }

    // Sends typing status to the server for a specific conversation
    public static async sendTyping ( convoId : string )
    {
        try
        {
            // Ensure the SignalR connection is created and running
            if ( !SignalRService.connection )
            {
                // Create a new SignalR connection if none exists
                await SignalRService.createConnection();
                await SignalRService.startSignalRConnection();
            }

            // Start the connection if it's in the disconnected state
            if ( SignalRService.connection?.state === signalR.HubConnectionState.Disconnected )
            {
                await SignalRService.startSignalRConnection();
            }

            // Invoke the typing status on the server if connected
            if ( SignalRService.connection?.state === signalR.HubConnectionState.Connected )
            {
                await SignalRService.connection.invoke( 'SendTyping', convoId );
                console.log( 'SendTyping invoked successfully' );
            } else
            {
                console.error( 'SignalR connection is not in the connected state.' );
            }
        } catch ( error )
        {
            console.error( 'Error invoking SendTyping:', error );
            throw error;
        }
    }

    // Adds an impression to the chat
    public static async addImpression ( impression : any )
    {
        try
        {
            if (!SignalRService.connection)
                await SignalRService.createConnection();
            if ( SignalRService.connection?.state === signalR.HubConnectionState.Disconnected )
            {
                await SignalRService.startSignalRConnection();
            }

            if ( SignalRService.connection?.state === signalR.HubConnectionState.Connected )
            {
                await SignalRService.connection.invoke( 'AddImpression', impression );
                console.log( 'AddImpression invoked successfully' );
            } else
            {
                console.error( 'SignalR connection is not in the connected state.' );
            }
        } catch ( error )
        {
            console.error( 'Error invoking AddImpression:', error );
            throw error;
        }
    }

    // Removes an impression from the chat
    public static async removeImpression ()
    {
        try
        {
            await SignalRService.createConnection();
            if ( SignalRService.connection?.state === signalR.HubConnectionState.Disconnected )
            {
                await SignalRService.startSignalRConnection();
            }

            if ( SignalRService.connection?.state === signalR.HubConnectionState.Connected )
            {
                await SignalRService.connection.invoke( 'RemoveImpression' );
                console.log( 'RemoveImpression invoked successfully' );
            } else
            {
                console.error( 'SignalR connection is not in the connected state.' );
            }
        } catch ( error )
        {
            console.error( 'Error invoking RemoveImpression:', error );
            throw error;
        }
    }

    // Joins a hub group based on conversation ID
    public static async joinGroup ( convoId : string )
    {
        try
        {
            if ( convoId && convoId !== "" )
            {
                await SignalRService.createConnection();
                if ( SignalRService.connection?.state === signalR.HubConnectionState.Disconnected )
                {
                    await SignalRService.startSignalRConnection();
                }

                if ( SignalRService.connection?.state === signalR.HubConnectionState.Connected )
                {
                    await SignalRService.connection.invoke( 'AddConvo', convoId );
                    console.log( 'Joined group successfully' );
                }
            } else
            {
                console.error( 'Invalid conversation ID or SignalR connection is not in the connected state.' );
            }
        } catch ( error )
        {
            console.error( 'Error joining group:', error );
            throw error;
        }
    }

    // Leaves a hub group based on conversation ID
    public static async leaveGroup ( convoId : string )
    {
        try
        {
            if ( SignalRService.connection?.state === signalR.HubConnectionState.Disconnected )
            {
                await SignalRService.startSignalRConnection();
            }

            if ( SignalRService.connection?.state === signalR.HubConnectionState.Connected )
            {
                await SignalRService.connection.invoke( 'RemoveConvo', convoId );
                console.log( 'Left group successfully' );
            } else
            {
                console.error( 'SignalR connection is not in the connected state.' );
            }
        } catch ( error )
        {
            console.error( 'Error leaving group:', error );
            throw error;
        }
    }

    // Optionally, export the connection object if needed externally
    public static getConnection ()
    {
        return SignalRService.connection;
    }
}