import * as React from "react";

const Video = ({ color }) => (
	<>
		<path
			d="m17.153 10.582-5.118-2.958a2.047 2.047 0 0 0-3.07 1.77v5.917a2.047 2.047 0 0 0 3.07 1.77l5.118-2.957a2.047 2.047 0 0 0 0-3.542Zm-1.024 1.77-5.117 2.959V9.395l5.117 2.958ZM13.06 2.119C7.406 2.118 2.824 6.7 2.824 12.353s4.582 10.235 10.235 10.235 10.235-4.582 10.235-10.235A10.235 10.235 0 0 0 13.06 2.118Zm0 18.423a8.188 8.188 0 1 1 0-16.376 8.188 8.188 0 0 1 0 16.376Z"
			id="Shape"
			fillOpacity="0.85"
			fill={color ? color : "#000000"}
			fillRule="nonzero"
		></path>
	</>
);

export default Video;
