import { CancelIcon } from "assets/icons/icon-assets";
import { Pressable, Text } from "native-base";
import React, { FC } from "react";

interface Props {
	callback: any | undefined;
	color: string | undefined;
	size: any | undefined;
}
const AVCancel: FC<Props> = ({ callback, color, size = 35 }) => {
	return (
		<Pressable
			_hover={{
				bgColor: "AVColor.grey",
				rounded: "full",
			}}
			onPress={() => {
				callback(false);
			}}
			zIndex={1}
		>
			<CancelIcon size={"30px"} color={"AVColor.black"} />
		</Pressable>
	);
};

export default AVCancel;
