import { Box, Center, HStack, Skeleton, VStack } from "native-base";
import React from "react";
import pageloader from "assets/images/full_logo.png";
import loader from "assets/images/loader.gif";
import { useWindowDimension } from "Utility/WindowDimensions";

const FeedsLoader = () => {
	const dimensions = useWindowDimension();
	const isMobile = dimensions?.width <= 500 || false;
	return (
		<Center w="100%" mb={3} bgColor={"AVColor.white"} rounded={"md"}>
			<HStack
				w="100%"
				space={8}
				rounded="md"
				_dark={{
					borderColor: "AVColor.skeletonDark",
				}}
				_light={{
					borderColor: "AVColor.skeletonStart",
				}}
			>
				<VStack width={"100%"} space={4}>
					<HStack space={3} p={4}>
						<Skeleton width={"40px"} borderRadius={"full"} startColor={"AVColor.skeletonStart"} />
						<VStack width={"80%"} space={1}>
							<Skeleton h={6} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} width={"60%"} />
							<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} width={"40%"} />
						</VStack>
					</HStack>
					<HStack width={"100%"}>
						<Box
							position={"absolute"}
							width={"120px"}
							height={"120px"}
							borderRadius={"full"}
							bgColor="white"
							zIndex={1}
							left={isMobile ? "33%" : "45%"}
							top={"25%"}
						/>
						<Skeleton flex={1} h={"300px"} startColor={"AVColor.skeletonStart"} />
					</HStack>
					<HStack space={5} px={4}>
						<VStack left={1} height={"50px"} width={"50%"} space={1}>
							<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} width={"50%"} />
							<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} width={"40%"} />
						</VStack>
					</HStack>
					<HStack space={3} px={4} pb={4}>
						<Skeleton width={"40px"} borderRadius={"full"} startColor={"AVColor.skeletonStart"} />
						<Skeleton h={10} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} />
					</HStack>
				</VStack>
			</HStack>
		</Center>
	);
};

const PageLoader = () => {
	return (
		<VStack alignItems={"center"} justifyContent={"center"} height={"100vh"}>
			<img src={pageloader} width={"200px"} alt={"Page"} />
			<img src={loader} style={{ marginTop: "-50px", marginLeft: "30px" }} alt={"Loading..."} />
		</VStack>
	);
};

const Loader = () => {
	return (
		<>
			<img src={loader} style={{ marginTop: "-50px" }} alt="" />
		</>
	);
};

const ProfileLoader = () => {
	return (
		<Center w="100%" mb={3} px={"50px"} mt={3} shadow={1}>
			<HStack
				w="100%"
				//maxW='600'
				space={8}
				rounded="md"
				_dark={{
					borderColor: "coolGray.500",
				}}
				_light={{
					borderColor: "coolGray.200",
				}}
				p="4"
				bgColor={"#ffffff"}
				shadow={1}
			>
				<VStack width={"100%"} space={3} flex={4} justifyContent={"space-between"}>
					<HStack space={1}>
						<Skeleton flex={1} h={"300px"} startColor={"AVColor.skeletonStart"} />
					</HStack>
					<HStack w={"170px"} mt={"-80px"} ml={"100px"}>
						<Skeleton flex={1} h={"170px"} w={"170px"} rounded={"full"} color={"AVColor.skeletonDark"} />
					</HStack>
					<VStack h={"70px"} w={"100px"} ml={"100px"} space={3}>
						<Skeleton flex={1} h={"30px"} w={"300px"} startColor={"AVColor.skeletonStart"} />
						<Skeleton flex={1} h={"30px"} w={"300px"} startColor={"AVColor.skeletonStart"} />
					</VStack>
					<HStack px={"20px"} space={5} alignItems={"flex-end"} flex={1} justifyContent={"flex-end"}>
						<Box h={"35px"}>
							<Skeleton flex={1} h={"35px"} w={"150px"} borderRadius={"full"} startColor={"AVColor.skeletonStart"} />
						</Box>
						<Box h={"35px"}>
							<Skeleton flex={1} h={"35px"} w={"150px"} startColor={"AVColor.skeletonStart"} borderRadius={"full"} />
						</Box>
					</HStack>
				</VStack>
				<HStack flex={1}>
					<VStack>
						<Skeleton flex={1} h={"170px"} w={"170px"} color="coolGray.500" />
					</VStack>
				</HStack>
			</HStack>

			<HStack
				w="100%"
				//maxW='600'
				space={8}
				rounded="md"
				_dark={{
					borderColor: "AVColor.skeletonDark",
				}}
				_light={{
					borderColor: "AVColor.skeletonStart",
				}}
				p="4"
				bgColor={"AVColor.white"}
				shadow={1}
				mt={5}
				h={"200px"}
			></HStack>
		</Center>
	);
};

const EventsCardLoader = () => {
	return (
		<VStack m={4} h={"300px"} rounded={"md"} bgColor={"white"} shadow={2}>
			<HStack width={"100%"}>
				<Box
					position={"absolute"}
					width={"80px"}
					height={"80px"}
					borderRadius={"full"}
					bgColor="white"
					zIndex={1}
					left={"30%"}
					top={"10%"}
				/>
				<Skeleton flex={1} h={"150px"} startColor={"AVColor.skeletonStart"} />
			</HStack>
			<HStack width={"100%"} space={1} px={2} pt={4}>
				<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} />
			</HStack>
			<HStack width={"50%"} space={1} px={2} pt={4} alignItems={"center"}>
				<Skeleton width={"20px"} h={"20px"} borderRadius={"full"} startColor={"AVColor.skeletonStart"} />
				<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} />
			</HStack>
			<HStack width={"70%"} space={1} px={2} pt={4} alignItems={"center"}>
				<Skeleton width={"20px"} h={"20px"} borderRadius={"full"} startColor={"AVColor.skeletonStart"} />
				<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} />
			</HStack>
			<HStack width={"80%"} space={1} px={2} pt={4} alignItems={"center"}>
				<Skeleton width={"20px"} h={"20px"} borderRadius={"full"} startColor={"AVColor.skeletonStart"} />
				<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} />
			</HStack>
		</VStack>
	);
};

const OrgCardLoader = () => {
	return (
		<VStack m={4} h={"300px"} rounded={"md"} bgColor={"white"} shadow={2}>
			<HStack width={"100%"}>
				<Box
					position={"absolute"}
					width={"80px"}
					height={"80px"}
					borderRadius={"full"}
					bgColor="white"
					zIndex={1}
					left={"30%"}
					top={"10%"}
				/>
				<Skeleton flex={1} h={"150px"} startColor={"AVColor.skeletonStart"} />
			</HStack>
			<HStack width={"100%"} space={1} px={2} pt={4}>
				<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} />
			</HStack>
			<HStack width={"50%"} space={1} px={2} pt={4} alignItems={"center"}>
				<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} />
			</HStack>
			<HStack width={"70%"} space={1} px={2} pt={4} alignItems={"center"}>
				<Skeleton h={4} flex={1} startColor={"AVColor.skeletonStart"} borderRadius={7} />
			</HStack>
		</VStack>
	);
};

export { FeedsLoader, PageLoader, Loader, ProfileLoader, EventsCardLoader, OrgCardLoader };
