import React from "react";

function AVGradientBox() {
	return (
		<div
			style={{
				background: "linear-gradient(to right, #00A7BC, #F5E732)",
				height: "3px",
				width: "100%",
			}}
		></div>
	);
}

export default AVGradientBox;
