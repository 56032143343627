/*This screen only for SignalR testing purposes*/
import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SignalRService } from 'AVCore/SignalR';
import { RootStateOrAny } from 'react-redux';

const SignalRTest : React.FC = () =>
{
    // Get the current user from the Redux store
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );

    // Get the latest conversation ID from the current user's conversations
    const convoId = currentUser.convos[ currentUser.convos.length - 1 ].id;

    // Create a ref to hold the typing indicators for different conversations
    const typingIndicatorRef = useRef<{ [ key : string ] : HTMLParagraphElement | null }>( {} );

    // Function to set up the SignalR connection and event handlers
    const setupSignalRConnection = async () =>
    {
        // Join the SignalR group using the conversation ID
        await SignalRService.joinGroup( convoId );

        // Check if the connection is established and then register an event listener for the 'typing' event
        if ( SignalRService.getConnection() )
        {
            SignalRService.getConnection()!.on( 'typing', ( receivedConvoId, userName ) =>
            {
                console.log( "Typing event received:", { receivedConvoId, userName } );

                // If the typing event corresponds to the current conversation and is from a different user
                if (
                    receivedConvoId === convoId &&
                    typingIndicatorRef.current[ receivedConvoId ] &&
                    currentUser.name !== userName
                )
                {
                    // Update the typing indicator to show that the other user is typing
                    typingIndicatorRef.current[ receivedConvoId ]!.textContent = `${ userName } is typing...`;
                    console.log( `${ userName } is typing in conversation ${ receivedConvoId }...` );

                    // Clear the typing indicator after 6 seconds
                    setTimeout( () =>
                    {
                        if ( typingIndicatorRef.current[ receivedConvoId ] )
                        {
                            typingIndicatorRef.current[ receivedConvoId ]!.textContent = '';
                        }
                    }, 6000 );
                }
            } );
        }
    };

    // Function to send a typing event when the user starts typing
    const handleSendTyping = async () =>
    {
        await SignalRService.sendTyping( convoId ); // Notify the server that the user is typing
    };

    // Effect to set up the SignalR connection when the component mounts or convoId/userId changes
    useEffect( () =>
    {
        setupSignalRConnection(); // Establish SignalR connection and register event handlers

        // Cleanup function to remove the 'typing' event listener when the component unmounts
        return () =>
        {
            if ( SignalRService.getConnection() )
            {
                SignalRService.getConnection()!.off( 'typing' );
            }
        };
    }, [ convoId, currentUser.id ] ); // Rerun the effect when the conversation ID or user ID changes

    return (
        <div>
            <h1>This Screen is only for SignalR testing</h1>
            {/* Paragraph element to display typing status */ }
            <p ref={ el => typingIndicatorRef.current[ convoId ] = el }></p>

            {/* Button to trigger sending typing event */ }
            <button onClick={ handleSendTyping }>Send Typing</button>
        </div>
    );
};

export default SignalRTest;