import * as React from "react";

const Home = ({ color }) => (
	<>
		<path
			d="M20.1961967,8.02343959 L20.1961967,8.02343959 L14.3138668,2.86659708 C13.197398,1.86799705 11.5087824,1.86799705 10.3923136,2.86659708 L4.50998373,8.02343959 C3.88002967,8.58683956 3.52287733,9.39400386 3.52950232,10.2391172 L3.52950232,18.807711 C3.52950232,20.4320715 4.84639981,21.7488759 6.47076035,21.7488759 L18.2354201,21.7488759 C19.8597806,21.7488759 21.1766166,20.4320715 21.1766166,18.807711 L21.1766166,10.2293133 C21.1804858,9.38761107 20.8235793,8.58457138 20.1961967,8.02343959 Z M14.3138668,19.7880993 L10.3923136,19.7880993 L10.3923136,14.8861578 C10.3923136,14.3447042 10.8312484,13.9057694 11.3727019,13.9057694 L13.3334785,13.9057694 C13.874932,13.9057694 14.3138668,14.3447042 14.3138668,14.8861578 L14.3138668,19.7880993 Z M19.2158084,18.807711 C19.2158084,19.3491645 18.7768736,19.7880993 18.2354201,19.7880993 L16.2746434,19.7880993 L16.2746434,14.8861578 C16.2746434,13.2617972 14.957839,11.9449928 13.3334785,11.9449928 L11.3727019,11.9449928 C9.74834136,11.9449928 8.43153697,13.2617972 8.43153697,14.8861578 L8.43153697,19.7880993 L6.47076035,19.7880993 C5.92930684,19.7880993 5.49037204,19.3491645 5.49037204,18.807711 L5.49037204,10.2293133 C5.49072862,9.94771914 5.6121517,9.67987411 5.82370406,9.49402205 L11.7060339,4.34698343 C12.0761366,4.0218417 12.6300438,4.0218417 13.0001465,4.34698343 L18.8824763,9.49402205 C19.0940287,9.67987411 19.2154518,9.94771914 19.2158084,10.2293133 L19.2158084,18.807711 Z"
			id="Shape"
			fill={color ? color : "#000000"}
			fillRule="nonzero"
		></path>
	</>
);

export default Home;
