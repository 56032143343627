import data from "./Stores.json";
import { Store } from "../Platform/Store";
import { Server } from "../Platform/Server";
export class MediaStore {
	private static _stores: object = data;


    public static getStoreURL(store?: string| null): string {
        var retval: string;
        if (!store || store == undefined) {
            //TODO: Remove when we reset env
			if ( window.location.hostname == 'dev.actavivo.com' || window.location.hostname == 'localhost' ) {
                store = "ST-USDW";
                retval = "https://usd-store.actavivo.com/";
            }
          else if (window.location.hostname == 'qa.actavivo.com') {
            store = "ST-USTW";
            retval = "https://ust-store.actavivo.com/";
          }
            else {
                store = "ST-USCW";
                retval = "https://usc-store.actavivo.com/";
            }
        }
        else {
            retval = MediaStore._stores[store];
        }

		if (!retval) {
			retval = "https://usc-store.actavivo.com/";
			MediaStore._stores[store] = retval;
			MediaStore.UpdateStores();
		}
		return retval;
	}
	public static async init() {
		await MediaStore.UpdateStores();
		let s = await Store.GetObj<object>("MediaStore");
		if (s) MediaStore._stores = s;

		//let timeoutCheck = setTimeout(() => {
		//    MediaStore.UpdateStores();
		//}, 3000);
	}
	public static async UpdateStores() {
		try {
			let s: any = await Server.get<object>("/api/Base/Stores");
			if (s) {
				MediaStore._stores = JSON.parse(s);
				await Store.Set("MediaStore", MediaStore._stores);
			}
		} catch {
			console.log("failed to get stores");
		}
	}
}
