import Header from 'components/header/Header';
import { HStack, Hidden, VStack, Text, Box, Pressable } from 'native-base';
import React, { FC, useState } from 'react';
import { useWindowDimension } from 'Utility/WindowDimensions';
import Placeholder from 'components/Ads/Placeholder';
import Right from '../../components/body/Right';
import { Dimensions } from 'react-native';
import Offline from "react-offline";
import disconnect from '../../assets/images/disconnected.png';
import { Loc } from '../../App/Loc';
import settings from '../../assets/images/settings.png';
import Roles from '../../assets/images/Roles.png';
import AVCreateTeam from './AVCreateTeam';
import ProfileHeader from './ProfileHeader';
import { IProfile } from '../../AVCore/AVCore';

interface Props {
    children?: any | undefined;
    isGeneral?: any | undefined;
    setIsGeneral?: any | undefined;
    isLoginAccount?: any | undefined;
    setIsLoginAccount?: any | undefined;
    isRole?: any | undefined;
    setIsRole?: any | undefined;
    Entity?: any | undefined;
    setIsEntity?: any | undefined;
    type?: any | undefined;
    isHomepage?: any | undefined;
    setShowCreateNewPage: any | undefined;
    showCreateNewPage: any | undefined;
    setCreateType: any | undefined;
    createType: any | undefined;
    setPassEntity: any | undefined;
    passEntity: any | undefined;
    setIsShowEdit: any | undefined;
    isOpenConvo: any | undefined;
    setIsOpenConvo: any | undefined;
    isConvoData: any | undefined;
    setIsConvoData: any | undefined;
}

const AVBody: FC<Props> = ({ children, isGeneral, setIsGeneral, isLoginAccount, setIsLoginAccount, isRole, setIsRole, Entity, type, isHomepage, setShowCreateNewPage, showCreateNewPage, setCreateType, createType, setPassEntity, passEntity, setIsShowEdit, isOpenConvo, setIsOpenConvo, isConvoData, setIsConvoData }) => {
    const dimension = Dimensions.get('window');
    const dimensions = useWindowDimension();
    const isMobile = dimension?.width <= 500 || false;
    const [isEditEntireSeries, setIsEditEntireSeries] = useState(false);
    const [ profile, setProfile ] = useState<any>( { background: null, pic: null, thumb: null, gallery: null, previousPic: null, text: null, color: null } );
    const [isGroupChat,setIsGroupChat] = useState(false)

    return (
        <div className='container scrollbar' style={{ height: '100vh', width: '100%' }}>
            <Header dimensions={ dimensions } headerHeight={ 60 } isOpenConvo={ isOpenConvo } setIsOpenConvo={ setIsOpenConvo } isConvoData={ isConvoData } setIsConvoData={ setIsConvoData } isGroupChat={ isGroupChat } setIsGroupChat={ setIsGroupChat } />
            <HStack
                mt={{ base: '105px' }}
                mr={[0, 0, 'auto', 'auto']}
                ml={[0, 0, 'auto', 'auto']}
                maxWidth={'appMaxWidth'}
                width={[dimensions.width, '100%']}
                paddingLeft={isMobile ? '5px' : '39px'}
                paddingRight={isMobile ? '5px' : '31px'}
            >
                <Hidden only={['base', 'sm', 'md']}>
                    <VStack width={'227px'} >
                        {type === 'accountsettings' ?
                            <VStack width={'227px'} position={'fixed'}>
                                <Text marginLeft={'15px'} fontSize={'24px'} py={'12px'}>
                                    Settings
                                </Text>
                                <Box width={'100%'} >
                                    <VStack px={2} width={'100%'} space={3}>
                                        <Pressable
                                            backgroundColor={isGeneral ? 'gray.200' : ''}
                                            borderRadius={isGeneral ? '7px' : ''}
                                            onPress={() => {
                                                setIsGeneral(true);
                                                setIsRole(false);
                                                setIsLoginAccount(false);
                                            }}
                                        >
                                            <HStack space={5} p='12px'>
                                                <img src={settings} height={'25px'} width={'25px'} alt={''} />
                                                <Text fontSize='18px' color='rgba(0,0,0,0.85)'>
                                                    General
                                                </Text>
                                            </HStack>
                                        </Pressable>
                                        {isHomepage &&
                                            <Pressable
                                                backgroundColor={isLoginAccount ? 'gray.200' : ''}
                                                borderRadius={isLoginAccount ? '7px' : ''}
                                                onPress={() => {
                                                    setIsGeneral(false);
                                                    setIsRole(false);
                                                    setIsLoginAccount(true);
                                                }}
                                            >
                                                <HStack space={5} p='12px'>
                                                    <img src={settings} height={'25px'} width={'25px'} alt={''} />
                                                    <Text fontSize='18px' color='rgba(0,0,0,0.85)'>
                                                        Login Accounts
                                                    </Text>
                                                </HStack>
                                            </Pressable>
                                        }
                                        {!isHomepage && Entity?.type !== 'Event' && (
                                            <Pressable
                                                backgroundColor={isRole ? 'gray.200' : ''}
                                                borderRadius={isRole ? '7px' : ''}
                                                onPress={() => {
                                                    setIsRole(true);
                                                    setIsGeneral(false);
                                                    setIsLoginAccount(false);
                                                }}
                                            >
                                                <HStack space={5} p='12px'>
                                                    <img src={Roles} height={'27px'} width={'27px'} alt={''} />
                                                    <Text fontSize='18px' color='rgba(0,0,0,0.85)'>
                                                        {Loc.currentLang.menu.roles}
                                                    </Text>
                                                </HStack>
                                            </Pressable>
                                        )}
                                    </VStack>
                                </Box>
                            </VStack> :
                            <Placeholder />
                        }
                    </VStack>
                </Hidden>

                <VStack marginBottom={isMobile ? '15%' : ''} flex={[1, '30%']} marginLeft={isMobile ? '' : '18px'}>
                    <Offline>
                        {({ isOffline }) => {
                            return isOffline && <VStack padding={'10px'} backgroundColor={'white'} shadow={5} borderRadius={'10px'} width={isMobile ? '100%' : 'fit-content'} zIndex={999} left={isMobile ? "" : '40%'}>
                                <HStack space={20} marginLeft={'10px'}>
                                    <Box position={'absolute'} top={'10px'}>
                                        <img src={disconnect} height={'25px'} width={'25px'} alt={''} />
                                    </Box>
                                    <Text textAlign={'center'} fontWeight={'medium'} >{Loc.currentLang.menu.offlineMsg}</Text>
                                </HStack>
                                <HStack space={40} marginLeft={'20px'}>
                                    <Text textAlign={'center'} color={'gray.400'} marginLeft={'25px'}>{Loc.currentLang.menu.internetDisconnectedMsg}</Text>
                                </HStack>
                            </VStack>;
                        }}
                    </Offline>

                    {!showCreateNewPage && children}
                    {showCreateNewPage && (
                        <HStack flex={1} width={'100%'} space={[0, 0, 3, 4]}>
                            <HStack flex={[1, 1, 3]}>
                                <VStack flex={1}>
                                    <Box
                                        height={'auto'}
                                        borderRadius={5}
                                        backgroundColor={'AVColor.white'}
                                        shadow={1}
                                        borderTopLeftRadius={10}
                                        borderTopRightRadius={10}
                                        marginBottom={'12px'}
                                    >
                                        {/*ProfileHeader*/ }
                                        <ProfileHeader isCreate={ true } isShowEdit={ undefined } Entity={ undefined } handleUpload={ undefined } EntityType={ undefined } type={ createType } setIsShowEdit={ undefined } profile={ profile } setProfile={ setProfile } googlemap={ undefined } isAdminView={ undefined } weatherURL={ undefined } weather={ undefined } Owner={ undefined } setIsImagePopup={ undefined } setEntity={ undefined } editAccess={ undefined } setIsStaffReq={ undefined } setIsChildOrgReq={ undefined } isDeleted={ undefined } HandleBlockUser={ undefined } HandleUnBlockUser={ undefined } HandleRestoreAccount={ undefined } HandleUnFollowUser={ undefined } setIsOpenConvo={ setIsOpenConvo } setIsConvoData={ setIsConvoData } setIsEditEntireSeries={ setIsEditEntireSeries } isEditEntireSeries={ isEditEntireSeries } seriesEvent={ '' } isRequestAgain={ false } setIsRequestAgain={ undefined } />

                                        <AVCreateTeam isCreate={ true } setIsCreate={ undefined } type={ createType } profile={ profile } isShowEdit={ false } setIsShowEdit={ undefined } teamType={ createType } Entity={ undefined } setEntity={ undefined } EntityDataFinding={ undefined } setgooglemap={ undefined } setProfile={ setProfile } GetWeatherIcon={ undefined } handleUpload={ undefined } setShowCreateNewPage={ setShowCreateNewPage } setCreateType={ setCreateType } passEntity={ passEntity } setPassEntity={ setPassEntity } isEditEntireSeries={ isEditEntireSeries } setIsEditEntireSeries={ setIsEditEntireSeries } seriesEvent={ '' } />
                                    </Box>
                                </VStack>
                            </HStack>
                        </HStack>
                    )}
                </VStack>
                {type !== 'accountsettings' ?
                    <Right setShowCreateNewPage={setShowCreateNewPage} setType={setCreateType} setIsShowEdit={setIsShowEdit} /> :
                    <Hidden only={['base', 'sm', 'md']}>
                        <Box width={'227px'}>
                        </Box>
                    </Hidden>
                }
            </HStack>
        </div>
    );
}

export default AVBody;                                