/*!
 * Copyright © 2021 Actavivo
 */
import { AVBase } from "./AVBase";
import * as AV from "./Interfaces/AV.Interfaces";
import { Server } from "../Platform/Server";
import { Profanity, CensorType } from '../Profanity';
import { Loc } from '../App/Loc';

const profanity = new Profanity({
	languages: ["en", "de","fr","es"]
});

export class Post {
	static _api = "/api/Post";

	public constructor(public data: AV.IPost) {}

	public static makeSmall(val: AV.IPost): AV.IPost {
		let newval: AV.IPost = JSON.parse(JSON.stringify(val));
		newval.impressions = undefined;
		return newval;
	}
	public static async list(param: AV.IFeedParam): Promise<AV.IPost[]> {
		let list: AV.IPost[] = await Server.post<AV.IPost[]>(Post._api + "/Posts", param);
		return list;
	}
	public static async get(fk?: AV.IFK): Promise<AV.IPost> {
		var p: AV.IPost = await Server.post<AV.IPost>(Post._api + "/Get", fk);
		return p;
	}
	public static async save(val: AV.IPost): Promise<AV.IPost> {

		if (val.text && profanity.exists(val.text)) {
			throw new Error(Loc.currentLang.menu.inappropriateErrorMsg);
		}
		var p: AV.IPost = await Server.post<AV.IPost>(Post._api, this.makeSmall(val));
		return p;
	}
	public static async saveConvo(val: AV.IPost): Promise<AV.IPost> {
		if (val.text && profanity.exists(val.text)) {
			throw new Error(Loc.currentLang.menu.inappropriateErrorMsg);
		}
		var p: AV.IPost = await Server.post<AV.IPost>(Post._api + "/ConvoPost", this.makeSmall(val));
		return p;
	}
	public static async delete(val: AV.IPost) {
		await Server.delete(Post._api + "/Delete", AVBase.getFk(val));
	}
	public static async Report(val: AV.IPost) {
		await Server.post(Post._api + "/Report", AVBase.getFk(val));
	}
	public static async AcceptReview(val: AV.IPost) {
		await Server.post(Post._api + "/AcceptReview", AVBase.getFk(val));
	}
	public static async deleteAll() {
		await Server.delete("/api" + "/HardDelete", { pk: "498efa59-bf6f-4676-8390-db7382e2a41b" });
	}
	public static async addImpression(val: AV.IPost, type: string): Promise<AV.IPost> {
		let param: AV.IListItemParam = { source: AVBase.getFk(val), type: type };
		var p: AV.IPost = await Server.post(Post._api + "/AddImpression", param);
		return p;
	}
	public static async removeImpression(val: AV.IPost, type: string): Promise<AV.IPost> {
		let param: AV.IListItemParam = { source: AVBase.getFk(val), type: type };
		var p: AV.IPost = await Server.post(Post._api + "/RemoveImpression", param);
		return p;
	}
}
