import { Button } from "native-base";
import React, { FC } from "react";

interface Props {
	callback: any | undefined;
	btnText: any | undefined;
	px: any | undefined;
	height: any | undefined;
	isLoadingText: any | undefined;
	isDisabled: any | undefined;
	isLoading: any | undefined;
	loading: any | undefined;
}

const AVButtonRoundInvert: FC<Props> = ({ callback, btnText, px = 10, height = 35 }) => {
	return (
		<Button
			onPress={callback}
			variant={"AVRoundButton"}
			backgroundColor={"AVColor.grey"}
			px={px}
			height={height}
			minHeight={"20px"}
			zIndex={-3}
			borderRadius={"full"}
			_hover={{ backgroundColor: "AVColor.buttonBg" }}
			_text={{ color: "avcolors.darkGrey", opacity: "0.8", fontSize: [14, 16] }}
		>
			{btnText}
		</Button>
	);
};
const AVButtonRoundedFull: FC<Props> = ({
	callback,
	btnText,
	loading,
	isLoadingText,
	isDisabled,
	px = 10,
	height = 35,
}) => {
	return (
		<Button
			onPress={callback}
			variant={"AVRoundButton"}
			backgroundColor={"AVColor.primary"}
			px={px}
			height={height}
			minHeight={"20px"}
			zIndex={-3}
			borderRadius={"full"}
			_text={{ color: "AVColor.white", opacity: "1", fontSize: [14, 16] }}
			isLoading={loading}
			isLoadingText={isLoadingText}
			isDisabled={isDisabled}
		>
			{btnText}
		</Button>
	);
};

export { AVButtonRoundInvert, AVButtonRoundedFull };
