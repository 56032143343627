/*import { SET_FEEDS_DATA } from '../Constants';*/
//import {feeds} from '../../MockData/sampleData'
const initialState = {
	feeds: [],
	feedowners: [],
	postList: [],
	feedsUpdate: false,
	fetchProcess: false,
	lastUpdatedPostDate: null,
	remainingPosts: [],
	processedEntityIds: [],
	reminingEntities: [],
};

const feedsReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case "SET_FEEDS_DATA":
			return { ...state, feeds: [action.payload] };
		case "SET_POSTLIST_DATA":
			return { ...state, postList: [action.payload] };
		case "SET_FETCHPROCESS_DATA":
			return { ...state, fetchProcess: [action.payload] };
		case "SET_FEEDSOWNER_DATA":
			return { ...state, feedowners: [action.payload] };
		case "SET_FEEDS_SCROLL_UPDATE":
			return { ...state, feedsScrollUpdate: action.payload };
		case "SET_FEEDS_UPDATE":
			return { ...state, feedsUpdate: action.payload };
		case "SET_LAST_UPDATED_POST_DATE": // Add this case
			return { ...state, lastUpdatedPostDate: action.payload };
		case "SET_REMAINING_POSTS":
			return { ...state, remainingPosts: action.payload };
		case "SET_PROCESSED_ENTITY_IDS":
			return { ...state, processedEntityIds: action.payload };
		case "SET_REMAINING_ENTITIES":
			return { ...state, remainingEntities: action.payload };
		case "SET_FEEDSSET_STATE":
			return initialState;
		default:
			return state;
	}
};

export default feedsReducer;
