import * as React from "react";

const PhotoAlbum = ({ color }) => (
	<>
		<path
			fill="#EFEFEF"
			d="M34.904,20.355c-3.782-5.791-3.38-11.727-5.203-14.518c-1.823-2.791-7.256-7.603-20.093,0.782s-9.572,17.5-7.566,20.569C11.43,41.56,41.603,30.611,34.904,20.355z"
		/>
		<rect width="2" height=".999" x="6.596" y="2.802" fill="#A4AFC1" transform="rotate(-52.987 7.595 3.301)" />
		<rect width="2" height="1" x="3.132" y="7.39" fill="#A4AFC1" transform="rotate(-52.893 4.132 7.89)" />
		<rect width="1.001" height="1.999" x="3.044" y="2.689" fill="#A4AFC1" transform="rotate(-52.971 3.545 3.69)" />
		<path
			fill="#A4AFC1"
			d="M29.5,8.25c-0.827,0-1.5-0.673-1.5-1.5s0.673-1.5,1.5-1.5S31,5.923,31,6.75S30.327,8.25,29.5,8.25z M29.5,6.25c-0.276,0-0.5,0.225-0.5,0.5s0.224,0.5,0.5,0.5S30,7.025,30,6.75S29.776,6.25,29.5,6.25z"
		/>
		<path
			fill="#F3F3F1"
			d="M27,10h-1V7c0-0.553-0.447-1-1-1H10C8.346,6,7,7.346,7,9v18c0,1.654,1.346,3,3,3h17c0.553,0,1-0.447,1-1V11C28,10.447,27.553,10,27,10z"
		/>
		<path
			fill="#D5DBE1"
			d="M9.75,27V9c0-1.654,1.346-3,3-3H10C8.346,6,7,7.346,7,9v18c0,1.654,1.346,3,3,3h2.75C11.096,30,9.75,28.654,9.75,27z"
		/>
		<path
			fill="#2FDF84"
			d="M22.25,16.75H21.5c-0.078,0-0.153-0.037-0.239-0.148l-0.902-1.015C20.026,15.214,19.55,15,19.051,15h-2.102c-0.499,0-0.976,0.214-1.308,0.587L14.7,16.65c-0.047,0.062-0.122,0.1-0.2,0.1h-0.75c-0.965,0-1.75,0.785-1.75,1.75v4.75c0,0.965,0.785,1.75,1.75,1.75h8.5c0.965,0,1.75-0.785,1.75-1.75V18.5C24,17.535,23.215,16.75,22.25,16.75z"
		/>
		<path
			fill="#00B871"
			d="M14.75,23.25V18.5c0-0.965,0.785-1.75,1.75-1.75h0.75c0.078,0,0.153-0.037,0.2-0.1l0.941-1.063c0.259-0.291,0.607-0.482,0.984-0.553C19.269,15.014,19.161,15,19.051,15h-2.102c-0.499,0-0.976,0.214-1.308,0.587L14.7,16.65c-0.047,0.062-0.122,0.1-0.2,0.1h-0.75c-0.965,0-1.75,0.785-1.75,1.75v4.75c0,0.965,0.785,1.75,1.75,1.75h2.75C15.535,25,14.75,24.215,14.75,23.25z"
		/>
		<circle cx="18" cy="20.5" r="2.5" fill="#2FDF84" />
		<g>
			<path d="M27.25,30H9.75C8.233,30,7,28.767,7,27.25V8.75h1.5v18.5c0,0.689,0.561,1.25,1.25,1.25H26.5V10.75H28v18.5C28,29.664,27.664,30,27.25,30z" />
			<path d="M27.25 11.5H9.75C8.233 11.5 7 10.267 7 8.75S8.233 6 9.75 6h15.5C25.664 6 26 6.336 26 6.75V10h1.25V11.5zM9.75 7.5C9.061 7.5 8.5 8.061 8.5 8.75S9.061 10 9.75 10H24.5V7.5H9.75zM18 23c-1.378 0-2.5-1.121-2.5-2.5S16.622 18 18 18s2.5 1.121 2.5 2.5S19.378 23 18 23zM18 19.5c-.551 0-1 .448-1 1s.449 1 1 1 1-.448 1-1S18.551 19.5 18 19.5z" />
			<path d="M22.25,25h-8.5C12.785,25,12,24.215,12,23.25V18.5c0-0.965,0.785-1.75,1.75-1.75h0.75c0.078,0,0.152-0.037,0.199-0.1l0.942-1.062C15.973,15.214,16.45,15,16.949,15h2.102c0.5,0,0.977,0.214,1.308,0.588l0.902,1.014c0.087,0.111,0.161,0.148,0.239,0.148h0.75c0.965,0,1.75,0.785,1.75,1.75v4.75C24,24.215,23.215,25,22.25,25z M13.75,18.25c-0.138,0-0.25,0.112-0.25,0.25v4.75c0,0.138,0.112,0.25,0.25,0.25h8.5c0.138,0,0.25-0.112,0.25-0.25V18.5c0-0.138-0.112-0.25-0.25-0.25H21.5c-0.548,0-1.072-0.262-1.4-0.701l-0.862-0.965C19.19,16.53,19.122,16.5,19.051,16.5h-2.102c-0.071,0-0.139,0.03-0.187,0.084l-0.902,1.014c-0.289,0.391-0.812,0.652-1.36,0.652H13.75z" />
		</g>
	</>
);

export default PhotoAlbum;
