import React from "react";
import { View } from "react-native";

export default function AVStack({ children, ...props }) {
	return (
		<View
			style={{
				flexDirection: "column",
				...props,
			}}
		>
			{children ? children : ""}
		</View>
	);
}
