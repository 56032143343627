import { AVAuth } from "../Platform/AVAuth";
import { MediaStore } from "./MediaStore";
import { Loc } from "./Loc";
import { Server } from "Platform/Server";
import ProfilePic from "../components/common/ProfilePicture";
export class Config {
	public static async init() {
		//await Auth.init();
		await Loc.SetLang();
		await MediaStore.init();
		//await Server.init();
	}
	public static key: string = "LoveActavivo!";

	//TODO: Need to check the below code and if it doesn't affect other screens, then remove it

	//public static profilPics: ProfilePic[] = [];

	//public static removeProfile(obj: ProfilePic) {
	//    for (var i = 0; i < this.profilPics.length; i++) {
	//        if (this.profilPics[i] === obj) {
	//            this.profilPics.splice(i, 1);
	//            break;
	//        }
	//    }
	//}
	//public static UpdateProfilePic() {
	//    for (var i = 0; i < this.profilPics.length; i++) {
	//        this.profilPics[i].newProfile();
	//    }
	//}
}
