/*!
 * Copyright © 2021 Actavivo
 */
import * as AV from "./Interfaces/AV.Interfaces";
import { Server } from "../Platform/Server";
import { OrgBase } from "./OrgBase";

export class Team extends OrgBase {
	static _teamApi = "/api/Team";

	public static async getActivites(): Promise<AV.IActivityType[]> {
		return await Server.get(this._teamApi + "/Activities");
	}

	public static async addPlayer(source: AV.IFK, target: AV.INewPlayer): Promise<AV.ILeagueTeam> {
		return await Server.post(this._teamApi + "/AddPlayer", { source: source, target: [target] });
	}
	public static async addPlayers(source: AV.IFK, target: AV.INewPlayer[]): Promise<AV.ILeagueTeam> {
		return await Server.post(this._teamApi + "/AddPlayer", { source: source, target: target });
	}
	public static async removePlayer(source: AV.IFK, target: AV.IPlayer): Promise<AV.ILeagueTeam> {
		return await Server.post(this._teamApi + "/RemovePlayer", { source: source, target: target });
	}
	public static async updatePlayer(source: AV.IFK, target: AV.IPlayer): Promise<AV.ILeagueTeam> {
		return await Server.post(this._teamApi + "/UpdatePlayer", { source: source, target: target });
	}
	public static async addPlayerGuardian(source: AV.IFK, target: AV.IContact): Promise<AV.IPerson> {
		return await Server.post(this._teamApi + "/AddPlayerGuardian", { source: source, target: target });
	}

	public static async addSeason(source: AV.IFK, target: AV.ILink): Promise<AV.ITeam> {
		return await Server.post(this._orgBaseApi + "/AddChild", { source: source, target: target });
	}
	public static async removeSeason(source: AV.IFK, target: AV.ILink): Promise<AV.ITeam> {
		return await Server.post(this._orgBaseApi + "/RemoveChild", { source: source, target: target });
	}
}
