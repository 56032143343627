import React, { useState, useEffect } from "react";
import { msalConfig, msalParams } from "../../Utility/msalConfig"; // Import the MSAL config
import * as msal from "@azure/msal-browser"; // Import MSAL for authentication
import { Pressable, VStack, Text } from 'native-base'
import onedriveIcon from "../../assets/images/onedriveIcon.png";

const OneDrive = ( { setExternalLink, HandleAddOneDrive }) =>
{
    const [ app, setApp ] = useState<msal.PublicClientApplication | any>( null );
    const baseUrl = "https://onedrive.live.com/picker";
    const authority = "https://login.microsoftonline.com/consumers";
    const redirectUri = window.location.origin; // your web url
    const clientId = "54ddd835-f775-4232-b292-183f36d472af";

    useEffect( () =>
    {
        const script = document.createElement( "script" );
        script.src = "https://alcdn.msauth.net/browser/2.19.0/js/msal-browser.min.js";
        script.async = true;
        script.onload = apiLoaded;
        document.body.appendChild( script );
        return () =>
        {
            document.body.removeChild( script );
        };
    }, [] );

    const apiLoaded = () =>
    {
        setApp( new msal.PublicClientApplication( msalParams ) );
    };

    const params = {
        sdk: "8.0",
        entry: {
            oneDrive: {
                files: {}
            }
        },
        authentication: {},
        messaging: {
            origin: window.location.origin,
            channelId: "27"
        },
        typesAndSources: {
            mode: "files",
            pivots: {
                oneDrive: true,
                recent: true
            }
        }
    };

    let win : Window | any;
    let port : MessagePort | any;

    function combine ( ...paths )
    {
        return paths
            .map( path => path.replace( /^[\\|/]/, "" ).replace( /[\\|/]$/, "" ) )
            .join( "/" )
            .replace( /\\/g, "/" );
    }

    async function launchPicker ( e )
    {

        e.preventDefault();

        win = window.open( "", "Picker", "height=600,width=1200" );

        if ( !win || win.closed || typeof win.closed === 'undefined' || win == null )
        {
            alert( 'Popup was blocked. Please allow popups for this site.' );
            win.close();
            return;
        }
        const authToken = await getToken();

        const queryString = new URLSearchParams( {
            filePicker: JSON.stringify( params )
        } );

        const url = `${ baseUrl }?${ queryString }`;

        if ( win )
        {
            const form = win.document.createElement( "form" );
            if ( form )
            {
                form.setAttribute( "action", url );
                form.setAttribute( "method", "POST" );
                win.document.body.appendChild( form );

                const input = win.document.createElement( "input" );
                input.setAttribute( "type", "hidden" );
                input.setAttribute( "name", "access_token" );
                input.setAttribute( "value", authToken );
                form.appendChild( input );

                form.submit();
            } else
            {
                console.error( "Failed to create form." );
            }
        } else
        {
            console.error( "Failed to open the Picker window." );
        }

        window.addEventListener( "message", ( event ) =>
        {

            if ( event.source && event.source === win )
            {

                const message = event.data;

                if ( message.type === "initialize" && message.channelId === params.messaging.channelId )
                {

                    port = event.ports[ 0 ];

                    port.addEventListener( "message", messageListener );

                    port.start();

                    port.postMessage( {
                        type: "activate"
                    } );
                }
            }
        } );
    }

    const processSelectedFile = ( fileObj ) =>
    {
        let link = fileObj[ "@content.downloadUrl" ];
        setExternalLink('')
        HandleAddOneDrive( fileObj )
        console.log( "link::::::", link )
    };

    const uploadFiles = ( commandData ) =>
    {

        let data = commandData.items[ 0 ];
        let tokenObj = getToken();

        let url = `${ data[ "@sharePoint.endpoint" ] }drives/${ data.parentReference.driveId }/items/${ data.id }`;

        tokenObj.then( token =>
        {
            const headers = {
                "Authorization": `Bearer ${ token }`,
                "Content-Type": "image/jpeg"
            };
            fetch( url, {
                method: "GET",
                headers
            } ).then((response:any) =>
            {
                const reader = response.body.getReader();
                const decoder = new TextDecoder( "utf-8" );
                let chunk = "";
                return reader.read().then( function processResult ( result )
                {
                    if ( result.done )
                    {
                        return JSON.parse( chunk );
                    }
                    chunk += decoder.decode( result.value, { stream: true } );
                    return reader.read().then( processResult );
                } );
            } ).then( data =>
            {
                processSelectedFile( data );
            } ).catch( err =>
            {
                console.log( " some error occured ", err );
            } );
        } );

    };

    async function messageListener ( message )
    {
        switch ( message.data.type )
        {
            case "notification":
                console.log( `notification: ${ message.data }` );
                break;
            case "command":
                console.log( "command", message.data  );
                port.postMessage( {
                    type: "acknowledge",
                    id: message.data.id
                } );
                const command = message.data.data;

                switch ( command.command )
                {
                    case "authenticate":
                        // getToken is from scripts/auth.js
                        const token = await getToken();
                        if ( typeof token !== "undefined" && token !== null )
                        {
                            console.log( "authentication", token );
                            port.postMessage( {
                                type: "result",
                                id: message.data.id,
                                data: {
                                    result: "token",
                                    token
                                }
                            } );

                        } else
                        {
                            console.error( `Could not get auth token for command: ${ JSON.stringify( command ) }` );
                        }
                        break;

                    case "close":

                        win.close();
                        console.log( "close::::::::::::::::" );
                        break;

                    case "pick":
                        console.log( "pick file::::::::::::::::" );
                        uploadFiles( command );

                        port.postMessage( {
                            type: "result",
                            id: message.data.id,
                            data: {
                                result: "success"
                            }
                        } );

                        win.close();
                        console.log( "close::::::::::::::::");

                        break;

                    default:

                        console.warn( `Unsupported command: ${ JSON.stringify( command ) }`, 2 );

                        port.postMessage( {
                            result: "error",
                            error: {
                                code: "unsupportedCommand",
                                message: command.command
                            },
                            isExpected: true
                        } );
                        break;
                }

                break;
        }
    }

    async function getToken ()
    {
        let accessToken = "";

        let authParams = { scopes: [ "OneDrive.ReadWrite" ] };

        try
        {
            // see if we have already the idtoken saved
            const resp = await app.acquireTokenSilent( authParams );
            accessToken = resp.accessToken;

        } catch ( e )
        {
            // per examples we fall back to popup
            try
            {
                win.close();
                const resp = await app.loginPopup( authParams );
                win = window.open( "", "Picker", "height=600,width=1200" );
                app.setActiveAccount( resp.account );
                if ( resp.idToken )
                {

                    const resp2 = await app.acquireTokenSilent( authParams );
                    accessToken = resp2.accessToken;

                }
            }
            catch(e)
            {
                if ( win.closed )
                {
                    alert( 'Popup was blocked. Please allow popups for this site.' );
                    win.close();
                    return;
                }
            }
            
        }
        return accessToken;
    }

    return (
        <div>
            <Pressable onPress={ launchPicker }>
                <VStack space={ 3 }>
                    <img src={ onedriveIcon } height={ '32px' } width={ '32px' } />
                    <Text>OneDrive</Text>
                </VStack>
            </Pressable>
        </div>
    );
};

export default OneDrive;
