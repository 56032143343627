import React from 'react';
import { IPost, Person, IFeedParam, AVBase, Feed, Post, Convo } from '../AVCore/AVCore';
import useGetFeeds from '../Hooks/useGetFeeds';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setFeeds, setFeedsUpdate } from '../Redux/Actions/feedsAction';
import { Store } from '../Platform/Store';
import { Server } from '../Platform/Server';
import * as AV from "../AVCore/AVCore";


function useFillFeeds ( props, type )
{
    const dispatch = useDispatch();
    const textEditRef = React.useRef<HTMLInputElement>();
    const userfeeds = useSelector( ( state : RootStateOrAny ) => state.feeds.feeds[ 0 ] );
    const [ videos, setvideos ] = React.useState<any>( [] );
    const [ item, setItem ] = React.useState<any>(
        type === 'feeds'
            ? props?.data?.item === undefined
                ? props?.data
                : props?.data?.item
            : type === 'convos'
                ? props?.data
                : props?.subitem
    );
    const [ userInfo, setuserInfo ] = React.useState( props?.userInfo );
    const [ isAddComment, setIsAddComment ] = React.useState( false );
    const [ previewitem, setpreviewitem ] = React.useState<any>([]);
    const [ isDeleted, setIsDeleted ] = React.useState( false );
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
   /* const { refetch } = useGetFeeds();*/
    const [ editItem, setEditItem ] = React.useState<IPost>( item );
    const [ addComment, setAddComment ] = React.useState<any>('');
    const [ commentValue, setCommentValue ] = React.useState<any>( '' );
    const [ showFilePicker, setShowFilePicker ] = React.useState( false );
    const [ showFBEmoji, setShowFBEmoji ] = React.useState( false );
    const [ showComment, setShowComment ] = React.useState( false );
    const [ showModal, setShowModal ] = React.useState( false );
    const [ media, setMedia ] = React.useState<any>( [] );
    const [ commentMedia, setCommentMedia ] = React.useState<any>( [] );
    const [ gallery, setGallery ] = React.useState<any>( [] );
    const [ showEditPopup, setShowEditPopup ] = React.useState( false );
    const [ showEditPopups, setShowEditPopups ] = React.useState( false );
    const [ showEmoji, setShowEmoji ] = React.useState( false );
    const [ showEditPost, setShowEditPost ] = React.useState( false );
    const [ showDetails, setShowDetails ] = React.useState( false );
    const [ datatype, SetType ] = React.useState<any>( [] );
    const [ parentId, setParentId ] = React.useState( [] );
    const [cmdParent,setCmdParent] = React.useState<any>([])
    const [ commentTo, setCommentTo ] = React.useState<any>();
    const [ images, setImages ] = React.useState<any>( [] ); 
    const [ isCommentLoading, setIsCommentLoading ] = React.useState( false );
    const [ isLikeLoading, setIsLikeLoading ] = React.useState( false );
    const [ showunauthorizedDetails, setshowunauthorizedDetails ] = React.useState( false );
    const [ replyMore, setIsReplyMore ] = React.useState( false );
    const [ editComment, setEditComment ] = React.useState( item?.text );
    const [ showEditFilePicker, setShowEditFilePicker ] = React.useState( !item?.media?.type );

    //const reload = () =>
    //{
    //    refetch();
    //};

    const handleAddComment = async ( commentText ) =>
    {
        if ( commentText )
        {
            setIsAddComment( true );
            setAddComment( commentText );
            setCommentValue( commentText );
        } else
        {
          setAddComment('');
            setIsAddComment( false );
            setCommentValue( '' );
        }
    };
    const handlePostComment = async () =>
    {
        if ( ( addComment !== undefined && addComment?.trim()?.length > 0 ) || commentMedia?.length > 0 )
        {
            try
            {
                setIsCommentLoading( true );
                let post : any = { pk: item ? item.pk : previewitem.pk, region: item ? item?.region : previewitem.region, parentPost: item ? item?.id : previewitem?.id };
                let getpost:any = post;
                if ( datatype === 'ReplyComment')
                {
                    post.parentPost = parentId;
                    post.visibility = cmdParent?.visibility
                }
                else
                {
                    post.parentPost = item ? item?.id : previewitem?.id;
                    post.visibility = item?.visibility
                }
                post.text = addComment;
                if ( commentMedia && commentMedia.length === 0 )
                {
                    post.owner = AV.AVEntity.getFk( item ? item : Person.self() );
                    getpost = await Post.save( post );
                    if ( getpost === "Inappropriate Content" )
                    {
                        setAddComment( '' );
                        setCommentValue( '' );
                        setImages( [] );
                        setMedia( [] );
                        setCommentMedia( [] );
                        setvideos( [] );
                        setIsCommentLoading( false );
                        setShowFilePicker( false );
                        setIsAddComment( false );
                        return;
                    }
                }
                if ( commentMedia && commentMedia.length === 1 )
                {
                    post.media = commentMedia[ 0 ];
                    if ( datatype === 'ReplyComment' )
                    {
                        post.parentPost = parentId;
                        post.visibility = cmdParent?.visibility
                    }
                    else
                    {
                        post.parentPost = item?.id;
                        post.visibility = item?.visibility
                    }
                    post.owner = AV.AVEntity.getFk( item ? item : Person.self() );
                    getpost = await Post.save( post );
                    if ( getpost === "Inappropriate Content" )
                    {
                        setAddComment( '' );
                        setCommentValue( '' );
                        setImages( [] );
                        setMedia( [] );
                        setCommentMedia( [] );
                        setvideos( [] );
                        setIsCommentLoading( false );
                        setShowFilePicker( false );
                        setIsAddComment( false );
                        return;
                    }
                    setCommentMedia( [] );
                    setImages( [] );
                    setMedia( [] );
                }
                if ( commentMedia && commentMedia.length > 1 )
                {
                    let galleryPost:any = [];
                    // post.media = commentMedia[0];
                    if ( datatype === 'ReplyComment' )
                    {
                        post.parentPost = parentId;
                        post.visibility = cmdParent?.visibility
                    }
                    else
                    {
                        post.parentPost = item?.id;
                        post.visibility = item?.visibility;
                    }
                    let gallery = { layout: null, Items: [] };
                    post.gallery = gallery;
                    // let response = await Post.save(post);
                    post.id = Server.generateUUID();
                    let parentPostId = post?.id;
                    let galleryArray : string[] = [];
                    for ( var i = 0; i < commentMedia.length; i++ )
                    {
                        let param1 : IFeedParam = {
                          feed: AVBase.getFk( Person.self() ),
                            startDate: new Date( 0 ), forward:true
                        };
                      if (param1?.feed?.id )
                        {
                        var post1 : any = { pk: param1?.feed?.id, region: param1?.feed?.region };
                            post1.parentPost = parentPostId;
                            post1.media = commentMedia[ i ];
                            post1.ptype = "G";
                            post1.owner = AV.AVEntity.getFk( item ? item : Person.self() );
                            let childresponse : any = await Post.save( post1 );
                            if ( childresponse === "Inappropriate Content" )
                            {
                                setAddComment( '' );
                                setCommentValue( '' );
                                setImages( [] );
                                setMedia( [] );
                                setCommentMedia( [] );
                                setvideos( [] );
                                setIsCommentLoading( false );
                                setShowFilePicker( false );
                                setIsAddComment( false );
                                return;
                            }
                            galleryArray.push( childresponse?.id );
                            galleryPost.push( childresponse );
                        }
                    }
                    post.status = 'New';
                    post.gallery.items = galleryArray;
                    post.owner = AV.AVEntity.getFk( item ? item : Person.self() );
                    getpost = await Post.save( post );
                    if ( getpost === "Inappropriate Content" )
                    {
                        setAddComment( '' );
                        setCommentValue( '' );
                        setImages( [] );
                        setMedia( [] );
                        setCommentMedia( [] );
                        setvideos( [] );
                        setIsCommentLoading( false );
                        setShowFilePicker( false );
                        setIsAddComment( false );
                        return;
                    }
                    getpost.galleryPost = galleryPost;
                    setCommentMedia( [] );
                    setGallery( [] );
                    setImages( [] );
                    setMedia( [] );
                }
              let childPost : any = [];
              let childPostIds : any = [];
                if ( datatype === 'ReplyComment' )
                {
                  if (commentTo?.children?.length > 0) childPost = commentTo.children;
                  if (commentTo?.posts?.length > 0) childPostIds = commentTo.posts;
                  childPost.unshift(getpost);
                  childPostIds.unshift(getpost?.id);
                  commentTo.children = childPost;
                  commentTo.posts = childPostIds
                    setCommentTo( commentTo );
                    const updatedParentPost = replaceChildrenInParent( commentTo?.id, commentTo?.parentPost, userfeeds, commentTo );
                    let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                    userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                    dispatch( setFeeds( userfeeds ) );
                    previewitem?.id?.trim()?.length > 0 ? setpreviewitem( previewitem ) : setItem(commentTo);
                    setAddComment( '' );
                    setCommentValue( '' );
                    setIsCommentLoading( false );
                    setShowFilePicker( false );
                    setIsAddComment( false );
                } else
                {
                    if ( previewitem?.id?.trim()?.length !== undefined  ? previewitem?.children : item?.children )
                        childPost = previewitem?.id?.trim()?.length !== undefined ? previewitem?.children : item?.children;
                    if ( previewitem?.id?.trim()?.length !== undefined  ? previewitem?.children : item?.children )
                        childPostIds = previewitem?.id?.trim()?.length !== undefined ? previewitem?.posts :item?.posts ;
                    childPost.unshift( getpost );
                    childPostIds.unshift( getpost?.id );
                    if ( previewitem?.id?.trim()?.length !== undefined )
                    {
                        previewitem.children = childPost;
                        previewitem.posts = childPostIds;
                    }
                    else
                    {
                        item.children = childPost;
                        item.posts = childPostIds
                    }
                    if ( previewitem?.id?.trim()?.length !== undefined )
                    {
                        const updatedParentPost = findParentComment( previewitem?.id, previewitem?.parentPost, userfeeds, previewitem );
                        let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                        userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                        dispatch( setFeeds( userfeeds ) );
                        previewitem?.id?.trim()?.length !== undefined ? setpreviewitem( previewitem ) : setItem( item );
                        setItem( item )
                        setAddComment( '' );
                        setCommentValue( '' );
                        setIsCommentLoading( false );
                        setShowFilePicker( false );
                        setIsAddComment( false );
                    }
                    else
                    {
                        const updatedParentPost = replaceChildrenInParent( item?.id, item?.parentPost, userfeeds, item );
                        let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                        userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                        dispatch( setFeeds( userfeeds ) );
                        previewitem ? setpreviewitem( previewitem ) : setItem( item );
                        setItem( item )
                        setAddComment( '' );
                        setCommentValue( '' );
                        setIsCommentLoading( false );
                        setShowFilePicker( false );
                        setIsAddComment( false );
                    }

                }

                if ( showFilePicker ) setShowFilePicker( !showFilePicker );
                if ( showEmoji ) setShowEmoji( !showEmoji );
                setIsReplyMore( item?.children[ 0 ]?.children?.length > 0 ? true : false );
                setAddComment( '' );
                setCommentValue( '' );
                setImages( [] );
                setMedia( [] );
                setCommentMedia( [] );
                setvideos( [] );
                setIsCommentLoading( false );
                setShowFilePicker( false );
                setIsAddComment( false );
            } catch ( ex )
            {
                setIsCommentLoading( false );
                console.log('error_message : ' + ex);
            }
        }
    };

    const handleConvoPostComment = async ( item ) =>
    {
        let post = Convo.createConvoPost( item );
        post.text = addComment;
        post.media = commentMedia;
        post.parentPost = item?.id;
        post = await Post.saveConvo( post );
        setIsAddComment( !isAddComment );
    };

    const replacedeleteChildrenInParent = ( postId, parentpostId, nestedData, newChildren ) =>
    {
        for ( const item of nestedData )
        {
            if ( item.id === parentpostId )
            {
                if ( !item?.children )
                    item.children = [];
                let val = item.children.filter( x => x?.id !== postId );
                item.children = val;
                item.posts = item.posts.filter( x => x !== postId );
                return item;
            }
            if ( item.children && item.children.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replacedeleteChildrenInParent( postId, parentpostId, item.children, newChildren );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }
            if ( item?.galleryPost && item.galleryPost.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replacedeleteChildrenInParent( postId, parentpostId, item.galleryPost, newChildren );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }

        }
        return null; // Parent post not found
    };


    React.useEffect( () =>
    {
        setItem( type === 'feeds'
            ? props?.data?.item === undefined
                ? props?.data
                : props?.data?.item
            : type === 'convos'
                ? props?.data
                : props?.subitem )
    }, [ props ] );

    const replaceChildrenInParent = ( postId, parentpostId, nestedData, newChildren ) =>
    {
        for ( const item of nestedData )
        {
            if ( item.id === parentpostId )
            {
                if ( !item?.children )
                    item.children = [];
                let val = item.children.filter( x => x?.id == postId );
                if ( val?.length > 0 )
                    item.children[ item.children.indexOf( val[ 0 ] ) ] = newChildren
                else
                    item.children.push( newChildren );
                return item;
            }

            if ( item.children && item.children.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent( postId, parentpostId, item.children, newChildren );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }
            if ( item?.galleryPost && item.galleryPost.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent( postId, parentpostId, item.galleryPost, newChildren );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }

        }
        return null; // Parent post not found
    };
    const findParentPost = ( postId, parentpostId, nestedData, newChildren ) =>
    {
        for ( const item of nestedData )
        {
            if ( item.id === parentpostId )
            {
                return item;
            }

            if ( item.children && item.children.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent( postId, parentpostId, item.children, newChildren );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }
            if ( item?.galleryPost && item.galleryPost.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent( postId, parentpostId, item.galleryPost, newChildren );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }

        }
        return null; // Parent post not found
    };
    const findParentComment = ( postId, parentpostId, nestedData, newChildren ) =>
    {
        for ( const item of nestedData )
        {
            if ( item.id === parentpostId )
            {
                return item;
            }

        }
        return null; // Parent post not found
    };

    const handleLikePost = async ( post, type ) =>
    {
        setIsLikeLoading( true );
        let existingpost = post?.impressions?.filter( ( x : any ) => x?.id == currentUser?.id && x?.impression == type );
        if ( existingpost?.length == 0 )
        {
            let updatedItem = item;
            post = await Post.addImpression( post, type );
            const updatedParentPost = findParentPost( post?.id, post?.parentPost, userfeeds, post );
            let Index = updatedParentPost?.galleryPost?.findIndex( x => x?.id === post?.id );
            if ( updatedParentPost !== null )
            {
                if ( Index != -1 )
                {
                    post.children = previewitem.children;
                    post.galleryPost = previewitem?.galleryPost;
                    setpreviewitem( post )
                    updatedParentPost.galleryPost[ Index ] = post;
                    let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                    userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                    dispatch( setFeeds( userfeeds ) );
                }
                else if ( Index == -1 )
                {
                    if ( item ? item.id == post.id : previewitem?.id )
                    {
                        post.children = item ? item?.children : previewitem.children;
                        post.galleryPost = item ? item?.galleryPost : previewitem?.galleryPost;
                        if ( item?.galleryPost?.length > 0 )
                        {
                            //let selectimg = item?.galleryPost?.filter( x => x?.id === post?.id );
                            //item.galleryPost[ item.galleryPost.indexOf( selectimg[ 0 ] ) ] = post;
                            setItem( post?.parentPost ? item : post );
                            if ( setpreviewitem )
                                setpreviewitem( post?.parentPost ? item : post );
                            let excitingpost = userfeeds?.filter( x => x?.id == item?.id );
                            if ( excitingpost?.length > 0 )
                            {
                                userfeeds[ userfeeds.indexOf( excitingpost[ 0 ] ) ] = post?.parentPost ? item : post
                                dispatch( setFeeds( userfeeds ) );
                            }
                        }
                        else
                        {
                            setItem( post );
                            let excitingpost = userfeeds?.filter( x => x?.id == item?.id );
                            if ( excitingpost?.length > 0 )
                            {
                                userfeeds[ userfeeds.indexOf( excitingpost[ 0 ] ) ] = post
                                dispatch( setFeeds( userfeeds ) );
                            }
                            else
                            {
                                const updatedParentPost = replaceChildrenInParent( post?.id, post?.parentPost, userfeeds, post );
                                let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                                userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                                dispatch( setFeeds( userfeeds ) );
                            }
                        }
                        setShowFBEmoji( false );
                        setIsLikeLoading( false );
                    }
                }
            }
            if ( item ? item.id == post.id : previewitem?.id )
            {
                post.children = item ? item?.children : previewitem.children;
                post.galleryPost = item ? item?.galleryPost : previewitem?.galleryPost;
                if ( item?.galleryPost?.length > 0 )
                {
                    //let selectimg = item?.galleryPost?.filter( x => x?.id === post?.id );
                    //item.galleryPost[ item.galleryPost.indexOf( selectimg[ 0 ] ) ] = post;
                    setItem( post?.parentPost ? item : post );
                    if ( setpreviewitem )
                        setpreviewitem( post?.parentPost ? item : post );
                    let excitingpost = userfeeds?.filter( x => x?.id == item?.id );
                    if ( excitingpost?.length > 0 )
                    {
                        userfeeds[ userfeeds.indexOf( excitingpost[ 0 ] ) ] = post?.parentPost ? item : post
                        dispatch( setFeeds( userfeeds ) );
                    }
                }
                else
                {
                    setItem( post );
                    let excitingpost = userfeeds?.filter( x => x?.id == item?.id );
                    if ( excitingpost?.length > 0 )
                    {
                        userfeeds[ userfeeds.indexOf( excitingpost[ 0 ] ) ] = post
                        dispatch( setFeeds( userfeeds ) );
                    }
                    else
                    {
                        const updatedParentPost = replaceChildrenInParent( post?.id, post?.parentPost, userfeeds, post );
                        let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                        userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                        dispatch( setFeeds( userfeeds ) );
                    }
                }
                setShowFBEmoji( false );
                setIsLikeLoading( false );
            }
        }
        else
        {
            handleremoveLike( post, type )
        }
    };

    // Like and Feedback Function
    const handleremoveLike = async ( post, type ) =>
    {
        post = await Post.removeImpression( post, type );
        if ( item ? item.id == post.id : previewitem?.id )
        {
            post.children = item ? item?.children : previewitem.children;
            post.galleryPost = item ? item?.galleryPost : previewitem?.galleryPost;
            if (item?.galleryPost?.length > 0 )
            {
                //let selectimg = item?.galleryPost?.filter( x => x?.id === previewitem?.id );
                //item.galleryPost[ item.galleryPost.indexOf( selectimg[ 0 ] ) ] = post;
                setItem( post?.parentPost ? item : post );
                setpreviewitem( post?.parentPost ? item : post );
            }
            else
            {
                setItem( post );
                let excitingpost = userfeeds?.filter( x => x?.id == item?.id );
                if ( excitingpost?.length > 0 )
                {
                    userfeeds[ userfeeds.indexOf( excitingpost[ 0 ] ) ] = post
                    dispatch( setFeeds( userfeeds ) );
                }
                else
                {
                    const updatedParentPost = replaceChildrenInParent( post?.id, post?.parentPost, userfeeds, post );
                    let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                    userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                    dispatch( setFeeds( userfeeds ) );
                }
            }

            setShowFBEmoji( false );
        }
    };


    const onEmojiClick = ( emojiObject ) =>
    {
        const newVal = commentValue === undefined ? emojiObject?.native : commentValue + emojiObject?.native;
        setCommentValue( newVal );
        setAddComment( newVal );
        setShowEmoji( false );
    };

    const handleUpdatePost = async ( e : any ) =>
    {
        e.preventDefault();
        const text = textEditRef?.current?.value || '';
        if ( !text || text.length < 1 ) return;
        if ( commentMedia?.id !== '' )
        {
            item.media = commentMedia;
        }
        item.text = text;
        await Post.save( item );
        setGallery( [] );
        setCommentMedia( {} );
        setShowEditPost( !showEditPost );
    };

    const handleFilePicker = () =>
    {
        setCommentMedia( [] );
        setGallery( [] );
        setImages( [] );
        setMedia( [] );
        setShowFilePicker( !showFilePicker );
        setShowEmoji( false );
    };
    const handleslideChange = () =>
    {
        setCommentMedia( [] );
        setGallery( [] );
        setImages( [] );
        setMedia( [] );
        setShowFilePicker( false );
    };
    const handleimgCancel = () =>
    {
        setCommentMedia( [] );
        setGallery( [] );
        setImages( [] );
        setMedia( [] );
        setShowFilePicker( false );
    };



    const handleEmojiPicker = () =>
    {
        setShowFBEmoji( !showFBEmoji );
    };
    const handleComment = () =>
    {
        setShowComment( !showComment );
    };


    const getImageGalleryFromFilePickerCreateConvo = ( filePickerData : any ) =>
    {

    };
    const handleReplyPost = ( item ) =>
    {
        setCommentTo( item )
        setAddComment('');
        setImages( [] );
        setIsAddComment( !isAddComment );
    };
    const handleEditPopup = async ( item ) =>
    {
        setShowEditPopups( !showEditPopups );
        setShowEditPopup( !showEditPopup );
        setEditItem( item );
    };
    const handleDeletePost = async ( item ) =>
    {
        Post.delete( item );
        let val = userfeeds?.filter( x => x.id === item.id );
        if ( val?.length > 0 )
        {
            val[ 0 ].status = "Deleted"
            userfeeds[ userfeeds.indexOf( val[ 0 ] ) ] = val[ 0 ]
            dispatch( setFeeds( userfeeds ) ); dispatch( setFeedsUpdate( true ) );
        }
        else
        {
            const updatedParentPost = replacedeleteChildrenInParent( item?.id, item?.parentPost, userfeeds, item );
            if ( updatedParentPost )
            {
                let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                dispatch( setFeeds( userfeeds ) );
                dispatch( setFeedsUpdate( true ) );
            }

        }
        setIsDeleted( true );
        setShowEditPopup( !showEditPopup );
        setShowEditPopups( !showEditPopups );
        setShowDetails( false )
    };
    const HandleRejected = async ( item ) =>
    {
        Post.delete( item );
        let val = userfeeds?.filter( x => x.id === item.id );
        if ( val?.length > 0 )
        {
            val[ 0 ].status = "Deleted"
            userfeeds[ userfeeds.indexOf( val[ 0 ] ) ] = val[ 0 ]
            dispatch( setFeeds( userfeeds ) ); dispatch( setFeedsUpdate( true ) );
        }
        else
        {
            const updatedParentPost = replacedeleteChildrenInParent( item?.id, item?.parentPost, userfeeds, item );
            if ( updatedParentPost )
            {
                let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                dispatch( setFeeds( userfeeds ) );
                dispatch( setFeedsUpdate( true ) );
            }

        }
        setIsDeleted( true );
        setShowEditPopup( !showEditPopup );
        setShowEditPopups( !showEditPopups );
        setShowDetails( false )
    };
    const handleReportPost = async ( item ) =>
    {
        Post.Report( item );
        item.modStatus = "NR"
        let val = userfeeds?.filter( ( x ) => x.id === item.id );
        if ( val?.length > 0 )
        {
            let feeds = userfeeds?.filter( ( x ) => x.id !== item.id );
            dispatch( setFeeds( feeds ) );
        }
        else
        {
            if ( val?.length > 0 )
            {
                val[ 0 ].modStatus = "NR"
                userfeeds[ userfeeds.indexOf( val[ 0 ] ) ] = val[ 0 ]
                dispatch( setFeeds( userfeeds ) );
                dispatch( setFeedsUpdate( true ) );
            }
            else
            {
                const updatedParentPost = replacedeleteChildrenInParent( item?.id, item?.parentPost, userfeeds, item );
                if ( updatedParentPost )
                {
                    let exciting = userfeeds?.filter( x => x.id == updatedParentPost?.id );
                    userfeeds[ userfeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                    dispatch( setFeeds( userfeeds ) );
                    dispatch( setFeedsUpdate( true ) );
                }

            }
        }
        setIsDeleted( true );
        setShowEditPopup( !showEditPopup );
        setShowEditPopups( !showEditPopups );
    };

    const handleEditPost = async ( item ) =>
    {

        setShowModal( !showModal );
        if ( !item?.parentPost )
            setShowEditPopup( !showEditPopup );
        setEditItem( item );

    };

     const handleAcceptedPost = async ( item ) =>
    {
        await Post.AcceptReview( item );
        item.modStatus = "RA"
        let excitingpost = userfeeds?.filter( x => x?.id == item?.id );
        userfeeds[ userfeeds.indexOf( excitingpost[ 0 ] ) ] = item
        dispatch( setFeeds( userfeeds ) );
        setShowEditPopup( !showEditPopup );
    }

    const handleEditComment = async ( commentText ) =>
    {
        if ( commentText )
        {
            setEditComment( commentText );
        } else
        {
            setEditComment( '' );
        }
    };

    const handleEditFilePicker = () =>
    {
        setShowEditFilePicker( !showEditFilePicker );
    };

    return {
        item,
        userInfo, setuserInfo,
        setItem,
        isAddComment,
        setIsAddComment,
        addComment,
        setAddComment,
        commentValue,
        setCommentValue,
        showFilePicker,
        setShowFilePicker,
        media,
        setMedia,
        gallery,
        setGallery,
        handleAddComment,
        handlePostComment,
        handleimgCancel,
        handleLikePost,
        showEmoji,
        handleEmojiPicker,
        handleConvoPostComment,
        setShowEmoji,
        showModal,
        handleremoveLike,
        setShowModal,
        SetType,
        setParentId,
        setCommentTo,
        handleFilePicker,
        showComment,
        setShowComment,
        handleComment,
        getImageGalleryFromFilePickerCreateConvo,
        handleReplyPost,
        onEmojiClick,
        showEditPopup,
        setShowFBEmoji,
        showFBEmoji,
        setShowEditPopup,
        handleEditPopup,
        handleDeletePost,
        handleReportPost,
        handleEditPost,
        handleAcceptedPost,
        showEditPost,
        setShowEditPost,
        textEditRef,
        handleEditComment,
        handleUpdatePost,
        editComment,
        showEditFilePicker,
        handleEditFilePicker,
        isDeleted,
        showDetails, setShowDetails,
        showunauthorizedDetails, setshowunauthorizedDetails,
        images,
        setImages,
        setIsCommentLoading,
        isCommentLoading,
        commentMedia,
        setCommentMedia,
        editItem,
        setEditItem,
        previewitem, setpreviewitem, setShowEditPopups, showEditPopups,
        videos, setvideos,
        handleslideChange,
        isLikeLoading,
        HandleRejected,
        cmdParent, setCmdParent

    };
}
export default useFillFeeds;
