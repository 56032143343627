import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loc } from "../App/Loc";

const CustomToast = ({ clientError }) => {
	let val = clientError?.message;
	switch (clientError?.number) {
		case -10001:
			val = Loc.currentLang.toastMsg.inappropriateContent;
			break;
		case -10002:
			val = Loc.currentLang.toastMsg.invalidReoccurance;
			break;
		case -10003:
			val = Loc.currentLang.toastMsg.sizeLimitExceeded;
			break;
		case -10004:
			val = Loc.currentLang.toastMsg.typeNotDefined;
			break;
		case -10006:
			val = Loc.currentLang.toastMsg.cannotRemoveAllStaff;
			break;
		case -10007:
			val = Loc.currentLang.toastMsg.badToken;
			break;
		case -10008:
			val = Loc.currentLang.toastMsg.requestLimitExceeded;
			break;
		case -10010:
		case -10009:
			val = Loc.currentLang.toastMsg.wrongOwnerType;
			break;
		case -10011:
			val = Loc.currentLang.toastMsg.openRegCodenull;
			break;
		case -10013:
		case -10012:
			val = Loc.currentLang.toastMsg.gameTypenotdefined;
			break;
		case -2146233088:
			val = Loc.currentLang.toastMsg.typeNotDefined;
			break;
		case -2147467261:
			val = Loc.currentLang.toastMsg.valueCannotbeNull;
			break;
		case -2147467261:
			val = Loc.currentLang.toastMsg.objectNullError;
			break;
	}
	toast.error(val, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	return <ToastContainer />;
};

export default CustomToast;
