import { Box, Divider, Text, VStack, HStack, Pressable } from "native-base";
import React from "react";
const Placeholder = () => {
	return (
		<>
			<Box width={"227px"} position={"fixed"}>
				{/*<VStack height={'100%'} space={1} >*/}
				{/*    <Text fontWeight={'bold'}>Sponsored</Text>*/}
				{/*    <Box borderRadius={5} p={1} backgroundColor={'AVColor.white'}>*/}
				{/*        <img*/}
				{/*            src='https://upload.wikimedia.org/wikipedia/en/thumb/f/f2/Premier_League_Logo.svg/1200px-Premier_League_Logo.svg.png'*/}
				{/*            style={{ borderRadius: '4px', marginBottom: '3px' }}*/}
				{/*        />*/}

				{/*        <Text>loreum ipsum...</Text>*/}
				{/*        <Text fontSize={'14px'}>www.loreumipsum.com</Text>*/}
				{/*    </Box>*/}

				{/*    <Divider my={2}></Divider>*/}
				{/*    <Box borderRadius={5} p={1} backgroundColor={'AVColor.white'}>*/}
				{/*        <img*/}
				{/*            src='https://www.mlbstatic.com/team-logos/share/mlb.jpg'*/}
				{/*            style={{ borderRadius: '4px', marginBottom: '3px' }}*/}
				{/*        />*/}
				{/*        <Text>loreum ipsum</Text>*/}
				{/*        <Text fontSize={'14px'}>www.loreum.com</Text>*/}
				{/*    </Box>*/}
				{/*</VStack>*/}
			</Box>
		</>
	);
};

export default Placeholder;
