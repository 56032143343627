import { AddIcon, Pressable } from "native-base";
import React, { FC } from "react";

interface Props {
	addEvent: any;
	bgcolor?: string;
	color?: string;
}
const AVAdd: FC<Props> = ({ addEvent, bgcolor, color }) => {
	return (
		<Pressable backgroundColor={bgcolor ? bgcolor : "AVColor.grey"} padding={"5px"} rounded={"full"} onPress={addEvent}>
			<AddIcon size={"10px"} color={color ? color : "AVColor.secondary"} />
		</Pressable>
	);
};

export default AVAdd;
