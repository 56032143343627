import { ChevronLeftIcon, ChevronRightIcon, CloseIcon, Pressable } from "native-base";
import React, { useEffect, useState } from "react";
import * as AV from "AVCore/AVCore";

const PreviewAlbums = ( { setShowPreview, item, items, setShowAllImage }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ currentImage, setCurrentImage ] = useState<any | undefined>();
  console.log( "AV.Media.getURL(currentImage)", AV.Media.getURL( item ) )


  useEffect(() => {
    items
      .filter((item, index) => {
        return item; //.type.startsWith('image') || item.type.startsWith('video');
      })
      .map((img, index) => {
        if (img?.id === item?.id) {
          setCurrentIndex(index);
          setCurrentImage(img);
        }
      });
  }, []);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? items.length - 1 : currentIndex - 1;
    setCurrentImage(items[newIndex]);
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === items.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentImage(items[newIndex]);
    setCurrentIndex(newIndex);
  };
  return (
    <div className='show-album'>
          <Pressable position='absolute' right={ 3 } top={ 3 } onPress={ () => {setShowPreview(false);setShowAllImage([])}}>
        <CloseIcon color='red.100' size={'18px'} />
      </Pressable>

			<div>
				{items?.length > 1 && (
					<>
						<div onClick={goToPrevious} className="leftArrowStyles">
							<ChevronLeftIcon size={"30px"} color={"#fff"} />
						</div>
						<div onClick={goToNext} className="rightArrowStyles">
							<ChevronRightIcon size={"30px"} color={"#fff"} />
						</div>
					</>
				)}
			</div>

			<div className="img-container">
				{currentImage && (
					<>
						{currentImage.type.startsWith("image") ? (
							<img src={item ? AV.Media.getURL(currentImage) : ""} alt="" className="full-width-image" />
						) : (
							<>
								<video
									controls
									// position='absolute'
									// width={'110px'}
									// height={'120px'}
									src={item && AV.Media.getURL(currentImage)}
									className="full-width-image"
									// key={item + i}
								/>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PreviewAlbums;
