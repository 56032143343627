import * as React from "react";

const Events = ({ color }) => (
	<>
		<path
			d="M18.918 4.094H16.94v-.988a.988.988 0 1 0-1.976 0v.988h-5.93v-.988a.988.988 0 0 0-1.976 0v.988H5.082A2.965 2.965 0 0 0 2.118 7.06v11.859a2.965 2.965 0 0 0 2.964 2.964h13.836a2.965 2.965 0 0 0 2.964-2.964V7.058a2.965 2.965 0 0 0-2.964-2.964Zm.988 14.824a.988.988 0 0 1-.988.988H5.082a.988.988 0 0 1-.988-.988V12h15.812v6.918ZM14.52 13.52h1.801a1 1 0 0 1 1 1v1.801a1 1 0 0 1-1 1h-1.8a1 1 0 0 1-1-1v-1.8a1 1 0 0 1 1-1Zm5.386-3.496H4.094V7.059c0-.546.443-.988.988-.988H7.06v.988a.988.988 0 1 0 1.976 0V6.07h5.93v.988a.988.988 0 1 0 1.976 0V6.07h1.977c.545 0 .988.442.988.988v2.965Z"
			id="Shape"
			fillOpacity="0.85"
			fill={color ? color : "#000000"}
			fillRule="nonzero"
		></path>
	</>
);

export default Events;
