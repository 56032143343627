//import authService from "../components/api-authorization/AuthorizeService"
import { IFK } from "../AVCore/AVCore";
import { AVAuth } from "./AVAuth";
import Settings from "./settings.json";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
import { Alert } from "../AVCore/Alert";
import { ILink } from "../AVCore/Interfaces/AV.Interfaces";
import CustomToast from "../Hooks/useCustomToast";
import { Config } from "../App/Config";
import { CheckInternetConnectivity } from "../components/common/AVInternetCheck";
import { SSE } from "../Hooks/SSE";

export interface IClientException {
	number: number;
	message: string;
	source: string;
}
export class ServerResponse<T> implements IClientException {
	data: Promise<T> | null = null;
	number: number = 0;
	message: string = "";
	source: string = "";
}

export class Server {
	static _host: string = window.location.origin;
	static _curPage: string = "";
	static _impersonate: string = "";
	static _loginLink?: ILink;
	static _isValidClaim?: boolean;
	public static SSE: EventSourcePolyfill = null;
	public static async init() {
		const token = await AVAuth.getToken();
		var bearer = "Bearer " + token;
		var headers: Record<string, string> = { Authorization: bearer };

		return { host: this._host, bearer: bearer, impersonate: Server._impersonate };
	}
	public static impersonate(ward?: IFK, self?: ILink) {
		if (ward) {
			Server._impersonate = ward.id + ";" + ward.region;
			Server._loginLink = self;
		} else Server._impersonate = "";
	}
	public static GetLoginLink(): ILink | undefined {
		return Server._loginLink;
	}

	public static async get<T>(api: string, param?: object, anonymous: boolean = false): Promise<T> {
		//let retval = await Server.request<T>(api, 'GET', param);
		return await Server.request<T>(api, "GET", param, undefined, false, "application/json", anonymous);
	}
	public static async post<T>(
		api: string,
		body?: object | string,
		raw: boolean = false,
		contentType: string = "application/json",
		anonymous: boolean = false,
	): Promise<T> {
		//@ts-ignore
		return await Server.request<T>(
			api,
			"POST",
			undefined,
			//@ts-ignore
			raw ? body : JSON.stringify(body),
			raw,
			contentType,
			anonymous,
		);
	}
	public static async delete<T>(api: string, param: object, anonymous: boolean = false): Promise<T> {
		return await Server.request<T>(
			api,
			"DELETE",
			undefined,
			JSON.stringify(param),
			false,
			"application/json",
			anonymous,
		);
	}
	public static async request<T>(
		endpoint: string,
		method: string = "GET",
		params?: object,
		body?: string,
		raw: boolean = false,
		contentType: string = "application/json",
		anonymous: boolean = false,
	): Promise<never | T> {
		const result = await CheckInternetConnectivity(body, endpoint);
		if (!result.isConnected) {
			return result.returnvalue;
		}

		Server._curPage = window.location.href;
		endpoint = Server._host + endpoint;
		let query = "";
		if (params && Object.keys(params).length > 0) {
			//@ts-ignore
			query =
				"?" +
				Object.keys(params)
					.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
					.join("&");

			endpoint += query;
		}

		var header: HeadersInit = {};
		if (!anonymous) {
			const token = await AVAuth.getToken();
			if (!token) {
				console.log("Bad Token");
				throw new Error("Bad Token");
			}
			Server._isValidClaim = AVAuth.isValidClaim();
			//if (Server.SSE == null)
			//  (await SSE()).SSEupdate();
			var bearer = "Bearer " + token;
			header["Authorization"] = token ? bearer : "";
		}

		header["Accept"] = "application/json";
		header["X-AVKey"] = this.getKey(body != undefined && body.length > 0 ? body : query);
		if (Server._impersonate != "") {
			header["AVImpersonate"] = Server._impersonate;
		}

		if (raw) {
			header["cache"] = "false";
			header["processData"] = "false";
		} else {
			//'multipart/form-data; boundary=' + this.generateUUID()
			header["Content-Type"] = contentType;
		}

		return await fetch(endpoint, {
			method: method,
			body: body ? body : null,
			credentials: "include",
			headers: header,
		})
			.then(
				await async function (response): Promise<T> {
					if (response.redirected) {
						//TODO: Handle redirectback to initital page
						let newURL = response.url.substring(0, response.url.indexOf("?"));
						newURL += "?" + Server._curPage;
						window.location.href = newURL;
						//@ts-ignore
						return null;
					} else if (response.status === 401) {
						AVAuth.logout();
						//@ts-ignore
						return null;
					} else if (response.ok) {
						const contentType = response.headers.get("content-type");
						if (contentType && contentType.includes("application/json")) {
							return response.json();
						} else if (
							(contentType &&
								contentType?.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) ||
							contentType?.includes("text/csv") ||
							contentType?.includes("text/plain") ||
							contentType?.includes("audio/wav") ||
							contentType?.includes("application/vnd.ms-excel") ||
							contentType?.includes("application/pdf") ||
							contentType?.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
							contentType?.includes("application/msword")
						) {
							//@ts-ignore
							const blob = await response.blob();
							const url = URL.createObjectURL(blob);
							//@ts-ignore
							return url; // You can return the URL or any other data you need to handle the file
						} else {
							//@ts-ignore
							return null; // Return null for non-JSON responses
						}
					} else {
						//This is a server error, return error object
						// try {
						var clientError = (await response.json()) as IClientException;
						if ( clientError.number === 10001 )
						{
							CustomToast({ clientError });
							//@ts-ignore
							return clientError;
						}
						if (clientError?.number === -2146233088) {
							//@ts-ignore
							return clientError;
						} else if (clientError?.number !== -10008) {
							CustomToast({ clientError });
						}
						var error = new Error(clientError?.message);
						error.stack = clientError?.source;
						error.name = clientError?.number?.toString();
						throw error;
						//throw await response.json() as Promise<IClientException>;

						//}
						//catch (err)
						//{
						//}
						//@ts-ignore
						//return null;
					}
				},
			)
			.then(data => {
				// This is an error response, need to handle in UI
				//@ts-ignore
				if (data?.number != undefined) {
					console.log(data);
					//@ts-ignore
					return data;
				} else return data;
			})

			.catch(err => {
				console.log(err);
				throw err;
				//@ts-ignore
				//return null as Promise<T>;
			});
	}

	public static generateUUID(): string {
		// Public Domain/MIT
		var d = new Date().getTime(); //Timestamp
		var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			var r = Math.random() * 16; //random number betwe    en 0 and 16
			if (d > 0) {
				//Use timestamp until depleted
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {
				//Use microseconds since page-load if supported
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			// eslint-disable-next-line
			return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
		});
	}
	public static getKey(str: string | undefined): string {
		if (str == undefined || typeof str != "string") return this.shift(Config.key, 17);

		let length = new Blob([str]).size;
		if (length == 0) length = 17;
		return this.shift(Config.key, length % 256);
	}
	public static shift(str: string, shift: number = 17): string {
		if (str == null || typeof str != "string") {
			if (typeof str == "boolean") {
				if (str) str = "true";
				else str = "false";
			} else return str;
		}

		var max = 256; // 65535 + 1;
		var out = "";
		for (var i = 0; i < str.length; i++) {
			var char = str.charCodeAt(i);
			let hex = ((char + shift + max) % max).toString(16).toUpperCase();
			while (hex.length < 4) {
				hex = "0" + hex;
			}
			out += hex;
		}
		return out;
	}
	public static unShift(str: string, shift: number = -17): string {
		if (str == null || typeof str != "string") return str;

		var max = 256; // 65535 + 1;
		var out = "";
		for (var i = 0; i < str.length; i += 4) {
			var sChar = parseInt(str.substring(i, 4), 16);
			let char = (sChar + shift + max) % max;

			out += String.fromCharCode(char);
		}
		return out;
	}
}
