export const extractUrlsFromText = text => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	const urls = text.match(urlRegex);

	return urls;
};

export const extractYouTubeVideoId = url => {
	const videoIdRegex = /(?:\/embed\/|\/watch\?v=|\/(?:embed\/|v\/|watch\?.*v=|youtu\.be\/|embed\/|v=))([^&?#]+)/;
	const match = url.match(videoIdRegex);
	return match ? match[1] : "";
};

export const isYouTubeURL = url => {
	const videoIdRegex = /(?:\/embed\/|\/watch\?v=|\/(?:embed\/|v\/|watch\?.*v=|youtu\.be\/|embed\/|v=))([^&?#]+)/;

	if (url.includes("youtube.com") || url.includes("youtu.be")) {
		const match = url.match(videoIdRegex);
		const videoId = match[1];
		return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
	}
	return false;
};

export const isHuddleURL = url => {
	let match = url.match(/\/watch\/video\/([^\/]+)/);

	if (url.includes("//huddlehuddle.com")) {
		const videoId = match[1];
		return `https://player.huddlehuddle.com/?source=watch&id=${videoId}`;
	}
	return false;
};

export const getContentTypeFromUrl = url => {
	let queryStringIndex = url.indexOf("?");
	let cleanUrl = queryStringIndex === -1 ? url : url.substring(0, queryStringIndex);
	let extension = cleanUrl.split(".").pop().toLowerCase();

	// Define arrays of known media file extensions
	let imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
	let videoExtensions = ["mp4", "avi", "mov", "wmv", "mkv"];

	// Check if the extension matches
	if (imageExtensions.includes(extension)) {
		return "image";
	} else if (videoExtensions.includes(extension)) {
		return "video";
	} else {
		return "unknown";
	}
};

export const sanitizedText = text => {
	const urls = extractUrlsFromText(text);
	let domainMatch = text.match(/https?:\/\/([^\/]+)/);
	let outputString = text;

	if (domainMatch) {
		outputString = text.replace(/https?:\/\/[^\s]+/, "");
	}

	return { outputString, urls };
};

export const extractDomain = url => {
	const parsedUrl = new URL(url);

	const isHttpOrHttps = parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
	if (isHttpOrHttps) {
		return { host: `${parsedUrl.protocol}//${parsedUrl.hostname}`, domain: parsedUrl.hostname };
	} else {
		return null;
	}
};
