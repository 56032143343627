import * as React from 'react';
import { AppServiceWorker } from 'react-service-worker';
import { Main, Signin } from './Screens';
import { Provider } from 'react-redux';
import { store, persistor } from './Redux/store';
import AVAlert from 'components/Alerts/AVAlert';
import './custom.css';
import { SSE } from './Hooks/SSE';
import { setNewAlerts } from './Redux/Actions/alertActions';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { AVAuth } from './Platform/AVAuth';
import { PageLoader } from './components/loaders';
import FirebaseNotifications from './Utility/FirebaseNotifications';
import { Server } from './Platform/Server';
import { RootStateOrAny, useSelector } from 'react-redux';
import ToastMessage from './Hooks/useAlertToast';
import useConvoChat from './Hooks/useConvoChat';
import * as AV from './AVCore/AVCore';
import _ from 'lodash';
import { setConvo, setConvoState } from "./Redux/Actions/convoActions";
import { setLastFeedUpdate } from "./Redux/Actions/userActions";

interface AppState
{
    showInstalledMessage : boolean;
    showUpdateMessage : boolean;
    isLoading : boolean;
}

export class App extends React.Component<AppServiceWorker, AppState> {
    isInit : boolean = false;
    constructor ( props )
    {
        super( props );
        this.state = { showInstalledMessage: false, showUpdateMessage: false, isLoading: true };
    }

    async init ()
    {
        this.setState( { isLoading: true } );
        const { appServiceWorker } = this.props;
        appServiceWorker.onInstalled( () => this.setState( { showInstalledMessage: true } ) );
        appServiceWorker.onUpdateFound( () => this.setState( { showUpdateMessage: true } ) );
        this.isInit = true;
        this.forceUpdate();
    }

    componentDidMount ()
    {
        this.init();
        const reduxState : RootStateOrAny = store.getState();
        async function handleNewDMAlert ( item )
        {

            console.log( "processing newDM alert in SSE....." )

            const reduxState : RootStateOrAny = store.getState();
            const { user, convo } = reduxState;
            const userconvo = convo.convo[ 0 ];
            const lastUpdateFeed = user.lastUpdateFeed;
            const feedId = item?.data?.feed?.id;
            const postdata = userconvo?.find( ( x ) => x?.id === feedId );

            const post = postdata?.posts?.find( ( x ) => x?.id === item?.target?.id );

            let PostList : any = [];
            PostList[ 0 ] = item?.target?.id;
            let response = userconvo?.find( ( x : any ) => x?.id === item?.data?.feed?.id )
            if ( response )
            {
                await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList.slice( 0, 50 ) ).then( ( x : any ) =>
                {
                    if ( x?.length > 0 )
                    {
                        var usercon = userconvo || [];
                        let Index = response.posts?.findIndex( ( y ) => y.id == x[ 0 ]?.id )
                        if ( Index !== -1 )
                        {
                            response.posts = response.posts?.filter( ( y ) => y.id !== x[ 0 ]?.id )
                            response.posts?.unshift( x[ 0 ] );
                            response.posts = list_to_tree( response?.posts )
                            response?.posts?.sort( ( a, b ) =>
                            {
                                return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                            } );
                            usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                            usercon.unshift( response );
                            usercon = _.uniqBy( usercon, 'id' );
                        }
                        else
                        {
                            response.posts?.unshift( x[ 0 ] );
                            response.posts = _.uniqBy( response?.posts, 'id' );
                            //response.lastUpdated = x[ 0 ].lastUpdated;
                            response.posts = list_to_tree( response?.posts )
                            response?.posts?.sort( ( a, b ) =>
                            {
                                return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                            } );
                            usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                            usercon.unshift( response );
                            usercon = _.uniqBy( usercon, 'id' );
                        }
                    }
                    store.dispatch( setConvo( usercon ) );
                } );
            }
            else
            {
                const feedParam : AV.IFeedParam = {
                    feed: item?.data?.feed,
                    startDate: postdata?.posts?.length > 0 ? postdata?.lastUpdated : new Date( 0 ), forward: true
                };
                await AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
                {
                    if ( response?.id )
                    {
                        let PostList = await AV.Feed.getPostList( response );
                        PostList = PostList?.filter( item => item !== null );
                        if ( PostList.length > 0 )
                        {
                            await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList ).then( ( x : any ) =>
                            {
                                if ( x?.length > 0 )
                                {
                                    response.posts = x;
                                    response.posts = list_to_tree( response?.posts )
                                    var usercon = userconvo || [];
                                    const existingconvo = usercon.find( ( x : any ) => x?.id === response?.id );
                                    if ( !existingconvo )
                                    {
                                        usercon.unshift( response );
                                    }
                                    store.dispatch( setConvo( usercon ) );
                                }
                            } );
                        }
                    }
                } );
            }

            //Temporary code to update feeds in background
            const feedParam : AV.IFeedParam = {
                feed: item?.data?.feed,
                startDate: postdata?.posts?.length > 0 ? postdata?.lastUpdated : new Date( 0 ), forward: true
            };
            AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
            {
                if ( response?.id )
                {

                    //set last successful get feed
                    let data : any = {
                        Ifeed: feedParam,
                        lasttime: new Date()
                    };
                    let Index = lastUpdateFeed?.findIndex( ( x ) => x?.Ifeed?.feed?.id == item?.feed?.id )
                    if ( Index !== -1 )
                    {
                        lastUpdateFeed[ Index ] = data
                    }
                    store.dispatch( setLastFeedUpdate( lastUpdateFeed ) );


                    let PostList = await AV.Feed.getPostList( response );
                    PostList = PostList?.filter( item => item !== null );
                    if ( PostList.length > 0 )
                    {
                        AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList ).then( ( x : any ) =>
                        {
                            if ( x?.length > 0 )
                            {
                                response.posts = x;
                                var usercon = userconvo || [];
                                const existingconvo = usercon.find( ( x : any ) => x?.id === response?.id );
                                if ( !existingconvo )
                                {
                                    let user : any = AV.Person._self;
                                    if ( usercon?.length == 0 && ( user?.convos == undefined || user?.convos?.length == 0 ) )
                                        usercon.unshift( response );
                                    else
                                        return;
                                } else
                                {
                                    let updatedFeeds = [ ...( existingconvo?.posts || [] ) ];
                                    response?.posts.forEach( ( newconvo : any ) =>
                                    {
                                        let existingFeed = existingconvo?.posts?.find( ( x : any ) => x?.id === newconvo?.id );
                                        if ( !existingFeed )
                                        {
                                            updatedFeeds.unshift( newconvo );
                                        } else
                                        {
                                            existingFeed.impressions = newconvo.impressions;
                                            existingFeed.text = newconvo.text;
                                        }
                                    } );
                                    existingconvo.posts = _.uniqBy( [ ...updatedFeeds ], 'id' );
                                    //existingconvo.lastUpdated = response.lastUpdated;
                                    existingconvo.people = response.people;
                                    usercon.unshift( existingconvo );
                                    usercon = _.uniqBy( usercon, 'id' );
                                }
                                store.dispatch( setConvo( usercon ) );
                            }
                        } );
                    }
                }
            } );
        }
        const list_to_tree = ( list ) =>
        {
            var map = {},
                node : any,
                roots : any = [],
                i;

            for ( i = 0; i < list?.length; i += 1 )
            {
                map[ list[ i ]?.id ] = i; // initialize the map
                if ( list[ i ]?.children?.length === 0 || !list[ i ]?.children ) list[ i ][ 'children' ] = []; // initialize the children
                if ( list[ i ]?.galleryPost?.length === 0 || !list[ i ]?.galleryPost ) list[ i ][ 'galleryPost' ] = [];
                list[ i ][ 'ReplyPost' ] = [];
            }

            for ( i = 0; i < list?.length; i += 1 )
            {
                node = list[ i ];
                if ( node.parentPost !== undefined )
                {
                    if (
                        list[ map[ node.parentPost ] ]?.gallery?.items?.filter( ( x ) => x === node.id )?.length > 0 &&
                        node.gallery == undefined
                    )
                    {
                        let excitingpost = list[ map[ node.parentPost ] ]?.galleryPost?.filter(
                            ( x ) => x?.id === node?.id
                        );
                        if ( excitingpost?.length === 0 || excitingpost === undefined )
                            list[ map[ node.parentPost ] ]?.galleryPost.push( node );
                    } else
                    {
                        let PPost = list.filter( ( x ) => x?.id === node?.parentPost );
                        node.ReplyPost.push( PPost[ 0 ] );
                        roots.push( node );
                    }
                } else
                {
                    roots.push( node );
                }
            }
            return roots;
        };
        const { isFBNotificationEnabled } = reduxState;
        setTimeout( () =>
        {
            this.setState( { isLoading: false } );
        }, 2000 );

        let processedAlertIds = new Set();
        async function fetchAlerts ( isFBNotificationEnabled )
        {
            await ( await SSE() ).fetchAlertsOnfocus( isFBNotificationEnabled );
        }
        navigator.serviceWorker.addEventListener( 'message', ( event ) =>
        {
            if ( event.data.payload)
            {
                console.log( 'Background message received in client:', event.data.payload );

                let alertData = event?.data?.payload.data.data ? JSON.parse( event.data.payload.data.data ) : null;
                if ( alertData?.alertType )
                {
                    if ( !processedAlertIds.has( alertData.id ) )
                    {
                        const reduxState : RootStateOrAny = store.getState();
                        const { alerts, user } = reduxState;
                        console.log( 'isConvoScreenOpen...... ', user.isConvoScreenOpen );
                        let alertsVal = alerts.alert[ 0 ];
                        const existingAlertIds = new Set( alertsVal?.map( alert => alert?.id ) );
                        if ( !existingAlertIds.has( alertData.id ) )
                        {
                            let ToastAlert = { type: alertData?.alertType, message: event?.data?.notification?.body?.slice( 0, 100 ) + '...' };
                            //Check if convo screen is open and if current convo id and alert convo matches, then don't show the toast
                            if ( ( user.isConvoScreenOpen.convoId != alertData.target?.pk || Server.SSE == null )
                                && ( ( alertData?.alertType == "NewDM" || alertData?.alertType == "Impression" || alertData?.alertType == "ReplyDM" ) && ( alertData.data?.source ? alertData.data?.source?.id != user.user[ 0 ].id : alertData.data?.person?.id != user.user[ 0 ].id ) ) )
                            {
                                //ToastMessage( { ToastAlert } );
                            }
                            store.dispatch( setNewAlerts( [ alertData ] ) );
                            fetchAlerts( isFBNotificationEnabled );

                        }
                        processedAlertIds.add( alertData.id );

                        //Temp Code
                        if ( ( alertData?.alertType == "NewDM" || alertData?.alertType == "ReplyDM" ) )
                        {
                            console.log( "Received FB alert for convo...." );
                        }

                        if ( ( user.isConvoScreenOpen.convoId != alertData.target?.pk || Server.SSE == null )
                            && ( ( alertData?.alertType == "NewDM" || alertData?.alertType == "ReplyDM" ) && alertData.data?.person?.id != user.user[ 0 ].id ) )
                        {
                            handleNewDMAlert( alertData );
                        }
                    }
                } else
                {
                    fetchAlerts( isFBNotificationEnabled );
                }
            }
        } );
    }

    render ()
    {
        const user = AVAuth.currentUser();
        const isUserLoggedIn = !navigator.onLine && user?.username;

        return (
            <>
                { this.state.isLoading && <PageLoader /> }
                <Provider store={ store }>
                    <PersistGate persistor={ persistor }>
                        {
                            this.state.showUpdateMessage && (
                                <AVAlert message={ 'New content is available; please reload or press Ctrl + F5.' } />
                            ) }
                        <ToastContainer />
                        <FirebaseNotifications />
                        { !isUserLoggedIn ? (
                            //<AuthenticatedTemplate>
                                <Main />
                            //</AuthenticatedTemplate>
                        ) : (
                            <Main />
                        ) }
                        { !isUserLoggedIn && <UnauthenticatedTemplate>{ <Signin /> }</UnauthenticatedTemplate> }
                    </PersistGate>
                </Provider>
            </>
        );
    }
}
