import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { HStack, Box, VStack, Text, Button, Input, ScrollView, FlatList, Pressable, Badge, Avatar, Divider } from 'native-base';
import { useWindowDimension } from '../../Utility/WindowDimensions';
import { Loc } from '../../App/Loc';
import { SearchIcon, CreateMessageIcon, TeamProfile, FavouriteIcon, Community, Neighbor, Organization, GradClass, Group, Class, EliteClub, School, Profile, Event, LogoSvgIcon, PerformanceGroup, FriendsIcon, FeedbackIcon, BugReportIcon, FeatureRequestIcon, Club } from '../../assets/icons/icon-assets';
import AVCancel from '../../components/common/AVCancel';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import ProfilePic from '../common/ProfilePicture';
import { Link } from '../../AVCore/Link';
import { View } from 'react-native';
import { setAlerts } from '../../Redux/Actions/alertActions';
import { Alert } from 'AVCore/Alert';
import * as AV from '../../AVCore/AVCore';
import { setIsConvoScreenOpen } from '../../Redux/Actions/userActions';
import { uniqBy } from 'lodash';
import useInitSSE from '../../Hooks/useInitSSE';
import { SignalRService } from '../../AVCore/SignalR'
import LogoSvg from "../../assets/images/Actavivo-Logo.svg";


interface Props {
	isHeader: any | undefined;
	convosearch: any | undefined;
	convoListData: any | undefined;
	userconvolistdata: any | undefined;
	UnReadChatMessage: any | undefined;
	handleGetConvo: any | undefined;
	setShowEmoji: any | undefined;
	setShowConvoFilePicker: any | undefined;
	setValue: any | undefined;
	setShowAlertModal: any | undefined;
	setShowFollowingModal: any | undefined;
	setShowConvoListModal: any | undefined;
	setMdlConvoVisible: any | undefined;
	setMdlCreateConvoVisible: any | undefined;
	setConvoLoading: any | undefined;
	handleCreateModel: any | undefined;
	setconvosearch: any | undefined;
	setconvoListData: any | undefined;
    convoval : any | undefined;
    setIsOpenConvo : any | undefined;
    setIsConvoData : any | undefined;
    isOpenConvo : any | undefined;
    TriggerOpenConvo : any | undefined;
    setIsTriggerOpenConvo : any | undefined;
    isGroupChat : any | undefined;  
    setIsGroupChat : any | undefined;
    isNewChat : any | undefined;
    setIsNewChat : any | undefined;
}

const CreateNewMessage : FC<Props> = ( { isHeader, TriggerOpenConvo, isNewChat, setIsNewChat,setIsTriggerOpenConvo, isGroupChat,setIsGroupChat,convosearch, convoListData, userconvolistdata, UnReadChatMessage, handleGetConvo, setShowEmoji, setShowConvoFilePicker, setValue, isOpenConvo,setShowAlertModal, setShowFollowingModal, setShowConvoListModal, setMdlConvoVisible, setMdlCreateConvoVisible, setConvoLoading, handleCreateModel, setconvosearch, setconvoListData, convoval, setIsOpenConvo, setIsConvoData }) => {
    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const isMobile = dimensions?.width <= 500 || false;
    const stateUser = useSelector((state : RootStateOrAny) => state);
    const currentUser = stateUser?.user?.user[ 0 ];
    const userconvo = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const useralertList = useSelector((state : RootStateOrAny) => state?.alerts.alert[ 0 ]);
    const isConvoScreenOpen = useSelector( ( state : RootStateOrAny ) => state.user.isConvoScreenOpen );
    const { CloseSSE } = useInitSSE();

  const entitydatalist = useSelector((state : RootStateOrAny) => state.user.entitydatalist);
  const userConvoList = useSelector((state : RootStateOrAny) => state.convo.convo[ 0 ] || []);

    const calculateMaxLastUpdated = ( conversation ) =>
    {
        let maxLastUpdated = new Date( 0 ); // Initialize with a minimum date

        // Check if conversation.createDate exists and is valid
        if ( conversation?.createDate )
        {
            const createDate = new Date( conversation.createDate );

            // Compare createDate with maxLastUpdated
            if ( createDate > maxLastUpdated )
            {
                maxLastUpdated = createDate;
            }
        }

        // Check if conversation.posts exists and is an array
        if ( Array.isArray( conversation?.posts ) )
        {
            conversation.posts.forEach( post =>
            {
                const postLastUpdated = new Date( post.lastUpdated );
                if ( postLastUpdated > maxLastUpdated )
                {
                    maxLastUpdated = postLastUpdated;
                }
            } );
        }

        return maxLastUpdated;
    };

    useEffect( () =>
    {
        if (!isOpenConvo && TriggerOpenConvo)
        {
            setIsOpenConvo( true );
            setIsTriggerOpenConvo(false)
        }
    }, [ TriggerOpenConvo ])


	// Memoized sorted conversation list with unique entries
	// Remove duplicate conversations based on the id
	const uniqueConvoList = uniqBy(userConvoList, "id");

	// Sort the unique conversation list based on the maximum lastUpdated timestamp
	const sortedConvoList = uniqueConvoList.slice().sort((a, b) => {
		const maxLastUpdatedA: any = calculateMaxLastUpdated(a);
		const maxLastUpdatedB: any = calculateMaxLastUpdated(b);
		return maxLastUpdatedB - maxLastUpdatedA; // Sort in descending order based on max lastUpdated
    } );

    const Renderconvo = useCallback((convo : any) => {
        let item = convo.item;
        let BlockedUser =  item?.people?.filter( x =>
            currentUser?.blocked?.some( blockedUser => blockedUser?.id === x?.id )
        );
        if ( BlockedUser?.length > 0 )
        {
            item = [];
        }
      let index = convo.index;
      let profile = item?.people?.length > 0 &&  item?.people?.filter(
            (x) => item?.people.length === 2 && x.id !== currentUser.id
        );
      item.posts = Array.isArray(item?.posts) ? item.posts.sort((a : any, b : any) => {
        return (new Date(b?.createDate) as any) - (new Date(a?.createDate) as any);
      }).filter((x : any) => x?.status !== "Deleted") : [];

			let userconvoPost = userconvo?.filter(x => x?.id === item.id);
			let lastchat = item?.posts?.length > 0 ? item?.posts[0]?.text : "";
			let lastUpdated = item?.posts?.length > 0 ? item?.posts[0]?.lastUpdated : "";

			let profilename = item?.posts?.filter((x: any) => x?.person?.id == profile[0]?.id);
			let cacheItem: any;
			if (profilename[0]?.person?.id || profile[0]?.id) {
				cacheItem = entitydatalist?.find((x: any) => x?.id == profilename[0]?.person?.id || x?.id == profile[0]?.id);
			}

			profilename =
				profilename?.length > 0 &&
				profilename?.sort(function (a: any, b: any) {
					let c: any = new Date(b.lastUpdated);
					let d: any = new Date(a.lastUpdated);
					return c - d;
				});

			let followersinuser =
				currentUser?.follows?.length > 0 &&
				currentUser?.follows?.filter((x: any) => x?.id == profilename[0]?.person?.id);

			if (followersinuser?.length > 0) {
				profilename[0].person = followersinuser[0];
			}

			const calculateTimeDifference = item => {
				const lastUpdateDate: any = new Date(item);
				const currentDate: any = new Date();
				const diffInMs = currentDate - lastUpdateDate;

				const diffInSeconds = Math.floor(diffInMs / 1000);
				const diffInMinutes = Math.floor(diffInSeconds / 60);
				const diffInHours = Math.floor(diffInMinutes / 60);
				const diffInDays = Math.floor(diffInHours / 24);

				if (diffInSeconds < 60) {
                    return `${ diffInSeconds } ${ Loc.currentLang.convo.secondsAgo}`;
				} else if (diffInMinutes < 60) {
                    return `${ diffInMinutes } ${ Loc.currentLang.convo.minutesAgo}`;
				} else if (diffInHours < 24) {
                    return `${ diffInHours } ${ Loc.currentLang.convo.hoursAgo}`;
				} else {
                    return `${ diffInDays } ${ Loc.currentLang.convo.daysAgo}`;
				}
			};

			let uri = Link.getTummb(profilename && profilename[0]?.person ? profilename[0]?.person : profile && profile[0]);

			if (convo.item.id == isConvoScreenOpen.convoId) {
				updateUnreadMessages(convo.item);
			}

			const formatTextWithNonBreakingSpaces = text => {
				return text ? text.replace(/ /g, "\u00a0") : "";
			};


        return (
           item?.id && ( <Box key={ item.id + index }>
                <VStack justifyContent={ 'center' }>
                    <Pressable
                        _hover={ { backgroundColor: 'gray.100' } }
                        onPress={ () =>
                        {
                            setIsOpenConvo( true );
                            handleGetConvo( item );
                            setIsGroupChat( false );
                            setShowEmoji(false);
                            setShowConvoFilePicker(false)
                            setValue('');
                        } }
                    >
                        <HStack justifyContent={ 'space-between' } paddingTop='1'>
                            { (item?.subject?.trim()?.length < 1 || item?.subject?.trim()?.length == undefined) && (
                                <HStack padding={ 5 } alignSelf={ 'center' } paddingTop='2'>
                                    <ProfilePic
                                        width={ 50 }
                                        height={ 50 }
                        link={ cacheItem?.id ? AV.AVEntity?.getLink(cacheItem) :
                          profilename[ 0 ]?.person
                            ? profilename[ 0 ]?.person
                            : profile[ 0 ]
                        }
                                    />
                                    <VStack marginLeft={ 6 }>
                                        {/* <Tooltip label={ profilename[ 0 ]?.person?.title ? profilename[ 0 ]?.person?.title : profile[ 0 ]?.title } openDelay={ 500 } >*/ }
                                        <Pressable
                                            onPress={ () => {
                                                handleGetConvo( item );
                                                setIsGroupChat( false )
                                                setIsOpenConvo( true );
                                                setShowEmoji(false);

                                            } }
                                        >
                                            <Text
                                                fontWeight='bold'
                                                width={ '240px' }
                                                text-overflow={ 'ellipsis' }
                                                numberOfLines={ 1 }
                                            >
                                                {
                                                    profilename && profilename.length > 0 && profilename[ 0 ]?.person?.title
                                                        ? profilename[0]?.person?.title
                                                        : profile && profile.length > 0 && profile[0]?.title
                                                }
                                            </Text>
                                        </Pressable>
                                        {/* </Tooltip>*/ }
                                        <View>
                                            <div style={ { fontWeight: 'medium', width: '240px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px' } } dangerouslySetInnerHTML={ { __html: formatTextWithNonBreakingSpaces(lastchat) } } />
                                        </View>

                                    </VStack>
                                    <Text fontSize="12px" fontWeight={ 'bold' }>
                                        { calculateTimeDifference(userconvoPost[ 0 ]?.posts?.length > 0 ? userconvoPost[ 0 ]?.posts[ 0 ]?.lastUpdated : item?.lastUpdated) }
                                    </Text>
                                    { UnReadChatMessage?.filter((x : any) => x?.data?.feed?.id == item?.id)?.length > 0 &&
                                        <Badge
                                            colorScheme="info" right={ '20px' } size="small" rounded="full" variant="solid" alignSelf="flex-end" _text={ {
                                                fontSize: 9
                                            } }>
                                            { UnReadChatMessage?.filter((x : any) => x?.data?.feed?.id == item?.id)?.length }
                                        </Badge>
                                    }

                                </HStack>
                            ) }
                            { item?.subject?.trim()?.length >= 1 && (
                                <HStack alignSelf={ 'flex-start' } padding={ 9 } paddingTop='2' marginLeft={ item?.type === 'GroupConvo' ? '-15px'  : ''}>
                                    { item?.type !== 'GroupConvo' ?
                                        <Avatar.Group size='lg' width={ 45 } height={ 45 } max={ 1 } marginRight={ 5 }>
                                            { item?.people?.map( ( item, index ) => (
                                                <HStack key={ item.id } borderWidth={ 0 } borderColor="transparent">
                                                    <ProfilePic width={ 38 } height={ 38 } link={ item } />
                                                </HStack>
                                            ) ) }
                                        </Avatar.Group>
                                        : ( item?.owner?.type === 'Team' && item?.owner?.subType !== 'Term' || ( item?.owner?.type === 'PlayerOrg' && item?.owner?.subType !== 'Term' && item?.owner?.subType !== "MusicGroup" ))
                                            ? <TeamProfile height="55" width="55" color="#32AE78" />
                                            : ( item?.owner?.subType === 'Club' || item?.owner?.subType === 'Term' )
                                                ? <Club height="55" width="55" color="#32AE78" />
                                                : item?.owner?.type === "Club"
                                                    ? <EliteClub height="55" width="55" color="#32AE78" />
                                                    : item?.owner?.subType == "MusicGroup" ?
                                                        <PerformanceGroup height="55" width="55" color="#32AE78" />
                                                        :
                                                        item?.owner?.type === "Person"
                                                            ? < FriendsIcon height="55" width="55" color="#32AE78" />
                                                            :
                                                    item?.owner?.type === 'Event' ? 
                                                        < Event height="55" width="55" color="#32AE78" />:
                                                    item?.owner?.type === 'School'
                                                        ? <School height="55" width="55" color="#32AE78" />
                                                        : item?.owner?.type === 'Group'
                                                            ? <Group height="55" width="55" color="#32AE78" />
                                                            : item?.owner?.subType === 'Class'
                                                                ? <Class height="55" width="55" color="#32AE78" />
                                                                : item?.owner?.subType === 'GradClass'
                                                                    ? <GradClass height="55" width="55" color="#32AE78" />
                                                                    : item?.owner?.type === 'Neighborhood'
                                                                        ? <Neighbor height="55" width="55" color="#32AE78" />
                                                                        : item?.owner?.type === 'Organization'
                                                                            ? <Organization height="55" width="55" color="#32AE78" />
                                                                            : item?.owner?.type === 'Community'
                                                                                ? <Community height="55" width="55" color="#32AE78" />
                                                                                : null }

                                    <VStack marginLeft={item?.type !== 'GroupConvo' ? 0 : 6 }>
                                        {/*<Tooltip label={ item?.subject ? item?.subject : profile[ 0 ]?.title } openDelay={ 500 }>*/ }
                                        <Pressable
                                            onPress={ () => {
                                                handleGetConvo( item );
                                                setIsGroupChat( false );
                                                setIsOpenConvo( true );
                                                setShowEmoji(false);

                                            } }
                                        >
                                            <Text
                                                fontWeight='bold'
                                                width={ '240px' }
                                                text-overflow={ 'ellipsis' }
                                                numberOfLines={ 1 }
                                            >
                                                { item?.subject ? item?.subject : profile[ 0 ]?.title }
                                            </Text>
                                        </Pressable>
                                        {/*</Tooltip>*/ }
                                        <View>
                                            <div style={ { fontWeight: 'medium', width: '240px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px' } } dangerouslySetInnerHTML={ { __html: formatTextWithNonBreakingSpaces(lastchat) } } />
                                        </View>

                                    </VStack>
                                    <Text fontSize="12px" fontWeight={ 'bold' }>
                                        { calculateTimeDifference( userconvoPost[ 0 ]?.posts?.length > 0 ? userconvoPost[ 0 ]?.posts[ 0 ]?.lastUpdated : item?.lastUpdated ) }
                                    </Text>
                                    { UnReadChatMessage?.filter((x : any) => x?.data?.feed?.id == item?.id)?.length > 0 &&
                                        <Badge
                                            colorScheme="info" right={ '20px' } size="small" rounded="full" variant="solid" alignSelf="flex-end" _text={ {
                                                fontSize: 9
                                            } }>
                                            { UnReadChatMessage?.filter((x : any) => x?.data?.feed?.id == item?.id)?.length }
                                        </Badge> }
                                </HStack>
                            ) }
                        </HStack>
                    </Pressable>
                </VStack>
                { item?.id && <Divider /> }
            </Box>)
        );
    },
        [ userconvo, convoListData, userconvolistdata, UnReadChatMessage ]
    );

    const handleCancelAlert = async() => {

        setShowAlertModal(false);
        setShowFollowingModal(false);
        setShowConvoListModal(false);
        setMdlConvoVisible(false);
        setMdlCreateConvoVisible(false);
        setConvoLoading( false );
        setIsOpenConvo(false)
        setconvoListData( userconvo );
       // CloseSSE();
        await SignalRService.stopSignalRConnection();
        let setConvoScreenOpen = { isOpen: false, convoId: undefined }
        dispatch( setIsConvoScreenOpen( setConvoScreenOpen ) );
    };

	const handleSearchConvo = async val => {
		if (val?.trim()?.length > 0) {
			setconvosearch(true);
			setconvoListData(
				userconvo.filter(
					(x: any) =>
						(x?.subject !== undefined && x?.subject?.toLocaleLowerCase().includes(val.toLocaleLowerCase())) ||
						(x?.people !== undefined &&
							x.people.some(
								(person: any) =>
									person?.title !== undefined && person.title?.toLocaleLowerCase().includes(val.toLocaleLowerCase()),
							)),
				),
			);
		} else {
			setconvosearch(false);
		}
	};
	async function updateUnreadMessages(item) {
		let id = item?.id;
		const UnReadChatMessage = useralertList?.filter(
			(x: any) =>
				x?.actionTaken == undefined &&
				x?.data?.feed?.id === id &&
				(x?.alertType === "NewDM" || x?.alertType === "ReplyDM"),
		);

		if (UnReadChatMessage?.length > 0) {
			for (const message of useralertList) {
				if (
					message?.data?.feed?.id === id &&
					message?.actionTaken !== "Viewed" &&
					(message?.alertType === "NewDM" || message?.alertType === "ReplyDM")
				) {
					message.actionTaken = "Viewed";
				}
			}
			dispatch(setAlerts(useralertList));
			for (let i = 0; i < UnReadChatMessage.length; i++) {
				//await Alert.Save(UnReadChatMessage[i]);
			}
		}
	}

    React.useEffect( () =>
    {
        setconvoListData( userconvo )
    }, [ userconvo ]);

	return (
		<Box
			position="fixed"
			top={[`${83 - 1}px`]}
			right={[0]}
			w={isMobile ? "100%" : "460px"}
			h={"calc(100vh - 50px)"}
			bgColor={["AVColor.white"]}
			shadow={[10]}
			borderColor={"AVColor.grey"}
			borderWidth={1}
			zIndex={isHeader ? -3 : 9999}
			//borderRadius={8}
		>
			<VStack px={3} h={"100%"} position={"relative"} py={23}>
				<HStack justifyContent={"space-between"}>
					<Text fontSize={24} fontWeight={"bold"}>
						{Loc.currentLang.menu.messenger}
					</Text>
					<HStack position={"absolute"} right={"60px"} zIndex={2}>
                        <Button bgColor={ "none" } padding={ 1 } onPress={ () =>
                        {
                            setIsOpenConvo( false );
                            setIsConvoData( '' );
                            setIsNewChat( true );
                            setIsGroupChat( true );
                            handleCreateModel();
                        }
                            }>
							<CreateMessageIcon size={"32px"} />
						</Button>
					</HStack>
					<HStack alignItems={["center"]} space={"26px"}>
						<AVCancel callback={handleCancelAlert} color={""} size={undefined} />
					</HStack>
				</HStack>

                <VStack h={ '100%' } flex={ 1 } top={ 4 }>
                    <Input
                        borderWidth={ 0 }
                        placeholder={ Loc.currentLang.menu.searchMessenger }
                        variant='AVSearchInput'
                        backgroundColor={ '#F5F5F5' }
                        marginTop={ '1px' }
                        borderRadius={ '22px' }
                        fontSize={ '16px' }
                        width={ [ '100%' ] }
                        InputLeftElement={
                            <SearchIcon ml='2' size='24px' color='#000' left={ '10px' } p={ '7px' } />
                        }
                        onChangeText={ (e) => {
                            handleSearchConvo(e);
                        } }
                    />
                    { convosearch ? (
                        convoListData?.length > 0 ? (
                            <ScrollView maxHeight={ dimensions.height - 200 } showsVerticalScrollIndicator={ false }>
                                <HStack flexWrap="wrap" justifyContent="center">
                                    <FlatList
                                        removeClippedSubviews={ false }
                                        disableVirtualization={ true }
                                        data={ convoListData}
                                        renderItem={ Renderconvo }
                                        windowSize={ 3 }
                                        onEndReachedThreshold={ 0.1 }
                                        keyExtractor={ (item : any, index) => item?.id + index }
                                    />
                                </HStack>
                            </ScrollView>
                        ) : (
                            <HStack height={ '300px' } alignItems="center" justifyContent="center" width={ '100%' }>
                                <Text>{ Loc.currentLang.menu.noRecordsFound }</Text>
                            </HStack>
                        )
                    ) : (
                sortedConvoList?.length > 0 ? (
                            <ScrollView maxHeight={ dimensions.height - 200 } showsVerticalScrollIndicator={ false }>
                                <HStack flexWrap="wrap" justifyContent="center">
                                    <FlatList
                                        removeClippedSubviews={ false }
                                        disableVirtualization={ true }
                        data={ sortedConvoList }
                                        renderItem={ Renderconvo }
                                        windowSize={ 3 }
                                        onEndReachedThreshold={ 0.1 }
                                        keyExtractor={ (item : any, index) => item?.id + index }
                                    />
                                </HStack>
                            </ScrollView>
                        ) : (
                            <HStack height={ '300px' } alignItems="center" justifyContent="center" width={ '100%' }>
                                <Text>{ Loc.currentLang.menu.noRecordsFound }</Text>
                            </HStack>
                        )
                    ) }

                </VStack>
            </VStack>
        </Box>
    );
};

export default CreateNewMessage;
