/*!
 * Copyright © 2023 Avtavivo
 */

export class Weather {
	public static GetURL(lat: number, lng: number): string {
		if (!lat || !lng) return "";
		return "https://mobile.weather.gov/index.php?lat=" + lat.toString() + "&lon=" + lng.toString();
	}
	public static async getWeather(lat: number, lng: number): Promise<Forecast | null> {
		if (!lat || !lng) return null;
		var context: {
			properties: { gridId: string; gridX: string; gridY: string };
		} = await fetch("https://api.weather.gov/points/" + lat + "," + lng)
			.then(response => response.json())
			.then(data => {
				console.log("data", data);
				return data;
			})
			.catch(err => {
				console.log("Error Reading data " + err);
			});

		var weather = await fetch(
			"https://api.weather.gov/gridpoints/" +
				context.properties.gridId +
				"/" +
				context.properties.gridX +
				"," +
				context.properties.gridY +
				"/forecast",
		)
			.then(response => response.json())
			.then(data => {
				console.log("data", data);
				return data;
			})
			.catch(err => {
				console.log("Error Reading data " + err);
			});

		return new Forecast(weather);
	}
}
export class Forecast implements IForecast {
	public updateDate: Date;
	public validDate: Date;
	public units: string;
	public periods: IForecastPeriod[];
	constructor(context: any) {
		this.updateDate = context?.properties?.updateTime;
		this.validDate = context?.properties?.validTimes;
		this.units = context?.properties?.units;
		this.periods = context?.properties?.periods;
	}
}

export interface IForecast {
	updateDate: Date;
	validDate: Date;
	units: string;
	periods: IForecastPeriod[];
}
export interface IForecastPeriod {
	number: number;
	name: string;
	startTime: Date;
	endTime: Date;
	isDaytime: boolean;
	temperature: number;
	temperatureUnit: string;
	temperatureTrend: string;
	windSpeed: string;
	windDirection: string;
	icon: string;
	shortForecast: string;
	detailedForecast: string;
}
