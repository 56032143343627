import { SET_ALERT_STATE, SET_AlERTS_DATA } from "../Constants";
import { SET_NEW_AlERTS_DATA } from "../Constants";

export const setAlerts = data => ({
	type: SET_AlERTS_DATA,
	payload: data,
});
export const setNewAlerts = data => ({
	type: SET_NEW_AlERTS_DATA,
	payload: data,
});
export const setAlertState = () => ({
	type: SET_ALERT_STATE,
});
