export const msalConfig = {
    auth: {
        clientId: "54ddd835-f775-4232-b292-183f36d472af",  // Fetching Client ID from the .env file
        authority: "https://login.microsoftonline.com/consumers",  // The Microsoft login endpoint
        redirectUri: window.location.origin,   // URL to redirect after login (this should match the one in Azure)
    }
};

//export const msalParams = {
//    scopes: ["Files.ReadWrite"],  // Scopes to access OneDrive
//};

export const msalParams = {
    auth: {
        clientId: "54ddd835-f775-4232-b292-183f36d472af", // Replace with your actual client ID
        authority: "https://login.microsoftonline.com/consumers", // Common authority for consumer accounts
        redirectUri: window.location.origin, // Update to match your app's redirect URI
    },
    cache: {
        cacheLocation: "localStorage", // Can also use "sessionStorage"
        storeAuthStateInCookie: true, // Recommended for IE11 or Edge
    },
};