import * as React from "react";

const Favorites = ({ color }) => (
	<>
		<path
			d="M21.8479807,9.15135754 C21.7161007,8.75154948 21.3715675,8.47059745 20.9689446,8.43454135 L15.1529962,7.54654519 L12.5465518,2.01529195 C12.3757669,1.64619545 12.0184377,1.41176471 11.6266302,1.41176471 C11.2348228,1.41176471 10.8774936,1.64619545 10.7067087,2.01529195 L8.10026434,7.53584644 L2.28431592,8.43454135 C1.89838116,8.49195816 1.577248,8.77414305 1.45638653,9.16205629 C1.34587779,9.54069974 1.44417838,9.95226704 1.71192029,10.2319312 L5.93333804,14.5114308 L4.91120299,20.5883202 C4.82690853,21.0000968 4.98123823,21.4253304 5.30547017,21.6746675 C5.62970211,21.9240046 6.06351755,21.9510596 6.41374151,21.7437851 L11.6266302,18.8872191 L16.839519,21.7437851 C16.9829733,21.8284941 17.1449942,21.8727345 17.3097011,21.8721701 C17.5262164,21.8729772 17.7373837,21.801798 17.9127608,21.6688939 C18.2365554,21.4261105 18.3961213,21.008564 18.3216148,20.599019 L17.2994798,14.5221295 L21.5208975,10.2426299 C21.8221197,9.97551412 21.9494456,9.55070706 21.8479807,9.15135754 Z M15.5618502,13.4308571 C15.3217226,13.6749523 15.2109952,14.026646 15.265431,14.3723471 L16.0013683,18.8658216 L12.1581405,16.7260718 C11.855381,16.569307 11.500093,16.569307 11.1973335,16.7260718 L7.35410575,18.8658216 L8.09004299,14.3723471 C8.14447885,14.026646 8.03375144,13.6749523 7.79362382,13.4308571 L4.72721868,10.2212324 L9.03040723,9.56860875 C9.36663545,9.51965621 9.65770755,9.29917356 9.80722987,8.98017755 L11.6266302,4.90395418 L13.5482441,8.9908763 C13.6977665,9.30987231 13.9888386,9.53035495 14.3250668,9.5793075 L18.6282553,10.2319312 L15.5618502,13.4308571 Z"
			id="Shape"
			fillOpacity="0.85"
			fill={color ? color : "#000000"}
			fillRule="nonzero"
		></path>
	</>
);

export default Favorites;
