/*!
 * Copyright © 2023 Actavivo
 */
import * as AV from "./Interfaces/AV.Interfaces";
import { Server } from "../Platform/Server";
import { MediaStore } from "../App/MediaStore";
export class Media {
	static _api = "/api/Media";
	public static getVideoPicURL(media: AV.IMedia | undefined | null, defaultImg = "") {
		if (media) return MediaStore.getStoreURL(media.storeId) + "vpic/" + media.id;
		return defaultImg;
	}
	public static getURL(media: AV.IMedia | undefined | null, defaultImg = "") {
		if (media) return MediaStore.getStoreURL(media.storeId) + "base/" + media.id;
		return defaultImg;
	}
	public static getThumb(link: AV.ILink, defaultImg = "") {
		if (link) return MediaStore.getStoreURL(link?.storeId) + "thumb/" + link.thumb;

		return defaultImg;
	}
	public static getMap(link: AV.ILocationLink | undefined | null, defaultImg = "") {
		if (link) return MediaStore.getStoreURL(link.storeId) + "map/" + link.thumb;
		return defaultImg;
	}
	public static getBackground(link: AV.ILink | undefined | null, defaultImg = "") {
		if (link) {
			return MediaStore.getStoreURL(link.storeId) + "back/" + link.background;
		}
		return defaultImg;
	}
	public static getFormData(val?: FileList | null): FormData {
		var body = new FormData();
		if (!val) return body;

		for (var i = 0; i < val.length; i++) {
			body.append(val[i].name, val[i]);
		}
		return body;
	}
	public static async saveFiles(fData: FormData, parentRegion: string = "USD"): Promise<AV.IMedia[]> {
		var retval: AV.IMedia[] = await Server.post<AV.IMedia[]>(Media._api + "?region=" + parentRegion, fData, true);
		return retval;
	}
	public static async processFiles(media: AV.IMedia[]): Promise<AV.IMedia[]> {
		if (!media) return [];
		return await Server.post<AV.IMedia[]>(Media._api + "/Process", media);
	}
	public static async createList(list: AV.IMediaList): Promise<AV.IMediaList> {
		return await Server.post<AV.IMediaList>(Media._api + "/CreateList", list);
	}
	public static async saveList(list: AV.IMediaList): Promise<AV.IMediaList> {
		return await Server.post<AV.IMediaList>(Media._api + "/SaveList", list);
	}
	public static async deleteList(list: AV.IMediaList): Promise<AV.IAVEntity> {
		//Returns updated parent
		return await Server.post<AV.IAVEntity>(Media._api + "/DeleteList", list);
	}
	public static async getList(list: AV.IFK): Promise<AV.IMediaList> {
		return await Server.post<AV.IMediaList>(Media._api + "/GetList", list);
	}
	public static async addToList(item: AV.IMediaParam): Promise<AV.IMediaList> {
		return await Server.post<AV.IMediaList>(Media._api + "/AddToList", item);
	}
	public static async addManyToList(item: AV.IMediaListParam): Promise<AV.IMediaList> {
		return await Server.post<AV.IMediaList>(Media._api + "/AddManyToList", item);
	}
	public static async removeFromList(item: AV.IMediaParam): Promise<AV.IMediaList> {
		return await Server.post<AV.IMediaList>(Media._api + "/RemoveFromList", item);
	}
	public static async getDoc(item: AV.IMedia): Promise<Blob> {
		return await Server.post<Blob>(Media._api + "/GetDoc", item);
	}
	public static async getFile(mlId: string, mid: string, storeId: string): Promise<Blob> {
		return await Server.get<Blob>(Media._api + "/GetFile/" + mlId + "/" + mid + "/" + storeId);
	}
}
