import * as React from "react";

const Startruck = ({ color }) => (
	<>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.687 11.957c0 5.047-4.103 9.145-9.157 9.145-5.053 0-9.157-4.098-9.157-9.145a.905.905 0 0 0-1.811 0c0 6.046 4.914 10.953 10.968 10.953s10.968-4.907 10.968-10.953a.905.905 0 0 0-1.81 0ZM7.686 4.395a9.185 9.185 0 0 1 4.844-1.364 9.226 9.226 0 0 1 4.844 1.364.914.914 0 0 0 1.243-.275.87.87 0 0 0-.282-1.214A11.053 11.053 0 0 0 12.53 1.27a11.053 11.053 0 0 0-5.805 1.635.87.87 0 0 0-.282 1.214.914.914 0 0 0 1.243.275Z"
			fill={color ? color : "#E23131"}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.786 13.434a.89.89 0 0 0-.794.273c-.123.126-.314.39-.275.836.024.283.218.938.668 1.7.93 1.57 2.946 3.703 6.293 3.703 3.348 0 5.363-2.132 6.293-3.704.45-.76.644-1.416.669-1.699.038-.444-.151-.707-.273-.833a.887.887 0 0 0-.796-.276 43.614 43.614 0 0 1-11.785 0Zm1.238 1.995c.73 1.17 2.22 2.682 4.654 2.682 2.434 0 3.923-1.511 4.654-2.682-3.097.32-6.207.32-9.308 0ZM5.287 5.339l.968-1.915a1.22 1.22 0 0 1 1.087-.672c.459 0 .879.26 1.088.673l.968 1.914 2.103.338c.453.072.828.395.97.836.141.44.026.923-.298 1.25l-1.505 1.521.332 2.124c.072.457-.117.916-.488 1.188a1.212 1.212 0 0 1-1.271.102l-1.899-.975-1.898.975c-.409.21-.9.17-1.271-.102a1.237 1.237 0 0 1-.488-1.188l.331-2.124-1.504-1.52a1.24 1.24 0 0 1-.298-1.251c.142-.44.517-.764.97-.836l2.103-.338Zm2.055-.088-.628 1.243a1.07 1.07 0 0 1-.779.571l-1.366.22.978.987a1.08 1.08 0 0 1 .297.924l-.216 1.379 1.233-.633a1.051 1.051 0 0 1 .962 0l1.233.633-.215-1.379a1.08 1.08 0 0 1 .297-.924l.978-.988-1.366-.219a1.053 1.053 0 0 1-.779-.57L7.342 5.25Z"
			fill={color ? color : "#E23131"}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m15.663 5.339.968-1.915a1.22 1.22 0 0 1 1.087-.672c.458 0 .878.26 1.087.673l.968 1.914 2.103.338c.454.072.828.395.97.836.141.44.027.923-.297 1.25l-1.505 1.521.331 2.124c.072.457-.117.916-.488 1.188a1.212 1.212 0 0 1-1.27.102l-1.9-.975-1.897.975c-.41.21-.9.17-1.272-.102a1.237 1.237 0 0 1-.488-1.188l.332-2.124-1.505-1.52a1.24 1.24 0 0 1-.297-1.251c.142-.44.516-.764.97-.836l2.103-.338Zm2.055-.088-.629 1.243a1.07 1.07 0 0 1-.778.571l-1.367.22.978.987a1.08 1.08 0 0 1 .297.924l-.215 1.379 1.233-.633a1.05 1.05 0 0 1 .962 0l1.233.633-.216-1.379a1.08 1.08 0 0 1 .298-.924l.977-.988-1.366-.219a1.052 1.052 0 0 1-.779-.57l-.628-1.244Z"
			fill={color ? color : "#E23131"}
		/>
	</>
);

export default Startruck;
