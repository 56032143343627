//@ts-nocheck
import * as React from 'react';
import * as AV from '../AVCore/AVCore';
import { useQuery } from 'react-query';
import { Alert } from '../AVCore/Alert';
import { AVEntity } from '../AVCore/AVEntity';
import { Store } from 'Platform/Store';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, setFollowRequestData, setFeedsLoad } from '../Redux/Actions/userActions';
import { setAlerts } from '../Redux/Actions/alertActions';
import { useLocation } from 'react-router-dom';
import { setFeeds, setLastUpdatedPostDate } from '../Redux/Actions/feedsAction';
import _ from 'lodash';
import { setConvo } from '../Redux/Actions/convoActions';


const useAlerts = () => {
    const location = useLocation();
    const CurrentUser = useSelector((state) => state?.user?.user[ 0 ]);
    const [ showAlertModal, setShowAlertModal ] = React.useState( false );
    const followersdatalist = useSelector( ( state : RootStateOrAny ) => state?.user?.followersdatalist );
    const [ showFollowingModal, setShowFollowingModal ] = React.useState(false);
    const [ filter, setFilter ] = React.useState('');
    const [ unreadAlertData, setUnreadAlertData ] = React.useState();
    const [allAlertData, setAllAlertData] = React.useState();
    const [ requestdata, setrequestdata ] = React.useState<any>( followersdatalist );
    const [blockedUser, setIsBlockedUser] = React.useState(CurrentUser?.blocked);
    const [ viewPost, setViewPost ] = React.useState(false);
    const userFeeds = useSelector((state) => state?.feeds.feeds[0]);
    const [isMenu, setIsMenu] = React.useState(false);  
  const [ isAcceptAll, setisAcceptAll ] = React.useState(false);
    const [ isRejectAll, setisRejectAll ] = React.useState( false );
    const [ disable, setisdisable ] = React.useState( false );
    const followRequestData : any = useSelector( ( state : RootStateOrAny ) => state?.user?.followRequestData );
    const convoval = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const followerslist = useSelector( ( state : RootStateOrAny ) => state?.user?.followersdatalist );
  const lastUpdatedPostDate = useSelector(state => state?.feeds?.lastUpdatedPostDate);
    const [ Loader, setLoader ] = React.useState<any>();

    const [ postData, setPostData ] = React.useState<AV.IFeed>();
    const useralertList = useSelector((state) => state?.alerts.alert[ 0 ]);
    const dispatch = useDispatch();

    const getAlertFK = async () => {
        let alertList = useralertList;
        alertList = alertList?.filter(
            (x) =>
                (x?.target?.title !== undefined &&
                    x?.target?.title?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) ||
                (x?.data?.source?.title !== undefined &&
                    x?.data?.source?.title?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
        );
        let val = alertList?.filter((x) => x.actionTaken === undefined);
        setUnreadAlertData(val?.sort(function (a, b) {
            let c : any = new Date(b.date);
            let d : any = new Date(a.date);
            return c - d;
        }));
        setAllAlertData(alertList);
        return alertList;
    };
    const { data } = useQuery(
        [ 'allalertlist', filter ],
        getAlertFK,
        {
            keepPreviousData: true,
        },
        {
            refetchInterval: 1000,
        }
    );
    const handleSaveAlert = async (alert, type) => {
        //@ts-ignore
        alert.actionTaken = type == 'Accepted' || 'Rejected' ? 'Viewed' : type == "deleted" ? "Viewed" : type;
        alert = await Alert.Save( alert );
        if ( type !== "deleted")
        dispatch(setAlerts(alert?.alerts));
        return alert;
    };
    const handleDeleteAll = async () => {
        //@ts-ignore
        alert = await Alert.updateAll('Viewed');
        dispatch(setAlerts(alert?.alerts));
    };

    const handlegetPost = async (item) => {
        let excitingfeeds = userFeeds?.filter(x => x?.owner.id === item?.id);
        let param: AV.IFeedParam = { owner: AV.AVEntity.getFk(item), startDate: new Date(excitingfeeds?.length > 0 ? excitingfeeds[0]?.lastUpdated : 0) };
        if (param?.owner?.id) {
            let responsepost = await AV.Feed.getFeed(param);
            if (responsepost) {
                let feeds = userFeeds.concat(responsepost.posts.filter((x) => x.status !== 'Deleted'));
                feeds = _.uniqBy(feeds, 'id');
                dispatch(setFeeds(feeds));

            }
        }
    }

    const HandleFollowRequestData = ( item,date ) =>
    {
        const RequestData = CurrentUser?.follows?.find( ( x ) => x?.id === item?.id && x?.type == "Person" );
        if (!RequestData)
        {
            item.date = date;
            followRequestData?.push( item )
            dispatch( setFollowRequestData( followRequestData ))
        }

    }

    const handleAlert = async ( alert, type ) =>
    {
        setisdisable(true)
        location.state = { alert, type };
        setLoader(location.state);
      //@ts-ignore
      if (type === "Accepted" && alert?.type == 'Follow') {
          await HandleFollowRequestData( alert.source, alert?.date)
      }
        if (alert?.type && type != "block" && type != "followback" && type != "unFollowMe" && type != "unBlock") {
            alert.source.linkType = alert?.type;

            (await type) === 'Accepted'
                ? await AVEntity.approveRequest(alert.target, alert.source)
                : await AVEntity.denyRequest(alert.target, alert.source);
        }
        else if (alert?.data?.type && type != "block" && type != "followback" && type != "unFollowMe" && type != "unBlock") {
            alert.target.linkType = alert?.data?.type;
            if (alert?.alertType === "NewRequestToStaff") {
                await type === "Accepted" ? await AVEntity.approveRequest( alert?.data?.target, alert?.target ) : await AVEntity.denyRequest( alert?.data?.target, alert?.target );
                if (type === 'Accepted')
                    await handlegetPost(alert?.target);
            }
            else {
                await type === "Accepted" ? await AVEntity.approveRequest(alert.source, alert.target) : await AVEntity.denyRequest(alert.source, alert.target);
            }

        }
        else if (type === "block") {
            let val = await AV.Person.block(AV.AVEntity.getFk(CurrentUser), alert);
            let userFeed = userFeeds?.filter(x => x?.owner?.id !== alert?.id);
            dispatch(setFeeds(userFeed));
            await AV.Person.getSelf().then((x) => {
                dispatch(setUser(x));
            });
            setIsMenu(false);
        }
        else if (type === "followback") {
            alert.linkType = 'Follow';
            let val = await AV.Person.makeRequest(AV.AVEntity.getFk(CurrentUser), alert);
            if (val) {
                dispatch(setUser(val));
            }
            if (followRequestData.filter(X => X?.id == alert.id )?.length > 0)
            {
                setrequestdata( requestdata.filter( ( f : any ) => f?.id != alert.id ) )
                dispatch( setFollowRequestData( followRequestData.filter( ( f : any ) => f?.id != alert.id ) ) )
               
            }
            setIsMenu(false);
        }
        else if (type === "unBlock") {
            let val = await AV.Person.unBlock(AV.AVEntity.getFk(CurrentUser), alert);
            await AV.Person.getSelf().then((x) => {
                dispatch(setUser(x));
            });
            setIsMenu(false);
        }
        else if (type === "unFollowMe") {
            let val = await AV.Person.unFollowMe(AV.AVEntity.getFk(CurrentUser), alert);
            let userFeed = userFeeds?.filter(x => x?.owner?.id !== alert?.id);
            setrequestdata(requestdata?.filter(a => a.id !== alert.id))
            dispatch(setFeeds(userFeed));
            setIsMenu(false);
        };
        let newalert = await handleSaveAlert( alert, type );
        let user = await AV.Person.getSelf();
        //if (user)
        dispatch(setUser(user));
        setLoader();
        location.state = "'";
        setisdisable( false )
       
    };

    const Feedsupdate = ( feeds, feedsVal, newFeedsData ) =>
    {
        feeds?.sort( function ( a, b )
        {
            let c : any = new Date( b.createDate );
            let d : any = new Date( a.createDate );
            return c - d;
        } );
        for ( let i = 0; i < feeds?.length; i++ )
        {
            let existingVal = feedsVal?.filter( ( x ) => x?.id === feeds[ i ]?.id );
            if ( existingVal?.length === 0 ) feedsVal.unshift( feeds[ i ] );
            else
            {
                existingVal[ 0 ].impressions = feeds[ i ]?.impressions;
                existingVal[ 0 ].text = feeds[ i ]?.text;
            }
        }
        feedsVal = _.uniqBy( feedsVal, 'id' );
        let usersFeeds = list_to_tree( feedsVal );
        usersFeeds = _.uniqBy( usersFeeds, 'id' );
      dispatch(setFeeds(usersFeeds));

      if (newFeedsData) {
        setTimeout(() => {
          dispatch(setFeedsLoad(false));
        }, 3000); 
      } else {
        dispatch(setFeedsLoad(false));
      }
  };

    const Convoupdate = ( response, usercon ) =>
    {
        const existingconvo = usercon.find( ( x : any ) => x?.id === response?.id );
        if ( !existingconvo )
        {
            let user : any = AV.Person._self;
            if ( usercon?.length == 0 && ( user?.convos == undefined || user?.convos?.length == 0 ) )
                usercon.unshift( response );
            else
                return;
        } else
        {
            let updatedFeeds = [ ...( existingconvo?.posts || [] ) ];
            response?.posts?.forEach( ( newconvo : any ) =>
            {
                let existingFeed = existingconvo?.posts?.find( ( x : any ) => x?.id === newconvo?.id );
                if ( !existingFeed )
                {
                    updatedFeeds.unshift( newconvo );
                } else
                {
                    existingFeed.impressions = newconvo.impressions;
                    existingFeed.text = newconvo.text;
                }
            } );
            existingconvo.posts = _.uniqBy( [ ...updatedFeeds ], 'id' );
            existingconvo.lastUpdated = response.lastUpdated;
            existingconvo.people = response.people;
            usercon.unshift( existingconvo );
            usercon = _.uniqBy( usercon, 'id' );
        }
        console.log('5')
      dispatch(setConvo(usercon));

    };

    const handlerefetch = async () =>
    {
        // get ALerts List
        let lastAlertDate = Alert._startDate == undefined ? new Date( 0 ) : Alert._startDate;
        let alertList = await Alert.List( lastAlertDate );
        dispatch( setAlerts( alertList?.alerts ) );
        // get update feeds list
        let feeds = alertList.feeds;
        let followesrIDS = [
            ...( followerslist || [] ),
            ...( CurrentUser?.follows || [] ),
            ...( CurrentUser?.autoFollows || [] ),
            ...( CurrentUser ? [ CurrentUser ] : [] )
        ];

      let newFeedsData = false;
        for ( let i = 0; i < feeds?.length; i++ )
        {
            let personList : any = followesrIDS?.find( ( x : any ) => x?.id == feeds[ i ]?.id )
            let personConvoList = CurrentUser?.convos?.find( ( x : any ) => x?.id == feeds[ i ]?.id )

            if ( personList?.id || personConvoList?.id )
            {
                var existingPost = userFeeds?.filter( ( x : any ) => x?.pk === personList?.id );
                var existingConvo = convoval?.filter( ( x : any ) => x?.id === personConvoList?.id );
                var lastDate = personList?.id ? existingPost[ 0 ]?.lastUpdated ? existingPost[ 0 ]?.lastUpdated : 0 : existingConvo[ 0 ]?.lastUpdated ? existingConvo[ 0 ]?.lastUpdated : 0
                var id = personList?.id ? personList?.id : personConvoList?.id;
                let param : AV.IFeedParam = {
                    feed: { id: id, pk: id, type: personList?.id ? "Feed" : "Convo" },
                    startDate: lastDate,
                    forward: true
                };
                if ( param?.feed?.id )
                {
                    if ( personList?.id ? new Date( existingPost[ 0 ]?.lastUpdated ) < new Date( feeds[ i ].lastUpdate ) : new Date( existingConvo[ 0 ]?.lastUpdated ) < new Date( feeds[ i ].lastUpdate ) )
                    {

                        let response : any = await AV.Feed.getFeed( param );
                        if ( response?.id )
                        {

                            let PostList = await AV.Feed.getPostList( response );
                            if ( PostList?.length > 0 )
                            {
                              let Post : any = await AV.Feed.getPosts(AV.AVEntity.getFk(response), PostList.slice(0, 10) );
                                let cmdPost : any = []
                                for ( let i = 0; i < Post?.length; i++ )
                                {
                                    if ( Post[ i ].posts?.length > 0 )
                                    {
                                        var cmdPost1 : any = await AV.Feed.getPosts( AV.AVEntity.getFk( Post[ i ] ), Post[ i ]?.posts?.slice( 0, 2 ) );
                                        cmdPost.push( cmdPost1 );
                                    }
                                }
                                for ( let i = 0; i < cmdPost[ 0 ]?.length; i++ )
                                {
                                    Post.push( cmdPost[ 0 ][ i ] );
                                }
                              if (personList?.id) {
                                if (new Date(lastUpdatedPostDate) < new Date(Post[ Post.length-1 ]?.lastUpdated)) {
                                  newFeedsData = true;
                                }
                                if (newFeedsData) {
                                  dispatch(setFeedsLoad(true));
                                }
                                Feedsupdate(Post, userFeeds, newFeedsData);
                                // Update the lastUpdatedPostDate state
                                dispatch(setLastUpdatedPostDate(Post[ 0 ]?.lastUpdated));
                              }
                                else
                                    if ( Post?.length > 0 )
                                    {
                                        response.posts = Post;
                                    }
                                Convoupdate( response, convoval );
                            }
                        }
                    }
                }
            }
        }
    };

    const handleSearchAlert = async (item) => {
        setFilter(item);
    };
    const handleViewPost = async (alert, item) => {
        /*let person: AV.IPerson = await AV.Person.getSelf();*/
        let person = AV.Person.getSelf();
        let param: AV.IFeedParam = { owner: AV.AVEntity.getFk(person), startDate: new Date(0) };
        if (param?.owner?.id) {
            let response = await AV.Feed.getFeed(param);
            response = response?.posts?.filter((x) => x?.id === item?.id);
            response = list_to_tree(response);
            if (response) {
                setPostData(response[0]);
                setViewPost(!viewPost);
                if (alert.actionTaken !== 'Viewed') await handleSaveAlert(alert, 'Viewed');
            }
        }
    };
    const handlePost = async () => {
        setViewPost(true);
    }
    const list_to_tree = (list) => {
        var map = {},
            node,
            roots = [],
            i;

        for (i = 0; i < list.length; i += 1) {
            map[ list[ i ].id ] = i; // initialize the map
            list[ i ][ 'children' ] = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[ i ];
            if (node.parentPost !== undefined) {
                // if you have dangling branches check that map[node.parentId] exists
                list[ map[ node.parentPost ] ]?.children.push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    };
    let unreadAlertData1 = data?.filter(x => x.actionTaken === undefined);
    let allAlertData1 = data?.filter(x => x.actionTaken === "Viewed");
    /*let requestdata = data?.filter((x) => x.actionTaken === undefined && x.data?.type === 'Follow');*/
    let managerequestdata = data?.filter((x) => x.data?.type === 'Follow' && x.status !== 'Deleted' && x?.actionTaken === "Accepted");

    return {
      setisAcceptAll, isAcceptAll, isRejectAll, setisRejectAll,
        showAlertModal,
        setShowAlertModal,
        handleSaveAlert,
        handleAlert,
        unreadAlertData,
        showFollowingModal,
        setShowFollowingModal,
        allAlertData,
        handleSearchAlert,
        handleViewPost,
        setViewPost,
        handlePost,
        viewPost,
        managerequestdata,
        postData,
        handleDeleteAll,
        Loader, setLoader,
        requestdata,
        setrequestdata,
        blockedUser, setIsBlockedUser,
        isMenu, setIsMenu,
        disable,
        handlerefetch
    };
};
export default useAlerts;
