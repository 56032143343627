/*!
 * Copyright © 2023 Actavivo
 */
import * as AV from "./Interfaces/AV.Interfaces";
import { Server } from "../Platform/Server";
import { OrgBase } from "./OrgBase";

export class Club extends OrgBase {
	static _clubApi = "/api/Club";

	public static async addRegistration(source: AV.IFK, target: AV.ILink[]): Promise<AV.ILeagueTeam> {
		return await Server.post(this._clubApi + "/AddRegistration", { source: source, target: target });
	}
	public static async removeRegistration(source: AV.IFK, target: AV.ILink): Promise<AV.ILeagueTeam> {
		return await Server.post(this._clubApi + "/RemoveRegistration", { source: source, target: target });
	}
	public static async closeRegistration(source: AV.IFK): Promise<AV.ILeagueTeam> {
		return await Server.post(this._clubApi + "/CloseRegistration", source);
	}

	public static async addSchoolClass(source: AV.IFK, target: AV.ITypeLink): Promise<OrgBase> {
		return await Server.post(this._orgBaseApi + "/AddSchoolClass", { source: source, target: target });
	}
	public static async removeSchoolClass(source: AV.IFK, target: AV.ILink): Promise<OrgBase> {
		return await Server.post(this._orgBaseApi + "/RemoveSchoolClass", { source: source, target: target });
	}
}
