import "react-native-gesture-handler";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./App";
import registerServiceWorker from "react-service-worker";
import { MsalProvider } from "@azure/msal-react";
import { AVAuth } from "./Platform/AVAuth";
import { NativeBaseProvider } from "native-base";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AVTheme from "./Theme/Base";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/common/ErrorBoundaries";
const appSW = registerServiceWorker();
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

let AppProvider = () => (
	//<MsalProvider instance={AVAuth.pca}>
		<BrowserRouter>
			<NativeBaseProvider theme={AVTheme}>
				<QueryClientProvider client={queryClient}>
					{/*<ErrorBoundary>*/}
						<App appServiceWorker={appSW} />
					{/*</ErrorBoundary>*/}
					<ReactQueryDevtools />
				</QueryClientProvider>
			</NativeBaseProvider>
		</BrowserRouter>
	//</MsalProvider>
);

ReactDOM.render(<AppProvider />, document.getElementById("root"));
