const initialState = {
	alert: [],
	newalert: [],
};

const alertsReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case "SET_AlERTS_DATA":
			let payload = action.payload;

			return { ...state, alert: [action.payload] };
		case "SET_NEW_AlERTS_DATA":
			return { ...state, newalert: action.payload };
		case "SET_ALERT_STATE":
			return initialState;
		default:
			return state;
	}
};

export default alertsReducer;
