import React, { FC } from "react";
import Select, { components } from "react-select";
import FormControl from "@mui/material/FormControl";
import { Loc } from "../App/Loc";

interface Props {
	onChange: any;
	isSearchable: boolean;
	defaultValue: any;
	value: any;
	noOptionsMessage: () => void;
	components: any;
	options: any;
}

const customStyle = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "green" : "white", // Customize the background color based on the selected state
		color: state.isSelected ? "white" : "black", // Customize the text color based on the selected state
		fontWeight: "550px",
		paddingX: " 3",
		paddingY: "10px",
		borderStyle: "solid",
		borderWidth: "1px",
		borderRadius: "4px",
		boxShadow: "displey", // Remove the box shadow
		textAlign: "left", // Center the content
		width: "100%",
		position: "relative",
		height: "55px",
		zIndex: 3,
	}),
	indicatorsContainer: provided => ({
		...provided,
		display: "displey", // Hide the dropdown indicators container
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
		...styles,
		textAlign: "left", // Align the option text to the left
		backgroundColor: isFocused ? "#ededed" : null,
		color: isFocused ? "#000000" : "#000000",
		zIndex: 3,
	}),
	menu: (provided, state) => ({
		...provided,
		// custom styles for the menu container
		maxHeight: "110px",
		zIndex: 3,
	}),
	menuList: (provided, state) => ({
		...provided,
		maxHeight: "110px",
		zIndex: 3,
		// custom styles for the menu list
	}),
	menuPortal: (provided, state) => ({
		...provided,
		maxHeight: "110px",
		zIndex: 3,
		// custom styles for the menu portal
	}),
};

const AVDropdown: FC<Props> = ({
	defaultValue,
	value,
	isSearchable,
	options,
	components,
	onChange,
	noOptionsMessage,
}) => {
	return (
		<FormControl fullWidth>
			<Select
				defaultValue={defaultValue}
				value={value}
				options={options}
				isSearchable={isSearchable}
				noOptionsMessage={noOptionsMessage}
				placeholder={Loc.currentLang.menu.activityType}
				components={components}
				styles={customStyle}
				onChange={onChange}
			/>
		</FormControl>
	);
};

export default AVDropdown;
