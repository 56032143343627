import * as React from "react";

const ArrowDown = ({ color }) => (
	<>
		<path
			d="M7 10L12 15L17 10H7Z"
			id="Shape"
			fillOpacity="0.85"
			fill={color ? color : "#000000"}
			fillRule="nonzero"
		></path>
	</>
);

export default ArrowDown;
