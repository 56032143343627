import * as React from "react";

const CreateMessage = ({ color }) => (
	<>
		<path
			d="M18.020882,5.23252511 L13.8688084,5.23252511 C13.2955267,5.23252511 12.83079,5.69726178 12.83079,6.27054351 C12.83079,6.84382525 13.2955267,7.30856192 13.8688084,7.30856192 L18.020882,7.30856192 C18.5941638,7.30856192 19.0589004,7.77329859 19.0589004,8.34658032 L19.0589004,18.4361192 L17.4292115,16.9309925 C17.237777,16.7517629 16.9855987,16.6516332 16.723359,16.6507276 L7.64069797,16.6507276 C7.06741623,16.6507276 6.60267956,16.1859909 6.60267956,15.6127092 L6.60267956,14.5746908 C6.60267956,14.001409 6.13794289,13.5366723 5.56466116,13.5366723 C4.99137942,13.5366723 4.52664275,14.001409 4.52664275,14.5746908 L4.52664275,15.6127092 C4.52664275,17.3325544 5.92085276,18.7267644 7.64069797,18.7267644 L16.3185318,18.7267644 L19.4325871,21.5605546 C19.6240216,21.7397843 19.8761999,21.8399139 20.1384396,21.8408196 C20.2808089,21.8392681 20.4216323,21.8111034 20.5536469,21.7577781 C20.9331348,21.592159 21.1778998,21.2168527 21.1764643,20.8028012 L21.1764643,8.34658032 C21.1764643,7.51343701 20.8427515,6.71500696 20.2497418,6.12979997 C19.656732,5.54459298 18.8539513,5.22141653 18.020882,5.23252511 Z M8.24274864,12.4986713 L10.7547532,12.4986713 C11.3280349,12.4986713 11.792789,12.0339173 11.792789,11.4606355 L11.792789,8.948631 C11.7943668,8.67272261 11.6860493,8.40753155 11.4917463,8.21163793 L5.69960355,2.41949523 C5.2947375,2.017031 4.64086366,2.017031 4.2359976,2.41949523 L1.71361288,4.94187995 C1.31114865,5.34674601 1.31114865,6.00061985 1.71361288,6.40548591 L7.50575558,12.1976286 C7.7016492,12.3919317 7.96684025,12.5002491 8.24274864,12.4986713 Z M4.96261048,4.62009425 L9.71673478,9.37421854 L9.71673478,10.4122369 L8.67871637,10.4122369 L3.9142119,5.66849284 L4.96261048,4.62009425 Z"
			id="Shape"
			fillOpacity="0.85"
			fill={color ? color : "#000000"}
			fillRule="nonzero"
		></path>
	</>
);

export default CreateMessage;
