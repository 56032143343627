import React, { FC } from "react";
import { Text, Linking } from "react-native";

interface Props {
	text: string;
	fontSize?: number;
}

const AVlinktext: FC<Props> = ({ text, fontSize }) => {
	const linkRegex = /(https?:\/\/[^\s]+)/g;
	const emailRegex =
		/^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
	const phoneRegex = /^[0-9]{10}$/;
	const textParts = text?.trim()?.length > 0 ? text?.split(linkRegex) : [];

	return (
		<Text style={{ fontSize: fontSize || 15, padding: 1, paddingHorizontal: 2, color: "blue.400" }}>
			{textParts.map((part, index) => {
				if (part.match(linkRegex) || part.match(emailRegex) || part.match(phoneRegex)) {
					return (
						<Text
							key={index}
							onPress={() =>
								Linking.openURL(
									part.match(emailRegex)
										? `mailto:${part}`
										: part.match(phoneRegex)
											? `tel:${part?.replace(/\s/g, "")}`
											: part,
								)
							}
							style={{ color: "blue", textDecorationLine: "underline" }}
						>
							{part}
						</Text>
					);
				} else {
					return part;
				}
			})}
		</Text>
	);
};

export default AVlinktext;
