import { ChevronLeftIcon, ChevronRightIcon, Box, CloseIcon, Tooltip } from 'native-base';
import AVVideo from '../../Platform/AVVideo';
import { Media } from '../../AVCore/Media';
import { AHStack, AVBox, AVPressable, AVStack } from 'components/ui';
import fullscreenimage from '../../assets/images/fullscreen.png';
import normalscreen from '../../assets/images/normalscreen.png';
import React, { FC } from 'react';
import { Pressable, Linking } from 'react-native';
import AVCancel from 'components/common/AVCancel';
import { Dimensions } from 'react-native';
import PdfIcon from '../../assets/images/PdfIcon.png';
import docs from '../../assets/images/docs.png';
import xlsimg from '../../assets/images/xlsimg.png';
import MP3Icon from '../../assets/images/MP3ICON.png';
import * as AV from '../../AVCore/AVCore';
import settings from '../../Platform/settings.json';
import { useLocation } from 'react-router-dom';
import { setFeedShowing } from '../../Redux/Actions/userActions';
import { useDispatch } from 'react-redux';
import { DownloadIcon } from '../../assets/icons/icon-assets';
import GridView from '../posts/GridView';

interface Props
{
    slideItems : any | undefined,
    previewitem : any | undefined,
    setpreviewitem : any | undefined,
    type : any | undefined,
    settype : any | undefined,
    fullscreen : any | undefined,
    setIsFullScreen : any | undefined,
    setShowDetails :any | undefined,
    setIscommentItem : any | undefined,
    setIsCommentSlider : any | undefined,
    isCommentSlider : any | undefined,
    handleslideChange : any | undefined,
    isHeader : any | undefined,
    setShowPreview : any | undefined,
    setHeader : any | undefined,
    setCommentValue  : any | undefined,
}

const AVImageSlider : FC<Props> = ( { slideItems, previewitem, setpreviewitem, type, settype, fullscreen, setIsFullScreen, setShowDetails, setIscommentItem, setIsCommentSlider, isCommentSlider, handleslideChange, isHeader, setShowPreview, setHeader, setCommentValue }) =>
{
    const [ videoUrls, setVideoUrl ] = React.useState( slideItems?.galleryPost ? slideItems?.galleryPost : slideItems );
    const location : any = useLocation();
    const val = slideItems?.galleryPost?.filter((x) => x?.id === slideItems?.media?.id);
    let mediavalue:any = [];
    mediavalue.push(slideItems);
    let slides = ( val?.length === 0 || val == undefined ) && slideItems?.media ? mediavalue : slideItems?.galleryPost?.length > 0 ? slideItems?.galleryPost : slideItems; 
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [play, setplay] = React.useState(slides?.length > 0 ? slides[0] : slides);
    const [imageVIdeo, setimageVIdeo] = React.useState([]);
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions?.width <= 500 || false;
    const [ videoStatus, setVideoStatus ] = React.useState<any>( '' )
    const [ videoItem, setVideoItem ] = React.useState<any>( '' )

    const goToPrevious = () =>
    {
        setCommentValue( '' );
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        if (slides[0]?.type?.startsWith('video')) {
            setVideoUrl(slides[newIndex]);
            setplay(slides[newIndex]);
            setpreviewitem(slides[newIndex]);
        }
        setplay(slides[newIndex]);
        setpreviewitem(slides[newIndex]);
        setCurrentIndex(newIndex);
        handleslideChange()
    };
    const goToNext = () =>
    {
        setCommentValue( '' );
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        if (slides[0]?.type?.startsWith('video')) {
            setVideoUrl(slides[newIndex]);
            setplay(slides[newIndex]);
            setpreviewitem(slides[newIndex]);

        }
        setplay(slides[newIndex]);
        setpreviewitem(slides[newIndex]);
        setCurrentIndex(newIndex);
        handleslideChange()
    };

    const handledocument = async (item) => {
        let val = await AV.Media.getFile( item?.media?.mListId, item?.media?.id, item?.media?.storeId);
        if (val) {
            var blobUrl = val;
            var fileName = item?.media?.orgFile;
            var a = document.createElement('a');
            a.href = blobUrl.toString();
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            //    window.open(val);
        }
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
  };

  const getFileSource = (file : any = {}) => {
    const fileType : any = file?.type || '';

    if (typeof fileType !== 'string' || !fileType) {
      console.error('Invalid or missing file type.');
      return ''; // Return default value or handle error as needed
    }
        if ( fileType === 'application/pdf' )
        {
            return settings?.pdf;
        } else if ( fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' )
        {
            return settings?.word;
        } else if ( fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' )
        {
            return settings?.powerpoint;
        } else if ( fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' )
        {
            return settings?.excel;
        } else
        {
            return ''; // or any default value if needed
        }
    };

    const imageeeUrl : any = slides != undefined ? (
        slides?.length > 0 && slides[ currentIndex ]?.media?.linkURL && slides[ currentIndex ]?.media?.type !== 'dropbox' ? slides[ currentIndex ]?.media?.linkURL : slides[ currentIndex ]?.media?.linkURL && slides[ currentIndex ]?.media?.type == 'dropbox' ? slides[ currentIndex ]?.media?.orgFile : slides[ currentIndex ]?.media?.type?.startsWith( 'image' ) ? Media.getURL( slides[ currentIndex ]?.media ) : getFileSource( slides[ currentIndex ]?.media ) ) : null;

    if (type !== 'ALL' && slides?.length > 0) {
        setpreviewitem( slides[ currentIndex ] ? slides[ currentIndex ] : play );
    }

    const LinkIcon = () =>
    {
        return (
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="#888888">
                <path d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
            </svg>
        );
    };

    const SimpleLinkPreview = ({ url }) =>
    {
        const thumbnailUrl = url?.orgFile?.trim()?.length > 0 ? url?.orgFile : null;

        return (
            <div className="link-preview-wrapper">
                <div className="link-preview-image">
                    { thumbnailUrl ? (
                        <img src={ thumbnailUrl } alt={ "Preview" } />
                    ) : (
                        <div className="link-icon-container">
                            <LinkIcon />
                        </div>
                    ) }
                </div>
                <div className="link-preview-content">
                    <Pressable onPress={ () => Linking.openURL( url?.linkURL ) }>
                        <p className="link-url">{ url?.linkURL }</p>
                    </Pressable>
                </div>
            </div>
        );
    };


    return (
        <>
  
            { type === 'ALL' && (
                <>
                    <GridView
                        item={slideItems}
                        setVideoStatus={ setVideoStatus }
                        setVideoItem={ setVideoItem }
                    />
                </>
            ) }

            { type !== 'ALL' && (
                <div style={ {
                position: isHeader ? 'fixed' : 'relative',
                height: isHeader ? '' : '100%',
                backgroundColor: isHeader ? 'black' : '',
                top: isHeader ? '0px' : '',
                left: isHeader ? '0px' : '',
                right: isHeader ? '0px' : '',
                bottom: isHeader ? '0px' : '',
                zIndex: 999

            } }>
                <div>
                    { slides?.length > 1 && (
                        <>
                            <div onClick={ goToPrevious } style={ {
                                position: 'absolute',
                                top: '50%',
                                transform: 'translate(0, -50%)',
                                left: '15px',
                                fontSize: '45px',
                                color: '#fff',
                                zIndex: 1,
                                cursor: 'pointer',
                            } }>
                                <ChevronLeftIcon size={ '30px' } color={ '#fff' } />
                            </div>
                            <div onClick={ goToNext } style={ {
                                position: 'absolute',
                                top: '50%',
                                transform: 'translate(0, -50%)',
                                right: '15px',
                                fontSize: '45px',
                                color: '#fff',
                                zIndex: 1,
                                cursor: 'pointer',
                            } }>
                                <ChevronRightIcon size={ '30px' } color={ '#fff' } />
                            </div>
                        </>
                    ) }
                </div> )
                { ( play?.media?.id && ( play?.media?.type?.startsWith( 'image' ) || play?.media?.type == 'iframe' || play?.media?.type == 'dropbox' || play?.media?.type == 'Onedrive' || play?.media?.type == 'linkPreview')) && (
                    <>
                        { slides?.length >= 1 ? (
                            <div className={ fullscreen ? 'fullscreen-image-container' : isHeader ? 'image-containerHeader' : 'image-container' }>
                                { !fullscreen && !isHeader  && (
                                    <>
                                        { !slides[ currentIndex ]?.media?.linkURL  && (<AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    setIsFullScreen( true );
                                                } }
                                            >
                                                <img src={ fullscreenimage } height={ '25px' } width={ '25px' } />
                                            </Pressable>
                                        </AHStack>)}
                                        { slides[ currentIndex ]?.media?.linkURL && ( <AHStack position={ 'absolute' } right={ '30px' } top={ '0' } >
                                            <Pressable onPress={ () => Linking.openURL( slides[ currentIndex ]?.media?.orgFile?.startsWith( "GoogleDrive" ) ? slides[ currentIndex ]?.media?.linkURL : slides[ currentIndex ]?.media?.orgFile  ) } >

                                                <img src="https://www.shutterstock.com/image-vector/external-link-icon-on-grey-260nw-2005883333.jpg" height={ '50px' } width={ '50px' } />
                                            </Pressable>
                                        </AHStack> ) }
                                    </>
                                ) }
                                { isHeader && (
                                    <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                setShowPreview( false );
                                                setHeader( false );
                                            } }
                                        >
                                            <CloseIcon size='15px' color={ 'white' } />
                                        </Pressable>
                                        { slides[ currentIndex ]?.media?.linkURL && ( <AHStack position={ 'absolute' } right={ '40px' } top={ '0' } >
                                            <Pressable onPress={ () => Linking.openURL( slides[ currentIndex ]?.media?.orgFile?.startsWith( "GoogleDrive" ) ? slides[ currentIndex ]?.media?.linkURL : slides[ currentIndex ]?.media?.orgFile ) } >
                                                <img src="https://www.shutterstock.com/image-vector/external-link-icon-on-grey-260nw-2005883333.jpg" height={ '50px' } width={ '50px' } />
                                            </Pressable>
                                        </AHStack> ) }
                                    </AHStack>
                                ) }
                                { fullscreen && !isCommentSlider  &&  (
                                    <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                        <Pressable style={ { position: 'absolute', right: '0', top: '0' } }
                                            onPress={ () =>
                                            {
                                                setIsFullScreen( false );
                                            } }
                                        >
                                            <img src={ normalscreen } height={ '25px' } width={ '25px' } />
                                        </Pressable>
                                    </AHStack>
                                ) }

                                {
                                    slides[ currentIndex ]?.media?.type === 'iframe' ?
                                        <iframe src={ imageeeUrl } className={ !fullscreen ? 'detail_image' : fullscreen && isMobile ? 'detail_image_mobile' : 'full_image' } >
                                        </iframe> :
                                        slides[ currentIndex ]?.media?.type === 'linkPreview' ?
                                            <SimpleLinkPreview url={ slides[ currentIndex ]?.media } /> :
                                            (slides[ currentIndex ]?.media?.type?.startsWith( 'image' ) || slides[currentIndex]?.media?.type == "Onedrive" || slides[ currentIndex ]?.media?.type === "dropbox" ) ? 
                                        <img
                                            src={ imageeeUrl }
                                            alt='slide'
                                            className={ !fullscreen ? 'detail_image' : fullscreen && isMobile ? 'detail_image_mobile' : 'full_image' }
                                        /> :''
                                }
                            </div>
                        ) : (
                            <div
                                style={ {
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '10px',
                                    backgroundSize: 'contain',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                } }
                            >
                                    {
                                        slides[ currentIndex ]?.media?.orgFile?.startsWith( "GoogleDrive" ) ?
                                            <iframe src={ imageeeUrl } style={ { width: '80%', height: '80%', borderRadius: '10px' } }>
                                            </iframe> :
                                            <img
                                                src={ imageeeUrl }
                                                alt='slide'
                                                style={ { width: '80%', height: '80%', borderRadius: '10px' } }
                                            />
                                    }
                               
                            </div>
                        ) }
                    </>
                ) }

                { ( play?.media?.id && play?.media?.type?.startsWith('application')) && (
                    <>
                        { slides?.length > 0 ? (
                            <div className={ fullscreen ? 'fullscreen-image-container' : isHeader ? 'image-containerHeader' : 'image-container' }>
                                { isHeader && (
                                    <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                setShowPreview( false );
                                                setHeader( false );
                                            } }
                                        >
                                            <CloseIcon size='15px' color={ 'white' } />
                                        </Pressable>
                                    </AHStack>
                                ) }
                                <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                    <Tooltip openDelay={ 500 } label={ "Download" } >
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                handledocument( play );
                                            } }
                                        >
                                            <DownloadIcon height={ '25px' } width={ '25px' } />
                                        </Pressable>
                                    </Tooltip>
                                </AHStack>
                                <img
                                    src={ imageeeUrl }
                                    alt='slide'
                                    className={ !fullscreen ? 'detail_image' : fullscreen && isMobile ? 'detail_image_mobile' : 'full_image' }
                                />
                            </div>
                        ) : (
                            <div
                                style={ {
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '10px',
                                    backgroundSize: 'contain',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                } }
                            >

                                <img
                                    src={ imageeeUrl }
                                    alt='slide'
                                    style={ { width: '80%', height: '80%', borderRadius: '10px' } }
                                />
                            </div>
                        ) }
                    </>
                ) }

                {(play?.media?.id && play?.media?.type?.startsWith('video')) && (
                    <div className={ 'video-slide' }>
                        { !fullscreen && !isHeader && !slides[ currentIndex ]?.media?.linkURL && (
                            <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                <Pressable onPress={ () => { setIsFullScreen( true ); } }>

                                </Pressable>
                            </AHStack>
                        ) }
                        { isHeader && (
                            <AHStack position={ 'absolute' } right={ '10%' } top={ '10%' } zIndex={ 999 }>
                                <Pressable onPress={ () => { setShowPreview( false ); setHeader( false ); } }>
                                    <CloseIcon size='15px' color={ 'white' } />
                                </Pressable>
                            </AHStack>
                        ) }
                        { fullscreen  && (
                            <AHStack position={ 'absolute' } right={ '0' } top={ '0' }>
                                <Pressable style={ { position: 'absolute', right: '0', top: '0' } }
                                    onPress={ () => { setIsFullScreen( false ); } }>
                                    <img src={ normalscreen } height={ '25px' } width={ '25px' } />
                                </Pressable>
                            </AHStack>
                        ) }
                        <Box width={ '100%' } height={ fullscreen ? '100%' : '94%' }>
                            <video
                                controls
                                autoPlay
                                width={ '100%' }
                                height={ '100%' }
                                src={ play?.media?.linkURL ? play?.media?.linkURL : Media.getURL( play?.media ) }
                                className={ fullscreen ? 'full_video' : '' }
                            />
                        </Box>
                    </div>
                ) }
            </div> ) }
        </>
    )
}





export default AVImageSlider;
