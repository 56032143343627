import * as React from "react";

const Cancel = ({ color }) => (
	<>
		<path
			d="M13.2521233,11.9984844 L17.0706553,8.18883267 C17.4188719,7.84061614 17.4188719,7.27604559 17.0706553,6.92782906 C16.7224388,6.57961253 16.1578683,6.57961253 15.8096517,6.92782906 L12,10.7463611 L8.19034827,6.92782906 C7.84213173,6.57961254 7.2775612,6.57961255 6.92934467,6.92782907 C6.58112814,7.2760456 6.58112814,7.84061614 6.92934466,8.18883267 L10.7478767,11.9984844 L6.92934466,15.8081361 C6.76122921,15.9748786 6.66666667,16.2018556 6.66666667,16.4386379 C6.66666667,16.6754203 6.76122921,16.9023972 6.92934466,17.0691397 C7.09608717,17.2372552 7.32306414,17.3318177 7.55984646,17.3318177 C7.79662878,17.3318177 8.02360576,17.2372552 8.19034827,17.0691397 L12,13.2506077 L15.8096517,17.0691397 C15.9763942,17.2372552 16.2033712,17.3318177 16.4401535,17.3318177 C16.6769359,17.3318177 16.9039128,17.2372552 17.0706553,17.0691397 C17.2387708,16.9023972 17.3333333,16.6754203 17.3333333,16.4386379 C17.3333333,16.2018556 17.2387708,15.9748786 17.0706553,15.8081361 L13.2521233,11.9984844 Z"
			id="Shape"
			fillOpacity="0.85"
			fill={color ? color : "#000000"}
			fillRule="nonzero"
		></path>
	</>
);

export default Cancel;
