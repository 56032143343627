import { SET_CONVO_DATA, SET_CONVO_STATE } from "../Constants";

export const setConvo = data => ({
	type: SET_CONVO_DATA,
	payload: data,
});

export const setConvoState = () => ({
	type: SET_CONVO_STATE,
});
