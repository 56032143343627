/*!
 * Copyright © 2024 Actavivo
 */

import * as AV from "./Interfaces/AV.Interfaces";
import { Media } from "./Media";

export class Link {
	public constructor(public data: AV.ILink) {}
	public static getTummb(param: AV.ILink, defaultImg = ""): string {
		if (param?.thumb) return Media.getThumb(param);
		else return defaultImg;
	}
	public getTummb(defaultImg = ""): string {
		if (this.data.thumb) return Media.getThumb(this.data);
		else return defaultImg;
	}
}
