import React, { useEffect, useState } from 'react';
import { VStack, HStack, Input, Text, Divider, Box, Pressable, Image } from 'native-base';
import { Dimensions } from 'react-native';
import { SearchIcon } from '../../assets/icons/icon-assets';
import { Person } from '../../AVCore/AVCore';
import Moment from 'moment';
import AVBody from 'components/common/AVBody';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Loc } from '../../App/Loc';
import RenderEvents from '../Events/RenderEvents';
import Filter from '../../components/common/AVFilter';
import Dropdown from '../../assets/images/dropdown.svg';

const Myfavorites = (props : any) => {
    const [ showCreateNewPage, setShowCreateNewPage ] = useState<any>(false);
    const [ createType, setCreateType ] = useState<any>("");
    const user = useSelector((state : RootStateOrAny) => state?.user?.user[ 0 ]);
    const [ showEdit, setIsShowEdit ] = useState( false );
    const [ isOpenConvo, setIsOpenConvo ] = useState( false );
    const [ isConvoData, setIsConvoData ] = useState( '' )
    return (
        <AVBody isGeneral={ undefined } setIsShowEdit={ setIsShowEdit } setIsGeneral={ undefined } isRole={ undefined } setIsRole={ undefined } Entity={ undefined } setIsEntity={ undefined } type={ undefined } isHomepage={ undefined } setShowCreateNewPage={ setShowCreateNewPage } showCreateNewPage={ showCreateNewPage } setCreateType={ setCreateType } createType={ createType } passEntity={ undefined } setPassEntity={ undefined } isOpenConvo={ isOpenConvo } setIsOpenConvo={ setIsOpenConvo } setIsConvoData={ setIsConvoData } isConvoData={ isConvoData } >
            <VStack flex={ [ 1, 1, 1, 3 ] }>
                <FavoriteContent user={ user } />
            </VStack>
        </AVBody>
    );
};

const FavoriteContent = ({ user }) => {
    const [ teamType, SetTeamType ] = useState(Loc.currentLang.menu.all);
    const [ data, setTeamsData ] = React.useState([]);
    const [ teamsList, setTeamsList ] = React.useState<any>();
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions?.width <= 500 || false;
    const [ isDropDown, setIsDropDown ] = React.useState(false);

    const setAll = () => {
        SetTeamType( Loc.currentLang.menu.all );
        let FilteredAll = user?.favs;
        FilteredAll = FilteredAll.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData(FilteredAll);
        setTeamsList(FilteredAll);
    };

    const setMyTeams = () => {
        SetTeamType( Loc.currentLang.menu.filterTeams );
        let FilteredTeams = user?.favs?.filter( ( x ) => ( x.type === 'PlayerOrg' || x.type === 'Team' ) && x?.subType === undefined ) || []
        FilteredTeams = FilteredTeams.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilteredTeams );
        setTeamsList( FilteredTeams );
    }

    const setClub = () => {
        SetTeamType( Loc.currentLang.menu.clubs );
        let FilteredClub = user?.favs?.filter( ( x ) => x.subType === 'Club' ) || [];
        FilteredClub = FilteredClub.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData(FilteredClub);
        setTeamsList(FilteredClub);
    };

    const setElite = () => {
        SetTeamType( Loc.currentLang.menu.eliteClubs );
        let FilteredElite = user?.favs?.filter( ( x ) => x?.type === "Club" ) || [];
        FilteredElite = FilteredElite.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilteredElite );
        setTeamsList( FilteredElite );
    };

    const setSchools = () => {
        SetTeamType( Loc.currentLang.menu.schools );
        let FilteredSchool = user?.favs?.filter( ( x ) => x.type === 'School' ) || [];
        FilteredSchool = FilteredSchool.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData(FilteredSchool);
        setTeamsList(FilteredSchool);
    };

    const setGroup = () => {
        SetTeamType( Loc.currentLang.menu.groups );
        let FiltededGroup = user?.favs?.filter( ( x ) => x.type === 'Group' && x?.subType === undefined ) || [];
        FiltededGroup = FiltededGroup.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FiltededGroup );
        setTeamsList( FiltededGroup );
        setIsDropDown(false)
    };

    const setClass = () => {
        SetTeamType( Loc.currentLang.menu.classes );
        let FilterdClass = user?.favs?.filter( ( x ) => x?.type === 'PlayerOrg' && x?.subType === "Class" ) || [];
        FilterdClass = FilterdClass.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilterdClass );
        setTeamsList( FilterdClass );
        setIsDropDown(false)

    };

    const setCommunity = () => {
        SetTeamType( Loc.currentLang.menu.community );
        let FilteredCommunity = user?.favs?.filter( ( x ) => x.type === 'Community' ) || [];
        FilteredCommunity = FilteredCommunity.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilteredCommunity );
        setTeamsList( FilteredCommunity );
        setIsDropDown(false)
    };

    const setGradClass = () => {
        SetTeamType( Loc.currentLang.menu.gradClass );
        let FilteredGradClass = user?.favs?.filter( ( x ) => x?.type === 'PlayerOrg' && x?.subType === "GradClass" ) || [];
        FilteredGradClass = FilteredGradClass.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilteredGradClass );
        setTeamsList( FilteredGradClass );
        setIsDropDown(false)
    };

    const setNeighbors = () => {
        SetTeamType( Loc.currentLang.menu.neighborHood );
        let FilteredNeighbor = user?.favs?.filter( ( x ) => x?.type === 'Neighborhood' ) || [];
        FilteredNeighbor = FilteredNeighbor.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilteredNeighbor );
        setTeamsList( FilteredNeighbor );
        setIsDropDown(false)

    };

    const setOrgs = () => {
        SetTeamType( Loc.currentLang.menu.orgs );
        let FilteredOrganization = user?.favs?.filter( ( x ) => x?.type === 'Organization' ) || [];
        FilteredOrganization = FilteredOrganization.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilteredOrganization );
        setTeamsList( FilteredOrganization );
        setIsDropDown(false)

    };

    const setPerson = () => {
        SetTeamType( Loc.currentLang.menu.people );
        let FilteredPerson = user?.favs?.filter( ( x ) => x?.type === "Person" ) || [];
        FilteredPerson = FilteredPerson.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilteredPerson );
        setTeamsList( FilteredPerson );
        setIsDropDown(false)
    };

    const setPerformanceGroup = () =>
    {
        SetTeamType( Loc.currentLang.menu.musicGroup );
        let FilteredPerformanceGroup = user?.favs?.filter( ( x ) => x?.type === 'PlayerOrg' && x?.subType === "MusicGroup" ) || [];
        FilteredPerformanceGroup = FilteredPerformanceGroup.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData( FilteredPerformanceGroup );
        setTeamsList( FilteredPerformanceGroup );
        setIsDropDown( false )
    }

    const GetFavorites = () => {
        let results = user?.favs;
        results = results.filter( ( org ) => org.title ) // Ensure the title exists
            .sort( ( a, b ) => a.title.localeCompare( b.title ) );
        setTeamsData(results);
        setTeamsList(results);
    };

    const MenuFilter = [
        ...(user?.favs?.length > 0 ? [ { id: 1, name: Loc.currentLang.menu.all, flag: data, fn: setAll } ] : []),
        ...( user?.favs?.filter( ( x ) => ( x.type === 'PlayerOrg' || x.type === 'Team') && x?.subType === undefined)?.length > 0 ? [ { id: 2, name: Loc.currentLang.menu.filterTeams, flag: data, fn: setMyTeams } ] : []),
        ...(user?.favs?.filter((x) => x.subType === 'Club')?.length > 0 ? [ { id: 3, name: Loc.currentLang.menu.clubs, flag: data, fn: setClub } ] : []),
        ...(user?.favs?.filter((x) => x?.type === "Club")?.length > 0 ? [ { id: 4, name: Loc.currentLang.menu.eliteClubs, flag: data, fn: setElite } ] : []),
        ...(user?.favs?.filter((x) => x.type === 'School')?.length > 0 ? [ { id: 5, name: Loc.currentLang.menu.schools, flag: data, fn: setSchools } ] : []),
        ...(user?.favs?.filter((x) => x.type === 'Group' && x?.subType === undefined)?.length > 0 ? [ { id: 6, name: Loc.currentLang.menu.groups, flag: data, fn: setGroup } ] : []),
        ...( user?.favs?.filter( ( x ) => x?.type === 'PlayerOrg' && x?.subType === "Class")?.length > 0 ? [ { id: 7, name: Loc.currentLang.menu.classes, flag: data, fn: setClass } ] : []),
        ...(user?.favs?.filter((x) => x.type === 'Community')?.length > 0 ? [ { id: 8, name: Loc.currentLang.menu.community, flag: data, fn: setCommunity } ] : []),
        ...( user?.favs?.filter( ( x ) => x?.type === 'PlayerOrg' && x?.subType === "GradClass")?.length > 0 ? [ { id: 9, name: Loc.currentLang.menu.gradClass, flag: data, fn: setGradClass } ] : []),
        ...(user?.favs?.filter((x) => x.type === 'Neighborhood')?.length > 0 ? [ { id: 10, name: Loc.currentLang.menu.neighborHood, flag: data, fn: setNeighbors } ] : []),
        ...( user?.favs?.filter( ( x ) => x.type === 'Organization' )?.length > 0 ? [ { id: 11, name: Loc.currentLang.menu.orgs, flag: data, fn: setOrgs } ] : [] ),
        ...( user?.favs?.filter( ( x ) => x?.type === 'PlayerOrg' && x?.subType === "MusicGroup")?.length > 0 ? [ { id: 12, name: Loc.currentLang.menu.musicGroup, flag: data, fn: setPerformanceGroup } ] : [] ),
        ...(user?.favs?.filter((x) => x?.type === "Person")?.length > 0 ? [ { id: 13, name: Loc.currentLang.menu.people, flag: data, fn: setPerson } ] : []),
    ];

    useEffect(() => {
        GetFavorites();
    }, []);

    const menuRefs = React.useRef(null);

    useOutsideAlerter(menuRefs);
    function useOutsideAlerter (ref) {
        React.useEffect(() => {
            function handleClickOutside (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsDropDown(false)
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }

    const handleSearch = (item) => {
        if (item) {
            setTeamsData(item?.trim()?.length > 0 ? teamsList?.filter((x) => (x?.title !== undefined && x?.title?.toLocaleLowerCase().includes(item.toLocaleLowerCase()) || x?.data !== undefined && x?.data?.toLocaleLowerCase().includes(item.toLocaleLowerCase()))) : teamsList);
        }
        else {
            setTeamsData(teamsList);
        }
    };

    return (
        <>
            <Box bgColor='#fff' shadow='1' borderRadius={ 5 }>
                <VStack px={ 3 } pt={ 2 }>
                    <HStack alignItems={ 'center' } space={ 3 } flex={ 1 } justifyContent={ 'space-between' }>
                        { !isMobile && (<Text fontSize={ '20px' } fontWeight={ 'bold' } width={ isMobile ? '25%' : '40%' } text-overflow={ 'ellipsis' } numberOfLines={ 1 }>
                            { Loc.currentLang.menu.hello },
                            <Text color={ 'AVColor.primary' } width={ '100px' } text-overflow={ 'ellipsis' } fontSize={ '17px' } numberOfLines={ 1 }>{ Person.getLink(Person.self())?.title }!</Text>
                        </Text>) }
                        <HStack alignItems={ 'center' } justifyContent={ isMobile ? 'flex-start' : 'flex-end' } space={ 2 } width={ isMobile ? '100%' : '61%' }>
                            { !isMobile && (<Text fontSize={ [ 12, 14 ] } color='AVColor.textLite'>
                                { Moment().format('MMMM D, Y') }
                            </Text>) }
                            <Input
                                borderWidth={ 0 }
                                placeholder={ Loc.currentLang.menu.searchFavorites }
                                placeholderTextColor='AVColor.textLite'
                                variant='AVSearchInput'
                                backgroundColor={ '#F5F5F5' }
                                borderRadius={ '22px' }
                                width={ isMobile ? "97%" : "251px" }
                                fontSize={ '16px' }
                                InputLeftElement={
                                    <SearchIcon ml='2' size='24px' color='#000' left={ '10px' } p={ '5px' } />
                                }
                                onChangeText={ (e) => handleSearch(e) }
                            />
                        </HStack>
                    </HStack>

                    {/* menu */ }
                    <div className="custom-scrollbar">
                        <HStack width={ '100%' } space={ 5 }>
                            <VStack width={ isMobile ? "" : "" }>
                                <HStack marginY={ '24px' } backgroundColor={ 'rgba(0,0,0,0.05)' } borderRadius={ '18px' } width={ isMobile ? '100%' : 'auto' } overflow={ isMobile ? 'auto' : 'undefined' } height={ '36px' }>
                                    { MenuFilter?.slice(0, 6)?.map((item, index) => (
                                        <Filter key={ item?.id + index } Type={ teamType } SetType={ item?.fn } Name={ item?.name } />
                                    )) }
                                    { MenuFilter?.slice(6, MenuFilter?.length)?.length > 0 && <Pressable marginLeft={ '-5px' } marginTop={ '4px' } onPress={ () => setIsDropDown(!isDropDown) }>
                                        <Image source={ Dropdown } alt='Actavivo' size='30px' />
                                    </Pressable>
                                    }
                                </HStack>
                                { isDropDown && (<VStack
                                    ref={ menuRefs }
                                    width={ '128px' }
                                    height='auto'
                                    shadow={ 2 }
                                    background={ '#FFFFFF' }
                                    position={ 'absolute' }
                                    top={ '66px' }
                                    right={ 0 }
                                    zIndex={ 999 }
                                    borderRadius={ '6px' }
                                >
                                    { MenuFilter?.slice(6, MenuFilter?.length)?.map((item, index) => (
                                        <>
                                            <Pressable
                                                ref={ menuRefs }
                                                key={ item.id + index }
                                                onPress={ item.fn }
                                                width={ '100%' }
                                                height={ '36px' }
                                                alignItems={ 'flex-start' }
                                                paddingX={ '10px' }
                                                _hover={ {
                                                    backgroundColor: 'gray.100'
                                                } }
                                                justifyContent={ 'center' }
                                            >
                                                <Text
                                                    fontSize={ '14px' }
                                                    fontFamily={ 'Roboto' }
                                                >
                                                    { item.name }
                                                </Text>
                                            </Pressable>
                                            <Divider />
                                        </>
                                    )) }

                                </VStack>) }
                            </VStack>
                        </HStack>
                    </div>
                </VStack>
            </Box>
            <VStack my={ 3 } zIndex={ -3 }>
                <Text fontSize={ '24px' } color={ 'AVColor.primary' }>
                    { Loc.currentLang.menu.favorites }
                </Text>
                <HStack
                    backgroundColor={ 'AVColor.white' }
                    space={ 1 }
                    flexWrap={ 'wrap' }
                    mt={ 2 }
                    mb={ isMobile ? "30px" : "" }
                    height={ 'auto' }
                    borderRadius={ 5 }
                    justifyContent={ 'center' }
                    p={ 2 }
                    width={ '100%' }
                >
                    { data?.length !== 0 &&
                        (<RenderEvents data={ data } />) }
                    { data?.length === 0 &&
                        (<VStack >
                            <HStack padding={ '10px' } alignItems={ 'center' } >
                                <Text>No Favourite Found</Text>
                            </HStack>
                        </VStack>) }
                </HStack>
            </VStack>
        </>
    );
};

export default React.memo(Myfavorites);
