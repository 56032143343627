import { AddIcon, Box, Button, CloseIcon, Divider, HStack, Pressable, Text, VStack } from "native-base";
import React, { FC } from "react";
// import { HappyIcon, CancelIcon, CameraIcon } from '../../assets/images/svgIcons';
import AVAdd from "components/common/AVAdd";
import { AVButtonRoundedFull, AVButtonRoundInvert } from "components/common/AVButtons";
import AVCamera from "components/common/AVCamera";
import AVCancel from "components/common/AVCancel";
import AVEmoji from "components/common/AVEmoji";
import "../Screens/Home/style.css";
import Picker from "emoji-picker-react";
// import useBreakPoints from '../../helpers/breakPoints';
import * as AV from "AVCore/AVCore";
import useGetFeeds from "Hooks/useGetFeeds";
import { useNavigate } from "react-router-dom";
import { AddPhotoIcon } from "assets/icons/icon-assets";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { AHStack, AVBox, AVPressable, AVStack } from "components/ui";

function FilePicker(props: any) {
	const currentUser = useSelector((state: RootStateOrAny) => state?.user?.user[0]);
	const [imageUrls, setImagesUrl] = React.useState([]);
	const [images, setImages] = React.useState<any>([]);
	const [media, setMedia] = React.useState<any>([]);
	const [showAddMedia, setShowAddMedia] = React.useState(false);
	const [picker, setPicker] = React.useState(false);
	const [isImageProcessing, setIsImageProcessing] = React.useState(false);
	const [noticeMessage, setNoticeMessage] = React.useState("");
	const imageInputRef = React.useRef<any>(null);
	const handleImages = async (e: any) => {
		let files: any = Array.from(e?.target?.files);
		setShowAddMedia(false);
		setPicker(false);
		files.forEach((img: any) => {
			if (
				img.type !== "image/jpeg" &&
				img.type !== "image/png" &&
				img.type !== "image/webp" &&
				img.type !== "image/gif"
			) {
				files = files.filter((item: any) => item.name !== img.name);
				return;
			}
			//  else if (img.size > 1024 * 1024 * 5) {
			//   setError(`${img.name} size is too large max 5mb allowed.`);
			//   files = files.filter((item) => item.name !== img.name);
			//   return;
			// }
			else {
				const reader = new FileReader();
				reader.readAsDataURL(img);
				reader.onload = (readerEvent: any) => {
					setImages(images => [...images, readerEvent.target.result]);
				};
			}
		});
		setIsImageProcessing(true);
		setNoticeMessage("Please wait while we process your images...");
		const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
		setMedia(prev => {
			return { ...prev, ...data };
		});
		setNoticeMessage("");
		setIsImageProcessing(false);
	};

	return (
		<>
			<div className="overflow_a scrollbar">
				<input
					type="file"
					accept="image/jpeg,image/png,image/webp,image/gif"
					multiple
					hidden
					ref={imageInputRef}
					onChange={handleImages}
				/>
				<HStack my={3} mx={5} space={4} alignItems={"center"} zIndex={0}>
					{images && images.length ? (
						<RenderImages images={images} imageInputRef={imageInputRef} setImages={setImages} />
					) : (
						<Box
							//my={ 1 }
							// mx={ 3 }
							borderWidth={1}
							borderRadius={5}
							overflow={"hidden"}
							borderColor={"avcolors.black"}
							borderStyle={"none"}
							maxH={"300px"}
							height="100%"
							width="100%"
							overflowY={"auto"}
							//  zIndex={ 0 }
						>
							<Pressable onPress={() => imageInputRef?.current.click()}>
								<VStack alignItems={"center"} justifyContent={"center"} minH={"150px"} space={2}>
									<Box backgroundColor={"AVColor.grey"} p={2} borderRadius="full">
										<AddIcon color={"AVColor.secondary"} />
									</Box>
									<Text fontSize={14} color={"AVColor.grey"}>
										(images / videos)
									</Text>
								</VStack>
							</Pressable>
						</Box>
					)}
				</HStack>
			</div>
		</>
	);
}
const RenderImages = ({ images, imageInputRef, setImages }) => {
	return (
		<div className="add_pics_inside1 p0">
			<div className="preview_actions">
				<AVAdd addEvent={() => imageInputRef.current.click()} />
			</div>
			<div
				className="small_white_circle"
				onClick={() => {
					setImages([]);
				}}
			>
				<AVCancel callback={() => setImages([])} color={"#000000"} size={undefined} />
			</div>
			<div
				className={
					images.length === 1
						? "preview1"
						: images.length === 2
							? "preview2"
							: images.length === 3
								? "preview3"
								: images.length === 4
									? "preview4 "
									: images.length === 5
										? "preview5"
										: images.length % 2 === 0
											? "preview6"
											: "preview6 singular_grid"
				}
			>
				{images.map((img, i) => (
					<img src={img} key={i} alt="" className="Image" />
				))}
			</div>
		</div>
	);
};
const LoadMedia = ({ item, isDetail = false }) => {
	const [imageUrls, setImagesUrl] = React.useState([]);
	// let classname = useBreakpointValue({
	//   base: 'grid_1_mobile',
	//   sm: 'grid_1_mobile',
	//   md: 'grid_1',
	//   lg: 'grid_1',
	//   xl: 'grid_1',
	// });
	let media: any = [];

	React.useEffect(() => {
		if (
			(item?.galleryPost && item?.galleryPost.length > 0) ||
			(item?.media?.storeId && item?.media?.type?.startsWith("image"))
		) {
			for (let i = 0; i < item?.galleryPost.length; i++) {
				media.push(item?.galleryPost[i].media);
			}
			if (item?.media?.storeId && item?.media?.type?.startsWith("image")) {
				media.push(item?.media);
			}
			let images = media.length && media.map(i => AV.Media.getURL(i));

			setImagesUrl(images);
		}
	}, []);

	return (
		<AVStack width={"100%"}>
			<div>
				{imageUrls && imageUrls.length > 0 && (
					<div
						className={
							imageUrls.length === 1
								? "grid_1"
								: imageUrls.length === 2
									? "grid_2"
									: imageUrls.length === 3
										? "preview3"
										: imageUrls.length === 4
											? "grid_4"
											: imageUrls.length >= 5
												? "grid_5"
												: "defult"
						}
					>
						{imageUrls.slice(0, 5).map((url, i) => (
							<img src={url} key={i} alt="" className={`img-${i}`} style={{ margin: "1px" }} />
						))}
						{imageUrls.length > 5 && <div className="more-pics-shadow">+{imageUrls.length - 5}</div>}
					</div>
				)}
			</div>
		</AVStack>
	);
};

export default FilePicker;
