import React, { FC } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
interface Props {
	onEmojiSelect: any;
}

const AVEmojiPicker: FC<Props> = ({ onEmojiSelect }) => {
	return (
		<div style={{ width: "100%" }}>
			<Picker data={data} dynamicWidth={true} previewPosition="none" onEmojiSelect={onEmojiSelect} />
		</div>
	);
};

export default AVEmojiPicker;
