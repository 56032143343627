import React, { useState, useEffect } from "react";
import { VStack, Text, HStack, Image, Box, Pressable, CloseIcon, Button } from "native-base";
import { Loc } from "../../App/Loc";
import unauthorPopup from "../../assets/images/unauthorPopup.png";
import { useNavigate } from "react-router-dom";

function ErrorHandlerPopup() {
	const navigate = useNavigate();
	return (
		<>
			<Box
				position={"fixed"}
				top={0}
				bottom={0}
				left={0}
				right={0}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				backgroundColor={"rgba(0, 0, 0, 0.25)"}
				zIndex={9999}
			>
				<div className={`customModal`}>
					<div className={`show_unauthorized_details`}>
						<VStack>
							<Pressable
								padding={1.5}
								rounded={"full"}
								zIndex={999}
								position={"absolute"}
								top={"20px"}
								right={"20px"}
								_hover={{ backgroundColor: "AVColor.hovercolor2" }}
								backgroundColor={"AVColor.buttonBg"}
								onPress={() => {
									navigate("/");
									window.location.reload();
								}}
							>
								<CloseIcon size={"16px"} color={"AVColor.secondaryText"} />
							</Pressable>
							<HStack marginLeft={"208px"} marginTop={"46px"}>
								<img src={unauthorPopup} style={{ width: "101px", height: "112px" }} />
							</HStack>
							<HStack justifyContent={"center"}>
								<Text color={"#32AE78"} fontSize={"24px"} textAlign={"center"}>
									{Loc.currentLang.menu.somethingWrong}
								</Text>
							</HStack>
							<HStack justifyContent={"center"} marginTop={"5px"}>
								<Text color={"#000000"}>{Loc.currentLang.menu.unableProcess}</Text>
							</HStack>
							<HStack justifyContent={"center"} marginTop={"55px"}>
								<Button
									rounded="full"
									backgroundColor={"#EEEEEE"}
									width={"108px"}
									height={"40px"}
									onPress={() => {
										navigate("/");
										window.location.reload();
									}}
								>
									<Text fontSize={"16px"} color={"rgba(0,0,0,0.5)"} fontWeight={"bold"}>
										{Loc.currentLang.menu.ok}
									</Text>
								</Button>
							</HStack>
						</VStack>
					</div>
				</div>
			</Box>
		</>
	);
}

export default React.memo(ErrorHandlerPopup);
