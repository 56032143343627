//@ts-nocheck
import * as React from "react";
import { Person } from "../AVCore/Person";
import * as AV from "../AVCore/AVCore";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { setUser } from "../Redux/Actions/userActions";
import { Store } from "../Platform/Store";
import { setFeeds } from "../Redux/Actions/feedsAction";

const useFollowRequest = () => {
	const dispatch = useDispatch();
	const stateUser = useSelector(state => state);
	const currentUser = stateUser?.user?.user[0];
	const userFeeds = useSelector(state => state?.feeds.feeds[0]);
	const [showRequestModal, setShowRequestModal] = React.useState(false);
	const [showFollowingModal, setShowFollowingModal] = React.useState(false);
	const [managerequestdata, setManageRequestdata] = React.useState(currentUser?.follows);
	const [unfollowLoader, setunfollowLoader] = React.useState();

	const getRequestFK = async () => {
		let followList = currentUser?.requests;
		return followList;
	};
	const { data } = useQuery(
		"allfollowlist",
		getRequestFK,
		{
			keepPreviousData: true,
		},
		{
			refetchInterval: 1000,
		},
	);

	const sourceFK = {
		Id: currentUser?.id,
		Pk: currentUser?.pk,
		Region: currentUser?.region,
		Type: currentUser?.type,
	};
	// param for get thumb image
	const getThumbFK = {
		id: "",
		pk: "",
		region: "USC",
		type: "",
	};
	const handleAcceptProfile = async (itemFK, type) => {
		//@ts-ignore
		(await type) === "Accept"
			? await Person.approveRequest(sourceFK, itemFK)
			: await Person.denyRequest(sourceFK, itemFK);
		//await Person.getSelf(true);
		setShowRequestModal(!showRequestModal);
	};

	const handleUnFollow = async itemFK => {
		setunfollowLoader(itemFK);
		//@ts-ignore
		await Person.unFollow(sourceFK, itemFK);
		setManageRequestdata(managerequestdata?.filter(x => x?.id !== itemFK?.id));
		let userFeed = userFeeds?.filter(x => x?.owner?.id !== itemFK?.id);
		dispatch(setFeeds(userFeed));
		await AV.Person.getSelf(true).then(x => {
			dispatch(setUser(x));
		});
		setShowFollowingModal(!showFollowingModal);
		setunfollowLoader();
	};

	return {
		showRequestModal,
		setShowRequestModal,
		getThumbFK,
		handleAcceptProfile,
		handleUnFollow,
		managerequestdata,
		setManageRequestdata,
		data,
		unfollowLoader,
		setunfollowLoader,
	};
};
export default useFollowRequest;
