import React, { FC } from "react";
import AVPostPopup from "../../components/common/AVPostPopup";

interface Props {
    visibilityPermission: string;
    setShowCreatePost: any;
    Postdata: any;
}

const CreatePostpopup: FC<Props> = ({ setShowCreatePost, Postdata, visibilityPermission }) => {

    return (
        <AVPostPopup
            visibilityPermission={visibilityPermission}
            Postdata={Postdata}
            setShowCreatePost={setShowCreatePost}
            type={'Create'}
        />
    );
};

export default CreatePostpopup;
