import React, { useState, useEffect } from 'react';
import
    {
        VStack,
        Text,
        HStack,
        Box,
        Image,
        Pressable,
        Button,
        Select,
        CheckIcon,
        Tooltip
    } from 'native-base';
import { EditIcon, DeleteIcon, AddPhotoIcon, Profile, GroupChatIcon } from '../../assets/icons/icon-assets';
import AvatarWhite from '../../assets/icons/avatar-whitebg.svg';
import * as AV from '../../AVCore/AVCore';
import { setEntitydata } from '../../Redux/Actions/userActions';
import { IStaff } from '../../AVCore/Interfaces/AV.Interfaces';
import LogoSvg from '../../assets/images/Actavivo-Logo.svg';
import { AVEntity } from '../../AVCore/AVEntity';
import { Media } from '../../AVCore/Media';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { Loc } from '../../App/Loc';
import { useDropzone } from 'react-dropzone';
import { TouchableOpacity } from 'react-native';
import { Linking } from 'react-native';
import useConvoChat from '../../Hooks/useConvoChat';


function StaffsCard ( { leagueTeam, data, staffList, setStaffList, setIsStaffEdit, Editstaffitem, setEditstaffitem, setStaffRole, setIsOpenConvo, setstaffphone, setstaffemail, setstaffpic, HandleRemoveStaffs, setIsConvoData } )
{
    let item = data?.item;
    const [ isPicLoader, setIsPicLoader ] = useState( false );
    let LeagueTeam = leagueTeam;
    const [ isEditPlayerDetails, setIsEditPlayerDetails ] = useState( false );
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const [ isdelete, setIsDelete ] = useState( false );
    const [ isCurrentUser, setCurrentUser ] = useState( false );
    const [ isRotate, setIsRotate ] = useState( false );
    const { handleSendSingleChat } = useConvoChat();
    const userconvo = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    //Delete Staff
    const handleRemoveStaff = async () =>
    {
        LeagueTeam = await AV.OrgBase.removeStaff( AVEntity.getFk( LeagueTeam ), {
            id: item.id,
            pk: item.pk,
            type: 'Person',
            role: item.role,
        } );
        if ( LeagueTeam )
        {
            setStaffList( LeagueTeam?.staff );
            setIsDelete( true );
            entitydatalist[ entitydatalist.indexOf( entitydatalist?.find( x => x?.id === LeagueTeam?.id ) ) ] = LeagueTeam;
            dispatch( setEntitydata( entitydatalist ) );
        }
    };

    //useeffect
    useEffect( () =>
    {
        if ( LeagueTeam?.staff )
        {
            if ( LeagueTeam?.staff.length > 0 )
            {
                var getstafflist = LeagueTeam?.staff.filter( ( x ) => x?.id === currentUser?.id );
                if ( getstafflist.length > 0 )
                {
                    setCurrentUser( true );
                } else
                {
                    setCurrentUser( false );
                }
            } else
            {
                setCurrentUser( false );
            }
        }
    } );

    const handleEmailPress = (item) =>
    {
        if ( item?.email )
        {
            Linking.openURL( `mailto:${ item.email }` );
        }
    };
    const findPersonInChats = ( conversations, personToCheck ) =>
    {
        return conversations.find( convo =>
            convo?.subject === '' &&
            convo?.people?.some( person =>
                person?.id === personToCheck?.id &&
                person?.pk === personToCheck?.pk
            )
        );
    };
    const handleChat = async(item:any) =>
    {
        setIsRotate(true)
        let People:any = [];
        People?.push( item )
        let valid = await findPersonInChats( userconvo, item )
        if (valid?.id !== undefined )
        {
            setIsConvoData( valid?.id )
            setIsRotate( false )
            setIsOpenConvo( true )
            return;
        }
       
       await handleSendSingleChat( People );
        setIsRotate( false )
        setIsOpenConvo(true)
    }

    const getMedia = async ( files ) =>
    {
        const updatedFiles = files.map( ( file ) =>
        {
            const fileName = file.name;
            if ( fileName.toLowerCase().endsWith( '.jfif' ) )
            {
                const updatedFileName = fileName.replace( /\.\w+$/, '.jpg' );
                const updatedFile = new File( [ file ], updatedFileName, { type: "image/jpeg" } );
                return updatedFile;
            }
            else
            {
                return file;
            }
        } );
        files = updatedFiles;
        let iMedia = await Media.saveFiles(
            AV.Media.getFormData( files ),
            item?.region
        );
        return iMedia[ 0 ];
    };
    const { getRootProps: getProfilePicFile, getInputProps: getProfileInputProps } = useDropzone( {
        accept: 'image/*',
        onDrop: ( acceptedFiles : any ) =>
        {
            setIsPicLoader( true );
            getMedia( acceptedFiles )
                .then( ( res ) =>
                {
                    let staff : IStaff = item;
                    staff.linkPic = res;
                    setIsPicLoader( false );

                } )
        },
    } );

    return (
        <>
            { !isdelete &&
                <Box key={ item.id } bgColor={ '#fff' } borderWidth={ "1px" } shadow={ 1 } borderColor={ "gray.400" } marginX={ '17px' } marginTop={ 5 } marginBottom={ '20px' } overflow="hidden" width={ '225px' } height={ 225 } rounded="lg" >
                    <VStack flex={ 1 } justifyContent={ 'flex-start' } alignItems={ 'center' }>
                        { currentUser?.id !== item?.id && (
                            <Pressable position={ 'absolute' } top={ '10px' } left={ '10px' } onPress={ () => handleChat(item)}>
                                { !isRotate ?
                                    <GroupChatIcon size={ 23 } color={ 'rgba(0, 0, 0, 0.5)' } /> : <div className="loading"></div>
                            }
                            </Pressable>
                        ) }
                        <HStack justifyContent={ 'center' }>
                            <VStack alignItems={ 'center' } padding={ 2 } >
                                <Pressable
                                    key={ item.id }
                                    onPress={ () =>
                                    {
                                        navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                            state: {
                                                user: {
                                                    id: item?.id,
                                                    pk: item?.pk,
                                                    region: item?.region,
                                                    type: item?.type,
                                                    name: item?.title,
                                                },
                                            },
                                        } );
                                    } }
                                >
                                    
                                    { ( item?.thumb || item?.linkPic ) ? (
                                        <Box
                                            bgColor={ '#FF6600' }
                                            borderRadius={ [ 'full' ] }
                                            width={ '70px' }
                                            height={ '70px' }
                                            left={ '1px' }
                                            p={ 0 }
                                        >
                                            <Image
                                                source={ {
                                                    uri: item?.linkPic ? AV.Media.getURL( item?.linkPic ) : AV.Media.getThumb( item ),
                                                } }
                                                borderRadius={ [ 'full' ] }
                                                //top={ "2" }
                                                width={ '100%' }
                                                height={ [ '100%' ] }
                                            />
                                            { isEditPlayerDetails && <Button
                                                bgColor={ 'AVColor.white' }
                                                position={ 'absolute' }
                                                top={ '45px' }
                                                right={ '-6px' }
                                                p={ 0 }
                                                rounded={ 'full' }
                                                _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                shadow={ 1 }
                                                zIndex={ 99 }
                                                isLoading={ isPicLoader }
                                                _spinner={ { color: 'AVColor.black' } }
                                            >
                                                <Box { ...getProfilePicFile( { className: 'dropzone' } ) }>
                                                    <input { ...getProfileInputProps() } />
                                                    <AddPhotoIcon size={ 18 } p={ 1 } />
                                                </Box>
                                            </Button> }
                                        </Box>
                                    )
                                        : (
                                            <Box
                                                alignItems={ 'center' }
                                            >
                                                <Profile height={ '70' } width={ '70' } color={ "#32AE78" } />

                                                { isEditPlayerDetails && <Button
                                                    bgColor={ 'AVColor.white' }
                                                    position={ 'absolute' }
                                                    top={ '45px' }
                                                    right={ '-6px' }
                                                    p={ 0 }
                                                    rounded={ 'full' }
                                                    _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                    shadow={ 1 }
                                                    zIndex={ 99 }
                                                    isLoading={ isPicLoader }
                                                    _spinner={ { color: 'AVColor.black' } }
                                                >
                                                    <Box { ...getProfilePicFile( { className: 'dropzone' } ) }>
                                                        <input { ...getProfileInputProps() } />
                                                        <AddPhotoIcon size={ 18 } p={ 1 } />
                                                    </Box>
                                                </Button> }
                                            </Box>
                                        ) }
                                </Pressable>
                            </VStack>
                        </HStack>
                        <VStack>
                            <VStack>
                                <Text marginTop={ '4px' } fontWeight='500' numberOfLines={ 1 } width={ '190px' } text-overflow={ 'ellipsis' } fontSize={ '17px' } >
                                    { item.title }
                                </Text>
                                <HStack>
                                    <Text left={ '2px' } fontWeight='700' color={ item?.role?.name === undefined ? '#00A7BC' : "" } numberOfLines={ 1 } width={ '190px' } text-overflow={ 'ellipsis' } fontSize={ '15px' } >
                                        { item?.role?.name === undefined ? 'Assign Role' : item?.role?.name }
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text left={ '2px' } color='#00A7BC' numberOfLines={ 1 } width={ '190px' } text-overflow={ 'ellipsis' } fontSize={ '15px' } >
                                        { item?.phone }
                                    </Text>
                                </HStack>
                                <HStack>
                                    <TouchableOpacity onPress={ () => handleEmailPress(item) }>
                                        <Text left="2px" color="#00A7BC" numberOfLines={ 1 } width="190px" text-overflow="ellipsis" fontSize="15px" textDecorationLine="underline">
                                            { item?.email }
                                        </Text>
                                    </TouchableOpacity>
                                </HStack>
                            </VStack>

                        </VStack>
                        <HStack alignSelf='center' position={ 'absolute' } bottom={ '12px' }>
                            <>
                                { isCurrentUser && (
                                    <Pressable
                                        onPress={ () =>
                                        {
                                            setIsStaffEdit( true );
                                            setEditstaffitem( item );
                                            setstaffemail( item?.email );
                                            setstaffphone( item?.phone );
                                            setStaffRole( item?.role?.name )
                                            setstaffpic( item?.linkPic ? AV.Media.getURL( item?.linkPic ) : AV.Media.getThumb( item ) )
                                        } }
                                    >
                                        <EditIcon size='5' marginRight={ 5 } />
                                    </Pressable>
                                ) }
                                { staffList?.length !== 1 && isCurrentUser && (
                                    <Pressable onPress={ () => HandleRemoveStaffs( item ) }>
                                        <DeleteIcon size='5' marginRight={ 5 } />
                                    </Pressable>
                                ) }
                            </>
                        </HStack>
                    </VStack>
                </Box>
            }
        </>
    );
}

export default React.memo( StaffsCard );
