import {
	Home,
	Events,
	Teams,
	Season,
	Orgs,
	Profile,
	MyEvents,
	MyTeams,
	Myfavorites,
	Unsubscribe,
	Signin,
} from "../../Screens";
import React from "react";
import managefollowings from "../../components/header/managefollowings";
import accountsettings from "../../components/header/accountsettings";
import ErrorHandlerPopup from "components/common/ErrorHandlerPopup";
import SignalRTestPage from "components/common/AVSignalRTet";

export const routes = [
	{
		component: Home,
		path: "/",
		exact: true,
		title: "Actavivo Home",
	},
	{
		component: Home,
		path: "/Convo",
		exact: true,
		title: "Actavivo Home",
	},
	{
		component: Profile,
		path: "/profile/:userId/:region",
		exact: true,
		title: "Profile",
	},
	{
		component: MyEvents,
		path: "/my-events",
		exact: true,
		title: "My Events",
	},
	{
		component: Teams,
		path: "/events/:entityId/:region",
		exact: true,
		title: "Events",
	},

	{
		component: Teams,
		path: "/teams/:entityId/:region",
		exact: true,
		title: "Teams",
	},
	{
		component: Teams,
		path: "/season/:entityId/:region",
		exact: true,
		title: "Season",
	},
	{
		component: MyTeams,
		path: "/my-teams",
		exact: true,
		title: "My Teams",
	},
	{
		component: Teams,
		path: "/organizations/:entityId/:region/:type",
		exact: true,
		title: "Organizations",
	},
	{
		component: Myfavorites,
		path: "/favorites",
		exact: true,
		title: "Favorites",
	},
	{
		component: Unsubscribe,
		path: "/unsubscribe",
		exact: true,
		title: "Unsubscribe",
	},
	{
		component: Signin,
		path: "/signin",
		exact: true,
		title: "Signin",
	},
	{
		component: managefollowings,
		path: "/manageFollowings",
		exact: true,
		title: "ManageFollowings",
	},
	{
		component: accountsettings,
		path: "/accountsettings/:entityId/:region",
		exact: true,
		title: "Accountsettings",
	},
	{
		component: ErrorHandlerPopup,
		path: "*",
		exact: true,
		title: "ErrorHandlerPopup",
	},
	{
		component: SignalRTestPage,
		path: "/signal",
		exact: true,
		title: "SignalRTestPage",
	},
];
