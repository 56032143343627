import React from "react";
import { VStack, Text, HStack, Image } from "native-base";
import LogoSvg from "../../assets/images/actavivo_logo.svg";

function Loader(props: any) {
	return (
		<>
			{
				<HStack
					space={2}
					alignItems="center"
					position="absolute"
					top="0"
					bg="rgba(0,0,0,0.5)"
					justifyContent="center"
					w="100vw"
					h="100vh"
					zIndex={999}
				>
					<VStack alignItems="center" space={1}>
						<Image source={{ uri: LogoSvg }} alt="Actavivo" size={[12, "56px"]} color={"red.200"} />
						<Text color="light.50">{props.status}</Text>
					</VStack>
				</HStack>
			}
		</>
	);
}

export default React.memo(Loader);
